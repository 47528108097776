import { useEffect, useMemo } from 'react';
import useFetch from './useFetch';
import { getLoyaltyLevels } from '../api/product';
import { getTranslatedValue } from '../utils/translationUtils';
import useLoyaltyPrograms from './useLoyaltyPrograms';

const CURRENT_LANGUAGE = 'en';

const useLoyaltyLevels = () => {
  const { data, loading, error } = useFetch(getLoyaltyLevels, []);

  const {
    loyaltyPrograms,
    loading: programsLoading,
    error: programsError,
  } = useLoyaltyPrograms();

  const publishedProgramUUIDs = useMemo(() => {
    if (!loyaltyPrograms) return new Set();
    return new Set(loyaltyPrograms.map((program) => program.uuid));
  }, [loyaltyPrograms]);

  const processedData = useMemo(() => {
    if (!data) return [];

    return data
      .filter((level) => publishedProgramUUIDs.has(level.loyalty_program_uuid))
      .map((level) => ({
        ...level,
        name: getTranslatedValue(level.translations, 'name', CURRENT_LANGUAGE),
        description: getTranslatedValue(
          level.translations,
          'description',
          CURRENT_LANGUAGE,
        ),
      }));
  }, [data, publishedProgramUUIDs]);

  useEffect(() => {
    if (!loading && !error && !programsLoading && !programsError) {
    }
  }, [processedData, loading, error, programsLoading, programsError]);

  const combinedError = error || programsError;

  const combinedLoading = loading || programsLoading;

  return {
    loyaltyLevels: processedData,
    loading: combinedLoading,
    error: combinedError,
  };
};

export default useLoyaltyLevels;
