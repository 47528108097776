import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Spinner,
  useColorModeValue,
  FormControl,
  Select,
} from '@chakra-ui/react';
import LineChart from 'components/charts/LineChart';
import Card from 'components/card/Card';
import { getRegisteredUsers } from 'api/dashboard';
import { getProducts } from 'api/product';
import { getLoyaltyPrograms, getLoyaltyLevels } from 'api/loyalty';
import {
  format,
  parseISO,
  isValid,
  startOfWeek,
  addWeeks,
  compareAsc,
  differenceInDays,
} from 'date-fns';

export default function UsersOverviewWidget(props) {
  const { startDate, endDate, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // State variables
  const [usersData, setUsersData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Filter states
  const [groupBy, setGroupBy] = useState('month');

  const [loyaltyProgramUuid, setLoyaltyProgramUuid] = useState('');
  const [loyaltyLevelUuid, setLoyaltyLevelUuid] = useState('');

  // Options for loyalty programs and levels
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);
  const [loyaltyLevels, setLoyaltyLevels] = useState([]);

  const [allowedGroupByOptions, setAllowedGroupByOptions] = useState([
    'day',
    'week',
    'month',
    'year',
  ]);

  useEffect(() => {
    const calculateAllowedGroupBy = () => {
      if (startDate && endDate) {
        const start = parseISO(startDate);
        const end = parseISO(endDate);
        const diffDays = differenceInDays(end, start);

        let options = [];
        if (diffDays <= 7) {
          options = ['day'];
        } else if (diffDays <= 31) {
          options = ['day', 'week'];
        } else if (diffDays <= 365) {
          options = ['day', 'week', 'month'];
        } else {
          options = ['day', 'week', 'month', 'year'];
        }

        setAllowedGroupByOptions(options);

        if (!options.includes(groupBy)) {
          setGroupBy(options[0]);
        }
      }
    };

    calculateAllowedGroupBy();
  }, [startDate, endDate, groupBy]);

  // Fetch loyalty levels based on the selected loyalty program
  useEffect(() => {
    const fetchLoyaltyLevels = async () => {
      if (loyaltyProgramUuid) {
        try {
          const allLevels = await getLoyaltyLevels();
          const levels = allLevels.filter(
            (level) => level.loyalty_program_uuid === loyaltyProgramUuid,
          );
          setLoyaltyLevels(levels);
        } catch (error) {
          console.error('Error fetching loyalty levels:', error);
        }
      } else {
        setLoyaltyLevels([]);
        setLoyaltyLevelUuid('');
      }
    };

    fetchLoyaltyLevels();
  }, [loyaltyProgramUuid]);

  // Fetch products to get loyalty programs and levels
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const products = await getProducts();
        const programs = await getLoyaltyPrograms();
        // Filter products where loyalty_program_uuid or loyalty_level_uuid are not null
        const filteredProducts = products.filter(
          (product) => product.loyalty_level_uuid !== null,
        );
        setLoyaltyPrograms(programs);
        setLoyaltyLevels(filteredProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  // Fetch users data
  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const params = {
          group_by: groupBy,
        };
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;
        if (loyaltyProgramUuid)
          params.loyalty_program_uuid = loyaltyProgramUuid;
        if (loyaltyLevelUuid) params.loyalty_level_uuid = loyaltyLevelUuid;

        const data = await getRegisteredUsers(params);
        setUsersData(data.users_data);
        setTotalUsers(data.total_users);
      } catch (error) {
        console.error('Error fetching users data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [groupBy, startDate, endDate, loyaltyProgramUuid, loyaltyLevelUuid]);

  const sortedUsersData = (usersData || []).sort((a, b) =>
    compareAsc(parseISO(a.period), parseISO(b.period)),
  );

  // Prepare chart data
  const chartData = [
    {
      name: 'Users',
      data: sortedUsersData.map((item) => item.users),
    },
  ];

  // Prepare chart categories
  const chartCategories = sortedUsersData.map((item) => {
    let date;
    if (groupBy === 'week') {
      // Parse week number and year
      const year = parseInt(item.period.slice(0, 4), 10);
      const week = parseInt(item.period.slice(4), 10);
      date = startOfWeek(addWeeks(new Date(year, 0, 1), week - 1), {
        weekStartsOn: 1,
      });
    } else {
      date = parseISO(item.period);
    }

    if (!isValid(date)) {
      console.error(`Invalid date value: ${item.period}`);
      return item.period; // Return the original value if the date is invalid
    }

    if (groupBy === 'day') {
      return format(date, 'dd MMM');
    } else if (groupBy === 'week') {
      return `W${format(date, 'w yyyy')}`;
    } else if (groupBy === 'month') {
      return format(date, 'MMM');
    } else if (groupBy === 'year') {
      return format(date, 'yyyy');
    } else {
      return item.period;
    }
  });

  // Chart options
  const chartOptions = {
    chart: {
      toolbar: { show: false },
    },
    xaxis: {
      categories: chartCategories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    yaxis: {
      show: false,
    },
    stroke: {
      curve: 'smooth',
      width: 5,
    },
    colors: ['#3182CE'],
  };

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      {...rest}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Flex
            justify="space-between"
            px="20px"
            pt="5px"
            w="100%"
            flexWrap="wrap"
          >
            {/* Left side - Total Users */}
            <Flex align="center" mb="10px">
              <Flex flexDirection="column" me="20px">
                <Text
                  color={textColor}
                  fontSize="34px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  {totalUsers}
                </Text>
                <Text
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                  mt="4px"
                >
                  Total Users
                </Text>
              </Flex>
            </Flex>

            {/* Right side - Filter controls */}
            <Flex
              mb="10px"
              ml="auto"
              alignItems="center"
              flexWrap="wrap"
              gap={2}
            >
              {/* Time Period */}
              <FormControl w="auto">
                <Select
                  fontSize="sm"
                  variant="subtle"
                  value={groupBy}
                  onChange={(e) => setGroupBy(e.target.value)}
                >
                  {allowedGroupByOptions.map((option) => (
                    <option key={option} value={option}>
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </option>
                  ))}{' '}
                </Select>
              </FormControl>

              {/* Loyalty Program */}
              <FormControl w="auto">
                <Select
                  fontSize="sm"
                  variant="subtle"
                  placeholder="Select Loyalty Program"
                  value={loyaltyProgramUuid}
                  onChange={(e) => setLoyaltyProgramUuid(e.target.value)}
                >
                  {loyaltyPrograms.map((program) => (
                    <option key={program.uuid} value={program.uuid}>
                      {program.translations.find(
                        (t) => t.language === 'en' && t.key === 'name',
                      )?.value || 'N/A'}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Loyalty Level */}
              <FormControl w="auto">
                <Select
                  fontSize="sm"
                  variant="subtle"
                  placeholder="Select Loyalty Level"
                  value={loyaltyLevelUuid}
                  onChange={(e) => setLoyaltyLevelUuid(e.target.value)}
                  isDisabled={!loyaltyProgramUuid}
                >
                  {loyaltyLevels.map((level) => {
                    const translation = Array.isArray(level.translations)
                      ? level.translations.find(
                          (t) => t.language === 'en' && t.key === 'name',
                        )
                      : null;
                    return (
                      <option key={level.uuid} value={level.uuid}>
                        {level.translations.en?.product_name ||
                          translation?.value}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Flex>
          </Flex>

          <Box minH="200px" mt="auto" w="100%">
            <LineChart chartData={chartData} chartOptions={chartOptions} />
          </Box>
        </>
      )}
    </Card>
  );
}
