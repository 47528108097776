import React, { useState, useEffect } from 'react';
import { Box, Text, List, ListItem, VStack } from '@chakra-ui/react';
import cookie from 'cookie';
import { fetchLoyaltyPrograms, fetchVouchers } from '../../api/automation';
import { getCompany } from '../../api/company';
import { renderConditionContent } from 'utils/automation/condtionalRenderers';

export default function FlowSummary({ flowItems }) {
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState('en');

  const getCookie = (name) => {
    const cookies = cookie.parse(document.cookie);
    return cookies[name];
  };

  const CompanyToken = getCookie('x-company');
  const UserToken = getCookie('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyDefaultLanguage = await getCompany().then(
          (company) => company.default_language,
        );
        setDefaultLanguage(companyDefaultLanguage);
        const fetchedLoyaltyPrograms = await fetchLoyaltyPrograms();
        const fetchedVouchers = await fetchVouchers();
        setLoyaltyPrograms(fetchedLoyaltyPrograms);
        setVouchers(fetchedVouchers);
      } catch (error) {
        console.error('Error fetching loyalty programs or vouchers', error);
      }
    };

    fetchData();
  }, [CompanyToken, UserToken]);

  const renderFlowItems = () => {
    const groupedItems = [];
    let currentGroup = [];

    flowItems.forEach((item, index) => {
      if (item.step_type === 'IF_AND') {
        currentGroup.push(item);
      } else {
        if (currentGroup.length > 0) {
          groupedItems.push({ step_type: 'IF_AND_GROUP', items: currentGroup });
          currentGroup = [];
        }
        groupedItems.push(item);
      }
    });

    if (currentGroup.length > 0) {
      groupedItems.push({ step_type: 'IF_AND_GROUP', items: currentGroup });
    }

    return groupedItems.map((item, index) => {
      if (item.step_type === 'IF_AND_GROUP') {
        return (
          <React.Fragment key={index}>
            <List>
              <ListItem display="flex" alignItems="center">
                <VStack
                  border="2px solid #6789FF"
                  borderRadius="md"
                  p={2}
                  spacing={2}
                  align="stretch"
                  width="100%"
                >
                  {item.items.map((subItem, subIndex) => (
                    <React.Fragment key={subIndex}>
                      <Box display="flex" alignItems="center">
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border="2px solid #6789FF"
                          borderRadius="full"
                          width="50px"
                          height="30px"
                          mr={3}
                        >
                          {index + subIndex + 1}
                        </Box>
                        {renderConditionContent(
                          subItem,
                          loyaltyPrograms,
                          vouchers,
                          defaultLanguage,
                        )}
                      </Box>
                      {subIndex < item.items.length - 1 && (
                        <ListItem display="flex" alignItems="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="50px"
                            height="30px"
                            mr={3}
                            fontWeight={500}
                          >
                            AND
                          </Box>
                        </ListItem>
                      )}
                    </React.Fragment>
                  ))}
                </VStack>
              </ListItem>
              {index < groupedItems.length - 1 && (
                <ListItem display="flex" alignItems="center" ml="8px">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="50px"
                    height="30px"
                    mr={3}
                    mt={2}
                    fontWeight={500}
                  >
                    {groupedItems[index + 1].step_type === 'THEN'
                      ? 'THEN'
                      : groupedItems[index + 1].step_type === 'IF_AND_GROUP'
                        ? 'AND'
                        : 'OR'}
                  </Box>
                </ListItem>
              )}
            </List>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={index}>
            <List>
              <ListItem display="flex" alignItems="center" ml="8px">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border="2px solid #6789FF"
                  borderRadius="full"
                  width="50px"
                  height="30px"
                  mr={3}
                >
                  {index + 1}
                </Box>
                {renderConditionContent(
                  item,
                  loyaltyPrograms,
                  vouchers,
                  defaultLanguage,
                )}
              </ListItem>
              {index < groupedItems.length - 1 && (
                <ListItem display="flex" alignItems="center" ml="8px">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="50px"
                    height="30px"
                    mr={3}
                    fontWeight={500}
                  >
                    {groupedItems[index + 1].step_type === 'THEN'
                      ? 'THEN'
                      : groupedItems[index].step_type === 'IF_OR' &&
                          groupedItems[index + 1].step_type === 'IF_AND_GROUP'
                        ? 'OR'
                        : groupedItems[index + 1].step_type === 'IF_AND_GROUP'
                          ? 'AND'
                          : 'OR'}
                  </Box>
                </ListItem>
              )}
            </List>
          </React.Fragment>
        );
      }
    });
  };

  return (
    <Box mt={100}>
      <Text fontWeight="bold" mb={4}>
        Flow Summary
      </Text>
      <List spacing={3}>
        {flowItems.length > 0 && (
          <ListItem display="flex" alignItems="center" ml="8px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="50px"
              height="30px"
              mr={3}
              fontWeight={500}
            >
              IF
            </Box>
          </ListItem>
        )}
        {renderFlowItems()}
      </List>
    </Box>
  );
}
