import React from 'react';
import { Select, Input } from '@chakra-ui/react';
import {
  getLoyaltyProgramName,
  getLoyaltyLevelName,
  getRewardVoucherName,
} from 'utils/automation/automationUtils';

export const renderConditionContent = (
  item,
  loyaltyPrograms,
  vouchers,
  language,
) => {
  switch (item.condition_type) {
    case 'loyaltyProgram':
      return `User has ${getLoyaltyProgramName(item.loyalty_program_uuid, loyaltyPrograms, language)}`;
    case 'loyaltyLevel':
      return `User has ${getLoyaltyLevelName(item.loyalty_level_uuid, loyaltyPrograms, language)} on ${getLoyaltyProgramName(item.loyalty_program_uuid, loyaltyPrograms, language)}`;
    case 'spendAmount':
      return `User has spent ${item.spent_amount}€ over the last ${item.time_period} ${item.time_unit.toLowerCase()}(s)`;
    case 'rewardVoucher':
      return `User gets ${item.reward_quantity === 1 ? `a` : item.reward_quantity} voucher(s) for ${getRewardVoucherName(item.reward_product_uuid, vouchers, language)}`;
    default:
      return;
  }
};

export const renderConditionInputs = (
  currentStep,
  setCurrentStep,
  loyaltyPrograms,
  vouchers,
  setErrorMessage,
  language,
) => {
  switch (currentStep.condition_type) {
    case 'loyaltyProgram':
      return (
        <Select
          placeholder="Select loyalty program"
          value={currentStep.loyalty_program_uuid}
          onChange={(e) =>
            setCurrentStep({
              ...currentStep,
              loyalty_program_uuid: e.target.value,
            })
          }
        >
          {loyaltyPrograms
            .filter((program) => program.is_published === 1)
            .map((program) => {
              const translation = program.translations.find(
                (t) => t.language === language && t.key === 'name',
              );

              return (
                <option key={program.uuid} value={program.uuid}>
                  {translation?.value || 'Unnamed Program'}
                </option>
              );
            })}
        </Select>
      );
    case 'loyaltyLevel':
      return (
        <>
          <Select
            placeholder="Select loyalty program"
            value={currentStep.loyalty_program_uuid}
            onChange={(e) =>
              setCurrentStep({
                ...currentStep,
                loyalty_program_uuid: e.target.value,
                loyalty_level_uuid: '',
              })
            }
          >
            {loyaltyPrograms
              .filter((program) => program.is_published === 1)
              .map((program) => {
                const translation = program.translations.find(
                  (t) => t.language === language && t.key === 'name',
                );

                return (
                  <option key={program.uuid} value={program.uuid}>
                    {translation?.value || 'Unnamed Program'}
                  </option>
                );
              })}
          </Select>
          <Select
            placeholder="Select loyalty level"
            value={currentStep.loyalty_level_uuid}
            onChange={(e) =>
              setCurrentStep({
                ...currentStep,
                loyalty_level_uuid: e.target.value,
              })
            }
          >
            {loyaltyPrograms
              .filter(
                (program) => program.uuid === currentStep.loyalty_program_uuid,
              )
              .flatMap((program) => program.products)
              .map((product) => {
                const translation = product.translations.find(
                  (t) => t.language === language && t.key === 'name',
                );

                return (
                  <option key={product.name} value={product.loyalty_level_uuid}>
                    {translation?.value || 'Unnamed Level'}
                  </option>
                );
              })}
          </Select>
        </>
      );
    case 'spendAmount':
      if (!currentStep.time_unit) {
        setCurrentStep({ ...currentStep, time_unit: 'MONTH' });
      }
      return (
        <>
          <div
            style={{
              position: 'relative',
              display: 'inline-block',
              width: '100%',
            }}
          >
            <Input
              style={{ paddingRight: '25px', width: '100%' }}
              placeholder="Enter Amount"
              type="number"
              min="1"
              step="0.01"
              value={currentStep.spent_amount}
              onChange={(e) =>
                setCurrentStep({ ...currentStep, spent_amount: e.target.value })
              }
            />
            <span
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
              }}
            >
              €
            </span>
          </div>
          <Input
            placeholder="Enter Period"
            type="number"
            min="0"
            step="1"
            value={currentStep.time_period}
            onChange={(e) => {
              setCurrentStep({ ...currentStep, time_period: e.target.value });
            }}
            onKeyDown={(e) => {
              if (e.key === '.' || e.key === ',') {
                e.preventDefault();
                setErrorMessage({
                  title: 'Error',
                  description: 'Decimal numbers are not allowed in this field.',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                }); // Set error message on invalid key press
              }
            }}
          />
          <Select
            value={currentStep.time_unit}
            onChange={(e) =>
              setCurrentStep({ ...currentStep, time_unit: e.target.value })
            }
          >
            <option value="DAY">Day(s)</option>
            <option value="MONTH">Month(s)</option>
            <option value="QUARTER">Quarter(s)</option>
            <option value="YEAR">Year(s)</option>
          </Select>
        </>
      );

    case 'rewardVoucher':
      return (
        <>
          <Select
            placeholder="Select reward voucher"
            value={currentStep.reward_product_uuid}
            onChange={(e) =>
              setCurrentStep({
                ...currentStep,
                reward_product_uuid: e.target.value,
              })
            }
          >
            {vouchers.map((voucher) => {
              const translation = voucher.translations.en.product_name;

              return (
                <option key={voucher.uuid} value={voucher.uuid}>
                  {translation || 'Unnamed Voucher'}
                </option>
              );
            })}
          </Select>
          <Input
            placeholder="Enter quantity"
            value={currentStep.reward_quantity}
            onChange={(e) =>
              setCurrentStep({
                ...currentStep,
                reward_quantity: e.target.value,
              })
            }
          />
        </>
      );
    default:
      return null;
  }
};
