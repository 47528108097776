import React, {useEffect, useState} from 'react';
import { Box, SimpleGrid, Tabs, TabList, TabPanels, Tab, TabPanel, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import apiClient from 'api/axios';
import { useToast } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';

const editorStyles = {
    '.editor-input': {
      minHeight: '200px',
      padding: '10px',
      outline: 'none',
      width: '100%',
      cursor: 'text',
      fontSize: '16px',
      lineHeight: '1.5',
      '& h1': { fontSize: '2em', fontWeight: 'bold', margin: '0.67em 0' },
      '& h2': { fontSize: '1.5em', fontWeight: 'bold', margin: '0.83em 0' },
      '& h3': { fontSize: '1.17em', fontWeight: 'bold', margin: '1em 0' },
      '& strong': { fontWeight: 'bold' },
      '& em': { fontStyle: 'italic' },
      '& u': { textDecoration: 'underline' },
      '& ul': { listStyle: 'disc', marginLeft: '20px' },
      '& ol': { listStyle: 'decimal', marginLeft: '20px' }
    },
    '.editor-header-one': { fontSize: '2em', fontWeight: 'bold', margin: '0.67em 0' },
    '.editor-header-two': { fontSize: '1.5em', fontWeight: 'bold', margin: '0.83em 0' },
    '.editor-header-three': { fontSize: '1.17em', fontWeight: 'bold', margin: '1em 0' }
  };



    export default function CompanySubscriptions() {
        useEffect(() => {
            // Dynamically load the Stripe script
            const script = document.createElement('script');
            script.src = 'https://js.stripe.com/v3/pricing-table.js';
            script.async = true;
            document.body.appendChild(script);
        
            return () => {
              // Cleanup the script if necessary
              document.body.removeChild(script);
            };
          }, []);
    
    return (
      <>
        <Global styles={editorStyles} />
        <Box p={5} mt={{ sm: '150px', md: '125px', lg: '80px' }}>
            <SimpleGrid columns={{ sm: 1, xl: 1 }} spacing={5} mb={5}>
            <Tabs w="100%">
            <TabList>
              <Tab>General</Tab>
              <Tab>Invoices</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {/* Stripe Pricing Table */}
            <stripe-pricing-table pricing-table-id="prctbl_1QPjjXLJ4wJXMuTJav6aed2C"
            publishable-key="pk_test_51Pqs65LJ4wJXMuTJO5aSQknYIHeNS4swumr5NYiqhw33rnSun1rCjvHoGpafvzDgTSFAD30IC6r5uiesqNWxmchP00TPdWl15A">
            </stripe-pricing-table>
              </TabPanel>
              <TabPanel>
                {/* Stripe Subcriptions */}
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Subscription ID</Th>
                      <Th>Status</Th>
                      <Th>Start Date</Th>
                      <Th>End Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                  </Tbody>
                </Table>
              </TabPanel>
            </TabPanels>
          </Tabs>
            </SimpleGrid>
            </Box>
            </>
    );}