import React, { useState, useMemo, useEffect } from 'react';
import {
  Flex,
  Spinner,
  Text,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  HStack,
  Box,
  useToast,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { MdEdit, MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { getCategories, deleteCategory } from '../../api/category';

export default function CategoriesTable({ categories, setCategories }) {
  const navigate = useNavigate();
  const toast = useToast();
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        id: 'id',
        header: () => (
          <Text fontSize="14px" color="gray.400">
            ID
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {info.getValue()}
          </Text>
        ),
        enableSorting: true,
      }),
      columnHelper.accessor('parent_id', {
        id: 'parent_id',
        header: () => (
          <Text fontSize="14px" color="gray.400">
            Parent ID
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {info.getValue() != null ? info.getValue() : 'Parent Category'}
          </Text>
        ),
        enableSorting: true,
      }),
      columnHelper.accessor('translations', {
        id: 'name',
        header: () => (
          <Text fontSize="14px" color="gray.400">
            Name
          </Text>
        ),
        cell: (info) => {
          const category = info.row.original;
          const translatedName =
            category.translations?.en?.category_name || 'Unnamed Category';
          return (
            <Text color={textColor} fontSize="md" fontWeight="500">
              {translatedName}
            </Text>
          );
        },
        enableSorting: true,
      }),
      // columnHelper.accessor('company_id', {
      //   id: 'company_id',
      //   header: () => (
      //     <Text fontSize="14px" color="gray.400">
      //       Company ID
      //     </Text>
      //   ),
      //   cell: (info) => (
      //     <Text color={textColor} fontSize="md" fontWeight="500">
      //       {info.getValue()}
      //     </Text>
      //   ),
      //   enableSorting: true,
      // }),
      // columnHelper.accessor('created_at', {
      //   id: 'created_at',
      //   header: () => (
      //     <Text fontSize="14px" color="gray.400">
      //       Created At
      //     </Text>
      //   ),
      //   cell: (info) => {
      //     const date = new Date(info.getValue());
      //     return (
      //       <Text color={textColor} fontSize="md" fontWeight="500">
      //         {date.toLocaleDateString('en-US', {
      //           year: 'numeric',
      //           month: 'short',
      //           day: 'numeric',
      //         })}
      //       </Text>
      //     );
      //   },
      //   enableSorting: true,
      // }),
      // columnHelper.accessor('updated_at', {
      //   id: 'updated_at',
      //   header: () => (
      //     <Text fontSize="14px" color="gray.400">
      //       Updated At
      //     </Text>
      //   ),
      //   cell: (info) => {
      //     const date = new Date(info.getValue());
      //     return (
      //       <Text color={textColor} fontSize="md" fontWeight="500">
      //         {date.toLocaleDateString('en-US', {
      //           year: 'numeric',
      //           month: 'short',
      //           day: 'numeric',
      //         })}
      //       </Text>
      //     );
      //   },
      //   enableSorting: true,
      // }),
      columnHelper.display({
        id: 'actions',
        header: () => (
          <Text fontSize="14px" color="gray.400">
            Actions
          </Text>
        ),
        cell: (info) => (
          <Flex align="center">
            <HStack spacing="4">
              <Icon
                w="16px"
                h="16px"
                as={MdDelete}
                cursor="pointer"
                color="red.500"
                onClick={() => handleDelete(info.row.original.uuid)} // Use uuid here
              />
            </HStack>
          </Flex>
        ),
      }),
    ],
    [navigate, brandColor, textColor],
  );

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const table = useReactTable({
    data: categories,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      sorting: [{ id: 'id', desc: false }],
    },
  });

  useEffect(() => {
    const totalPages = table.getPageCount();
    if (pagination.pageIndex >= totalPages && totalPages > 0) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: totalPages - 1,
      }));
    }
  }, [table.getPageCount(), pagination.pageIndex, setPagination, table]);

  const handleDelete = async (categoryUuid) => {
    try {
      await deleteCategory(categoryUuid);
      setCategories((prev) => prev.filter((cat) => cat.uuid !== categoryUuid));
      toast({
        title: 'Success',
        description: 'Category deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(
        'Error deleting category:',
        error.response || error.message || error,
      );
      toast({
        title: 'Error',
        description:
          error.response?.data?.error || 'Failed to delete category.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Flex justify="flex-end" mb={4}></Flex>
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id} bg="transparent">
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  borderColor={borderColor}
                  cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <Flex align="center" justify="space-between">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === 'desc' ? (
                        <Text fontSize="xs">↓</Text>
                      ) : (
                        <Text fontSize="xs">↑</Text>
                      )
                    ) : (
                      header.column.getCanSort() && <Text fontSize="xs">⇅</Text>
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  borderColor={borderColor}
                  align="center"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Flex
        w="100%"
        justify="space-between"
        px="20px"
        pt="10px"
        pb="5px"
        alignItems="center"
      >
        <Text fontSize="sm" color="gray.500" fontWeight="normal">
          Showing {pagination.pageIndex * pagination.pageSize + 1} to{' '}
          {Math.min(
            (pagination.pageIndex + 1) * pagination.pageSize,
            categories.length,
          )}{' '}
          of {categories.length} entries
        </Text>

        {/* Add pagination controls here */}
      </Flex>
    </Box>
  );
}
