// src/api/orders.js
import apiClient from './axios';

/**
 * Fetches orders based on provided filters.
 *
 * @param {Object} filters - The filters to apply
 * @param {string} filters.status - Order status filter
 * @param {string} filters.start_date - Start date filter
 * @param {string} filters.end_date - End date filter
 * @param {string} filters.global_filter - Search term
 * @param {number} filters.page - Page number (1-based)
 * @param {number} filters.limit - Items per page
 * @param {string} filters.sort_by - Sort by column
 * @param {string} filters.sort_order - Sort order
 * @returns {Promise<Object>} - Orders data with pagination
 */
export const getOrders = async (filters = {}) => {
  try {
    const {
      product_type,
      status,
      start_date,
      end_date,
      global_filter,
      page,
      limit,
      sort_by,
      sort_order,
    } = filters;

    // Build query parameters
    const params = {};
    if (product_type && product_type !== 'all')
      params.product_type = product_type.toLowerCase();
    if (status && status !== 'all') params.status = status.toLowerCase();
    if (start_date) params.start_date = start_date;
    if (end_date) params.end_date = end_date;
    if (global_filter) params.global_filter = global_filter.trim();
    if (page) params.page = page;
    if (limit) params.limit = limit;
    if (sort_by) params.sort_by = sort_by;
    if (sort_order) params.sort_order = sort_order;

    const { data } = await apiClient.get('/orders/purchases', { params });

    return {
      orders: data.data || [],
      pagination: {
        total: data.total || 0,
        pageCount: Math.ceil((data.total || 0) / (data.limit || 10)),
        page: data.page || 1,
        pageSize: data.limit || 10,
      },
    };
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

/**
 * Fetches details for a specific order.
 *
 * @param {string} orderUuid - The UUID of the order
 * @returns {Promise<Object>} Order details
 */
export const getOrderDetails = async (orderUuid) => {
  try {
    const { data } = await apiClient.get(`/orders/${orderUuid}`);
    return data;
  } catch (error) {
    console.error('Error fetching order details:', error);
    throw error;
  }
};

/**
 * Updates the status of an order.
 *
 * @param {string} orderUuid - The UUID of the order
 * @param {string} status - New status to set
 * @returns {Promise<Object>} Updated order data
 */
export const updateOrderStatus = async (orderUuid, status, comment) => {
  try {
    const { data } = await apiClient.put(`/orders/${orderUuid}/status`, {
      status,
      comment,
    });
    return data;
  } catch (error) {
    console.error('Error updating order status:', error);
    throw error;
  }
};

export const addOrderComment = async (orderUuid, comment) => {
  try {
    const { data } = await apiClient.post(`/orders/${orderUuid}/comments`, {
      comment,
    });
    return data;
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

export const getOrderStatusLogs = async (orderUuid) => {
  try {
    console.log('Fetching status logs for order:', orderUuid);
    const { data } = await apiClient.get(`/orders/${orderUuid}/status-logs`);
    console.log('Received status logs:', data);
    return data;
  } catch (error) {
    console.error('Error fetching status logs:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};
