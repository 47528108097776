import apiClient from 'api/axios';
import { getCompany } from 'api/company';
import cookie from 'cookie';

const ensureCompanyContext = async () => {
  const cookies = cookie.parse(document.cookie);
  if (!cookies['x-company']) {
    console.warn('No company token found, attempting to fetch company details');
    try {
      const company = await getCompany();
      if (company && company.x_company_token) {
        document.cookie = cookie.serialize('x-company', company.x_company_token, {
          path: '/',
          secure: process.env.NODE_ENV === 'production',
          sameSite: 'strict',
        });
        // console.log('Company token set:', company.x_company_token);
      } else {
        console.error('Company details fetched but no token found');
      }
    } catch (error) {
      console.error('Failed to fetch company details:', error);
    }
  }
};

/**
 * Retrieves a translation for a given key and optional language
 * @param {string} translation_key - The key of the translation to retrieve
 * @param {string|null} [language=null] - The language code (e.g. 'en', 'es'). If null, returns all languages
 * @returns {Promise<string|Object|null>} Translation value(s) or null if not found
 * @throws {Error} If the API request fails
 */
export async function getTranslation(translation_key, language = null) {
  try {
    await ensureCompanyContext();

    const endpoint = language
      ? `/translation/${translation_key}/${language}`
      : `/translation/${translation_key}`;

    // console.log(`Fetching translation for key: ${translation_key}, language: ${language}`);
    // console.log(`Endpoint: ${endpoint}`);

    const response = await apiClient.get(endpoint);
    // console.log(`Raw translation response for ${translation_key}:`, response.data);

    let parsedData = response.data;
    if (typeof response.data === 'string' && response.data.trim().startsWith('{')) {
      try {
        parsedData = JSON.parse(response.data);
        // console.log(`Parsed JSON data for ${translation_key}:`, parsedData);
      } catch (parseError) {
        console.warn(`Failed to parse JSON for ${translation_key}:`, parseError);
      }
    }

    return parsedData;
  } catch (error) {
    console.error(`Error fetching translation for key: ${translation_key}, language: ${language}:`, error);
    console.error('Error details:', {
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data
    });
    throw error;
  }
}

export const getTranslatedValue = (translations, key, language = 'en') => {
  const translation = translations.find(
    (t) => t.key === key && t.language === language,
  );
  return translation ? translation.value : key;
};

/**
 * Sets or updates a translation value for a specific key and language
 * @param {string} translation_key - The key of the translation to set
 * @param {string} translation_value - The translation text
 * @param {string} language - The language code (e.g. 'en', 'es')
 * @returns {Promise<{success: boolean}>} Object indicating success
 * @throws {Error} If required parameters are missing or API request fails
 */
export async function setTranslation(
  translation_key,
  translation_value,
  language,
) {
  try {
    await ensureCompanyContext();

    if (!translation_key || !translation_value || !language) {
      throw new Error('Missing required parameters');
    }

    /*
    console.log('Setting translation:', {
      key: translation_key,
      value: translation_value,
      language: language
    });
    */

    const response = await apiClient.put(
      `/translation/${translation_key}/${language}`,
      { translation_value },
    );

    // console.log('Translation set response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in setTranslation:', error);
    console.error('Error details:', {
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      headers: error.response?.headers,
    });
    throw error;
  }
}

/**
 * Deletes a translation by its ID
 * @param {string|number} translationId - The ID of the translation to delete
 * @returns {Promise<{success: boolean}>} Object indicating success
 * @throws {Error} If translation ID is missing or API request fails
 */
export async function deleteTranslation(translationId) {
  try {
    await ensureCompanyContext();

    if (!translationId) {
      throw new Error('Translation ID is required');
    }

    const response = await apiClient.delete(`/translation/${translationId}`);
    return response.data;
  } catch (error) {
    console.error('Error while deleting translation:', error);
    throw error;
  }
}
