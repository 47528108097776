import React from 'react';
import {
    Box,
    Button,
    Input,
    VStack,
    HStack,
    IconButton,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, DragHandleIcon } from '@chakra-ui/icons';
import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const HighlightRow = ({ item, index, updateItem, removeItem }) => (
    <Draggable draggableId={`highlight-${index}`} index={index}>
        {(provided) => (
            <Box
                ref={provided.innerRef}
                {...provided.draggableProps}
                mb={2}
                p={3}
                bg="white"
                borderRadius="md"
                boxShadow="sm"
            >
                <HStack spacing={4} align="center">
                    <Box {...provided.dragHandleProps} cursor="grab">
                        <DragHandleIcon />
                    </Box>
                    <FormControl flex="2">
                        <Input
                            size="sm"
                            value={item.title || ''}
                            onChange={e => updateItem(index, { ...item, title: e.target.value })}
                            placeholder="Title"
                        />
                    </FormControl>
                    <FormControl flex="1">
                        <Input
                            size="sm"
                            value={item.tag || ''}
                            onChange={e => updateItem(index, { ...item, tag: e.target.value })}
                            placeholder="Tag"
                        />
                    </FormControl>
                    <FormControl width="100px">
                        <Input
                            size="sm"
                            type="color"
                            value={item.color || '#000000'}
                            onChange={e => updateItem(index, { ...item, color: e.target.value })}
                        />
                    </FormControl>
                    <FormControl flex="2">
                        <Input
                            size="sm"
                            value={item.imageUrl || ''}
                            onChange={e => updateItem(index, { ...item, imageUrl: e.target.value })}
                            placeholder="Image URL"
                        />
                    </FormControl>
                    <IconButton
                        size="sm"
                        icon={<DeleteIcon />}
                        onClick={() => removeItem(index)}
                        aria-label="Remove highlight"
                        colorScheme="red"
                        variant="ghost"
                    />
                </HStack>
            </Box>
        )}
    </Draggable>
);

export const HighlightsElement = React.forwardRef(({ attributes, children, element, editor }, ref) => {
    const path = ReactEditor.findPath(editor, element);

    const addItem = () => {
        const newItems = [
            ...(element.attrs.items || []),
            {
                title: '',
                tag: '',
                color: '#000000',
                imageUrl: ''
            }
        ];
        Transforms.setNodes(
            editor,
            { attrs: { ...element.attrs, items: newItems } },
            { at: path }
        );
    };

    const updateItem = (index, newItem) => {
        const newItems = [...(element.attrs.items || [])];
        newItems[index] = newItem;
        Transforms.setNodes(
            editor,
            { attrs: { ...element.attrs, items: newItems } },
            { at: path }
        );
    };

    const removeItem = (index) => {
        const newItems = element.attrs.items.filter((_, i) => i !== index);
        Transforms.setNodes(
            editor,
            { attrs: { ...element.attrs, items: newItems } },
            { at: path }
        );
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(element.attrs.items || []);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        Transforms.setNodes(
            editor,
            { attrs: { ...element.attrs, items } },
            { at: path }
        );
    };

    return (
        <Box {...attributes} ref={ref} contentEditable={false} p={4} bg="gray.50" borderRadius="md">
            <HStack spacing={4} mb={3} px={8}>
                <Box width="32px" /> {/* Space for drag handle */}
                <Box flex="2" fontSize="sm" color="gray.600">Title</Box>
                <Box flex="1" fontSize="sm" color="gray.600">Tag</Box>
                <Box width="100px" fontSize="sm" color="gray.600">Color</Box>
                <Box flex="2" fontSize="sm" color="gray.600">Image URL</Box>
                <Box width="32px" /> {/* Space for delete button */}
            </HStack>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="highlights-list">
                    {(provided) => (
                        <VStack
                            spacing={2}
                            align="stretch"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {(element.attrs.items || []).map((item, index) => (
                                <HighlightRow
                                    key={index}
                                    item={item}
                                    index={index}
                                    updateItem={updateItem}
                                    removeItem={removeItem}
                                />
                            ))}
                            {provided.placeholder}
                        </VStack>
                    )}
                </Droppable>
            </DragDropContext>
            <Button leftIcon={<AddIcon />} onClick={addItem} mt={4} size="sm" colorScheme="blue">
                Add Highlight
            </Button>
            {children}
        </Box>
    );
}); 