import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { getLoyaltyLevels } from 'api/loyalty';
import DebouncedInput from 'components/DebouncedInput';
import { createPages } from 'utils/helpers';
import { MdEdit } from 'react-icons/md';

export default function LoyaltyLevelsOverview() {
  const [loyaltyLevels, setLoyaltyLevels] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLoyaltyLevels = async () => {
      try {
        const allLoyaltyLevels = await getLoyaltyLevels();

        setLoyaltyLevels(
          allLoyaltyLevels.map((loyaltyLevel) => ({
            uuid: loyaltyLevel.uuid,
            loyalty_program_uuid: loyaltyLevel.loyalty_program_uuid,
            translations: loyaltyLevel.translations,
            name: loyaltyLevel.name,
            created_at: loyaltyLevel.created_at,
            price: loyaltyLevel.price,
            sold: loyaltyLevel.sold_quantity,
            total: loyaltyLevel.quantity,
          })),
        );
      } catch (error) {
        console.error('Error getting loyalty levels:', error);
      }
    };
    fetchLoyaltyLevels();
  }, []);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Name
        </Text>
      ),
      cell: (info) => {
        const translations = info.row.original.translations;

        const enName = translations?.find(
          (t) => t.language === 'en' && t.key === 'name',
        )?.value;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {enName}
          </Text>
        );
      },
    }),
    columnHelper.accessor('price', {
      id: 'price',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Price
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()?.toFixed(2) || '0.00'}€
        </Text>
      ),
    }),

    columnHelper.accessor('stock', {
      id: 'stock',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Sold/Total
        </Text>
      ),
      cell: (info) =>
        info.row.original.sold ? (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {info.row.original.sold}/{info.row.original.total}
          </Text>
        ) : (
          <Text color={textColor} fontSize="md" fontWeight="500">
            0/{info.row.original.total}
          </Text>
        ),
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Date Created
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          ACTIONS
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Icon
            w="16px"
            h="16px"
            as={MdEdit}
            cursor="pointer"
            color={brandColor}
            onClick={() => {
              navigate(
                `/admin/loyalty-programs/${info.row.original.loyalty_program_uuid}`,
              );
            }}
          />
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: loyaltyLevels,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Flex direction="column" mt={{ sm: '150px', md: '125px', lg: '75px' }}>
      <Card
        w="100%"
        px={{ base: '16px', md: '24px' }}
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          mb="36px"
          mt="24px"
        >
          <DebouncedInput
            placeholder="Search"
            onChange={(value) => setGlobalFilter(value)}
          />
        </Flex>

        <Table variant="simple" color="gray.500">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    borderColor={borderColor}
                    key={header.id}
                    colSpan={header.colSpan}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {header.isPlaceholder ? null : (
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr px="20px" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Tooltip
                    label={cell.getValue()}
                    aria-label="Full text"
                    borderRadius="md"
                    key={cell.id}
                    bg="gray.600"
                  >
                    <Td
                      key={cell.id}
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      maxW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor={borderColor}
                      align="center"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  </Tooltip>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Flex
          w="100%"
          justify="space-between"
          align="center"
          px="20px"
          py="24px"
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: '24px', md: '0px' }}
          >
            Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
            {pagination.pageSize * (pagination.pageIndex + 1) <=
            loyaltyLevels.length
              ? pagination.pageSize * (pagination.pageIndex + 1)
              : loyaltyLevels.length}{' '}
            of {loyaltyLevels.length} entries
          </Text>
          <div className="flex items-center gap-2">
            <Stack direction="row" spacing="4px" ms="auto">
              <Button
                variant="no-effects"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={useColorModeValue('gray.200', 'white')}
                display={
                  pagination.pageSize === 5
                    ? 'none'
                    : table.getCanPreviousPage()
                      ? 'flex'
                      : 'none'
                }
                _hover={{
                  bg: 'whiteAlpha.100',
                  opacity: '0.7',
                }}
              >
                <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
              </Button>
              {createPages(table.getPageCount()).map((pageNumber, index) => (
                <Button
                  variant="no-effects"
                  transition="all .5s ease"
                  onClick={() => table.setPageIndex(pageNumber - 1)}
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bg={
                    pageNumber === pagination.pageIndex + 1
                      ? brandColor
                      : 'transparent'
                  }
                  border={
                    pageNumber === pagination.pageIndex + 1
                      ? 'none'
                      : '1px solid lightgray'
                  }
                  _hover={
                    pageNumber === pagination.pageIndex + 1
                      ? {
                          opacity: '0.7',
                        }
                      : {
                          bg: 'whiteAlpha.100',
                        }
                  }
                  key={index}
                >
                  <Text
                    fontSize="sm"
                    color={
                      pageNumber === pagination.pageIndex + 1
                        ? '#fff'
                        : textColor
                    }
                  >
                    {pageNumber}
                  </Text>
                </Button>
              ))}
              <Button
                variant="no-effects"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={useColorModeValue('gray.200', 'white')}
                display={
                  pagination.pageSize === 5
                    ? 'none'
                    : table.getCanNextPage()
                      ? 'flex'
                      : 'none'
                }
                _hover={{
                  bg: 'whiteAlpha.100',
                  opacity: '0.7',
                }}
              >
                <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
              </Button>
            </Stack>
          </div>
        </Flex>
      </Card>
    </Flex>
  );
}
