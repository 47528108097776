import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Textarea,
  Select,
} from '@chakra-ui/react';
import { fetchLoyaltyPrograms } from 'api/automation';

export default function CreateAutomationModal({ isOpen, onClose, createFlow }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState('');

  useEffect(() => {
    if (isOpen) {
      const loadLoyaltyPrograms = async () => {
        try {
          const data = await fetchLoyaltyPrograms();
          setLoyaltyPrograms(data);
        } catch (error) {
          console.error('Error fetching loyalty programs:', error);
        }
      };

      loadLoyaltyPrograms();
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    createFlow(name, description, selectedProgram);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Flow</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Enter flow name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            mb={3}
            required={true}
          />
          <Textarea
            placeholder="Enter flow description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            mb={3}
            required={true}
          />
          <Select
            placeholder="Select loyalty program"
            value={selectedProgram}
            onChange={(e) => setSelectedProgram(e.target.value)}
            required={true}
          >
            {loyaltyPrograms
              .filter((program) => program.is_published === 1)
              .map((program) => (
                <option key={program.uuid} value={program.uuid}>
                  {program.name}
                </option>
              ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isDisabled={!name || !description || !selectedProgram}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
