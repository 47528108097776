// src/pages/discounts/CreateDiscount.js

import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  Checkbox,
  VStack,
  Alert,
  AlertIcon,
  Box,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react';
import { createProductDiscount } from '../../api/discount';
import { getProductDiscounts } from '../../api/discount'; // Assuming this is needed
import { useNavigate } from 'react-router-dom';
import ProductCheckboxCard from './ProductCheckboxCard';
import { sortProductsByAvailability } from '../../utils/discountUtil'; // Import the sorting utility
import useFetch from '../../hooks/useFetch'; // Assuming a useFetch hook exists
import { getProducts } from '../../api/product'; // Assuming getProducts exists
import useLoyaltyPrograms from '../../hooks/useLoyaltyPrograms';
import useLoyaltyLevels from '../../hooks/useLoyaltyLevels';

const CreateDiscount = () => {
  const navigate = useNavigate();

  const [discountKind, setDiscountKind] = useState('fixed_price'); // 'fixed_price' or 'percentage'

  const [discountScope, setDiscountScope] = useState(''); // 'selected_products' or 'all_products'

  // Form data state
  const [formData, setFormData] = useState({
    product_uuid: '',
    product_uuids: [],
    discount_price: '',
    discount_percentage: '',
    loyalty_program_uuid: '',
    loyalty_level_uuid: '',
    setPeriod: false,
    start_time: '',
    end_time: '',
    is_active: true,
  });

  // Data fetching states
  const [products, setProducts] = useState([]);
  const [filteredLoyaltyLevels, setFilteredLoyaltyLevels] = useState([]);

  // Loading and error states
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [errorProducts, setErrorProducts] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Use custom hooks for loyalty programs and levels
  const {
    loyaltyPrograms,
    loading: loadingLoyaltyPrograms,
    error: errorLoyaltyPrograms,
  } = useLoyaltyPrograms();

  const {
    loyaltyLevels,
    loading: loadingLoyaltyLevels,
    error: errorLoyaltyLevels,
  } = useLoyaltyLevels();

  useEffect(() => {
    const fetchProductsAndDiscounts = async () => {
      try {
        // Fetch Products and Discounts
        setLoadingProducts(true);
        const productsData = await getProducts();

        const discountsResponse = await getProductDiscounts();
        console.log('Fetched discounts:', discountsResponse);

        const discounts = discountsResponse.data || [];

        const discountMap = {};
        discounts.forEach((discount) => {
          if (discount.product_uuid) {
            const uuid = discount.product_uuid;
            if (!discountMap[uuid]) {
              discountMap[uuid] = [];
            }
            discountMap[uuid].push(discount);
          }
        });

        const productsWithDiscounts = productsData.map((product) => ({
          ...product,
          discounts: discountMap[product.uuid] || [],
        }));

        // Enhanced Filtering: Exclude loyalty products
        const filteredProducts = productsWithDiscounts.filter(
          (product) =>
            product.product_type === 'regular' &&
            !product.loyalty_program_uuid && // Exclude if associated with a loyalty program
            !product.loyalty_level_uuid, // Exclude if associated with a loyalty level
        );

        // Apply Sorting
        const sortedFilteredProducts =
          sortProductsByAvailability(filteredProducts);
        setProducts(sortedFilteredProducts);
      } catch (error) {
        console.error('Error fetching products and discounts:', error);
        setErrorProducts('Failed to fetch products.');
      } finally {
        setLoadingProducts(false);
      }
    };

    fetchProductsAndDiscounts();
  }, []);

  useEffect(() => {
    if (formData.loyalty_program_uuid) {
      const filtered = loyaltyLevels.filter(
        (level) => level.loyalty_program_uuid === formData.loyalty_program_uuid,
      );
      setFilteredLoyaltyLevels(filtered);
      console.log('Filtered Loyalty Levels:', filtered);
      console.log('Current Loyalty Level UUID:', formData.loyalty_level_uuid);

      const isValidLevel = filtered.some(
        (level) => level.uuid === formData.loyalty_level_uuid,
      );
      if (!isValidLevel) {
        if (!isInitialLoad) {
          setFormData((prev) => ({ ...prev, loyalty_level_uuid: '' }));
          console.log('Loyalty Level UUID reset to empty string');
        }
      }
    } else {
      setFilteredLoyaltyLevels(loyaltyLevels);
      if (!isInitialLoad) {
        setFormData((prev) => ({ ...prev, loyalty_level_uuid: '' }));
        console.log(
          'Loyalty Level UUID reset to empty string (no loyalty program selected)',
        );
      }
    }

    if (
      isInitialLoad &&
      !loadingProducts &&
      !loadingLoyaltyPrograms &&
      !loadingLoyaltyLevels
    ) {
      setIsInitialLoad(false);
    }
  }, [
    formData.loyalty_program_uuid,
    loyaltyLevels,
    isInitialLoad,
    loadingProducts,
    loadingLoyaltyPrograms,
    loadingLoyaltyLevels,
  ]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const handleProductToggle = (productUuid) => {
    setFormData((prev) => {
      const newProductUuids = prev.product_uuids.includes(productUuid)
        ? prev.product_uuids.filter((uuid) => uuid !== productUuid)
        : [...prev.product_uuids, productUuid];
      return {
        ...prev,
        product_uuids: newProductUuids,
      };
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Basic validation
    if (discountScope === 'selected_products') {
      if (!formData.product_uuids || formData.product_uuids.length === 0) {
        setError('Please select at least one product.');
        return;
      }
    } else if (discountScope !== 'all_products') {
      setError('Invalid discount scope selected.');
      return;
    }

    // Optional Period validation
    if (formData.setPeriod) {
      if (!formData.start_time && !formData.end_time) {
        setError(
          'At least one of Start Date or End Date must be set when setting a period.',
        );
        return;
      }
      if (formData.start_time && formData.end_time) {
        if (new Date(formData.start_time) > new Date(formData.end_time)) {
          setError('Start Date must be before End Date.');
          return;
        }
      }
    }

    // Discount kind specific validation
    if (discountKind === 'fixed_price' && !formData.discount_price) {
      setError('Discount Amount is required for Fixed Price Discount.');
      return;
    }

    if (discountKind === 'percentage' && !formData.discount_percentage) {
      setError('Discount Percentage is required for Percentage Discount.');
      return;
    }

    // Discount scope validation
    if (!discountScope) {
      setError('Discount Scope is required.');
      return;
    }

    // Optional Loyalty Level validation
    if (formData.loyalty_program_uuid && formData.loyalty_level_uuid) {
      const isValidLevel = loyaltyLevels.some(
        (level) =>
          level.uuid === formData.loyalty_level_uuid &&
          level.loyalty_program_uuid === formData.loyalty_program_uuid,
      );
      if (!isValidLevel) {
        setError(
          'Selected Loyalty Level does not belong to the chosen Loyalty Program.',
        );
        return;
      }
    }

    try {
      let commonData = {
        is_active: formData.is_active,
        discount_type: discountScope,
      };

      if (formData.setPeriod) {
        if (formData.start_time) {
          commonData.start_time = new Date(formData.start_time).toISOString();
        }
        if (formData.end_time) {
          commonData.end_time = new Date(formData.end_time).toISOString();
        }
      } else {
        commonData.start_time = null;
        commonData.end_time = null;
      }

      let discountPayload = { ...commonData };

      if (discountKind === 'fixed_price') {
        discountPayload.discount_price = parseFloat(formData.discount_price);
      } else if (discountKind === 'percentage') {
        discountPayload.discount_percentage = parseFloat(
          formData.discount_percentage,
        );
      }

      if (discountScope === 'selected_products') {
        discountPayload.product_uuids = formData.product_uuids;
      }

      if (formData.loyalty_program_uuid) {
        discountPayload.loyalty_program_uuid = formData.loyalty_program_uuid;
      }
      if (formData.loyalty_level_uuid) {
        discountPayload.loyalty_level_uuid = formData.loyalty_level_uuid;
      }

      if (formData.parent_id) {
        discountPayload.parent_id = formData.parent_id;
      }

      console.log('Creating discount with data:', discountPayload);
      const createdDiscounts = await createProductDiscount(discountPayload);
      setSuccess('Discount created successfully!');
      setFormData({
        product_uuid: '',
        product_uuids: [],
        discount_price: '',
        discount_percentage: '',
        loyalty_program_uuid: '',
        loyalty_level_uuid: '',
        start_time: '',
        end_time: '',
        is_active: true,
        setPeriod: false,
      });
      setDiscountKind('fixed_price');
      setDiscountScope('');
      setIsInitialLoad(true);

      navigate('/admin/discounts');
    } catch (err) {
      console.error('Error creating discount:', err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        'Failed to create discount.';
      setError(errorMessage);
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          {/* Discount Kind Selection */}
          <FormControl id="discountKind" isRequired>
            <FormLabel>Discount Kind</FormLabel>
            <Select
              value={discountKind}
              onChange={(e) => {
                console.log('Discount kind changed:', e.target.value);
                setDiscountKind(e.target.value);
                // Reset discount fields when kind changes
                setFormData((prev) => ({
                  ...prev,
                  discount_price: '',
                  discount_percentage: '',
                }));
              }}
            >
              <option value="fixed_price">Discount Amount</option>
              <option value="percentage">Percentage</option>
            </Select>
          </FormControl>

          {/* Discount Price */}
          {discountKind === 'fixed_price' && (
            <FormControl id="discount_price" isRequired>
              <FormLabel>Discount Amount</FormLabel>
              <Input
                type="number"
                name="discount_price"
                value={formData.discount_price}
                onChange={handleChange}
                placeholder="Enter Discount Amount"
                min="0"
                step="0.01"
              />
            </FormControl>
          )}

          {/* Discount Percentage */}
          {discountKind === 'percentage' && (
            <FormControl id="discount_percentage" isRequired>
              <FormLabel>Discount Percentage</FormLabel>
              <Input
                type="number"
                name="discount_percentage"
                value={formData.discount_percentage}
                onChange={handleChange}
                placeholder="Enter Discount Percentage"
                min="0"
                max="100"
                step="0.1"
              />
            </FormControl>
          )}
          {/* Discount Scope Selection */}
          <FormControl id="discountScope" isRequired>
            <FormLabel>Discount Scope</FormLabel>
            <Select
              value={discountScope}
              onChange={(e) => {
                console.log('Discount scope changed:', e.target.value);
                setDiscountScope(e.target.value);
                // Reset product selections when scope changes
                setFormData((prev) => ({
                  ...prev,
                  product_uuid: '',
                  product_uuids: [],
                }));
              }}
            >
              {/* Explicit Disabled Placeholder */}
              <option value="" disabled>
                Select Discount Scope
              </option>
              <option value="selected_products">Selected Products</option>
              <option value="all_products">All Products</option>
            </Select>
          </FormControl>

          {/* Multiple Product Selection */}
          {discountScope === 'selected_products' && (
            <FormControl id="product_uuids">
              <FormLabel>Select Products</FormLabel>
              {loadingProducts ? (
                <Spinner size="sm" />
              ) : errorProducts ? (
                <Alert status="error">
                  <AlertIcon />
                  {errorProducts}
                </Alert>
              ) : (
                <Box>
                  {products.length === 0 ? ( // Check if sorted products list is empty
                    <Alert status="warning">
                      <AlertIcon />
                      No regular products available for selection.
                    </Alert>
                  ) : (
                    <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                      {products.map((product) => (
                        <ProductCheckboxCard
                          key={product.uuid}
                          product={product}
                          isChecked={formData.product_uuids.includes(
                            product.uuid,
                          )}
                          onChange={handleProductToggle}
                          discountKind={discountKind} // Pass additional props if needed
                          discountPrice={formData.discount_price}
                          discountPercentage={formData.discount_percentage}
                        />
                      ))}
                    </SimpleGrid>
                  )}
                </Box>
              )}
            </FormControl>
          )}

          {/* Loyalty Program Selection */}
          {['selected_products', 'all_products'].includes(discountScope) && (
            <FormControl id="loyalty_program_uuid">
              <FormLabel>Loyalty Program</FormLabel>
              {loadingLoyaltyPrograms ? (
                <Spinner size="sm" />
              ) : errorLoyaltyPrograms ? (
                <Alert status="error">
                  <AlertIcon />
                  {errorLoyaltyPrograms}
                </Alert>
              ) : loyaltyPrograms.length > 0 ? (
                <Select
                  name="loyalty_program_uuid"
                  value={formData.loyalty_program_uuid}
                  onChange={handleChange}
                  placeholder="Select Loyalty Program"
                >
                  {loyaltyPrograms.map((program) => (
                    <option key={program.uuid} value={program.uuid}>
                      {program.name}
                    </option>
                  ))}
                </Select>
              ) : (
                <Alert status="warning">
                  <AlertIcon />
                  No published loyalty programs available.
                </Alert>
              )}
            </FormControl>
          )}
          {/* Loyalty Level Selection (Optional) */}
          {formData.loyalty_program_uuid && (
            <FormControl id="loyalty_level_uuid">
              <FormLabel>Loyalty Level (Optional)</FormLabel>
              {loadingLoyaltyLevels ? (
                <Spinner size="sm" />
              ) : errorLoyaltyLevels ? (
                <Alert status="error">
                  <AlertIcon />
                  {errorLoyaltyLevels}
                </Alert>
              ) : filteredLoyaltyLevels.length > 0 ? (
                <Select
                  name="loyalty_level_uuid"
                  value={formData.loyalty_level_uuid}
                  onChange={handleChange}
                  placeholder="Select Loyalty Level (Optional)"
                >
                  {filteredLoyaltyLevels.map((level) => (
                    <option key={level.uuid} value={level.uuid}>
                      {level.name}
                    </option>
                  ))}
                </Select>
              ) : (
                <Alert status="info">
                  <AlertIcon />
                  No loyalty levels available for the selected program.
                </Alert>
              )}
            </FormControl>
          )}

          {/* Set Period Checkbox */}
          <FormControl id="setPeriod">
            <Checkbox
              name="setPeriod"
              isChecked={formData.setPeriod}
              onChange={(e) => {
                const isChecked = e.target.checked;
                setFormData((prev) => ({
                  ...prev,
                  setPeriod: isChecked,
                  start_time: isChecked ? prev.start_time : '',
                  end_time: isChecked ? prev.end_time : '',
                }));
              }}
            >
              Period (optional, if not set then always on)
            </Checkbox>
          </FormControl>

          {/* Start Time */}
          {formData.setPeriod && (
            <FormControl id="start_time">
              <FormLabel>Start Date (starting from)</FormLabel>
              <Input
                type="datetime-local"
                name="start_time"
                value={formData.start_time}
                onChange={handleChange}
                placeholder="Set Start Date"
              />
            </FormControl>
          )}

          {/* End Time */}
          {formData.setPeriod && (
            <FormControl id="end_time">
              <FormLabel>End Date (up to)</FormLabel>
              <Input
                type="datetime-local"
                name="end_time"
                value={formData.end_time}
                onChange={handleChange}
                placeholder="Set End Date"
              />
            </FormControl>
          )}

          {/* is_active Checkbox */}
          <FormControl id="is_active">
            <Checkbox
              name="is_active"
              isChecked={formData.is_active}
              onChange={handleChange}
            >
              Active
            </Checkbox>
          </FormControl>

          {/* Error Alert */}
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          {/* Success Alert */}
          {success && (
            <Alert status="success">
              <AlertIcon />
              {success}
            </Alert>
          )}

          {/* Submit Button */}
          <Button type="submit" colorScheme="blue">
            Save Discount
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default CreateDiscount;
