// ProductEdit.js
import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Button,
  useToast,
  Text,
  Switch,
  Icon,
  Spinner,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Input,
  Textarea,
  Select,
} from '@chakra-ui/react';
import React, { useState, useEffect, useCallback } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import Card from '../../components/card/Card';
import Dropzone from '../../components/products/Dropzone';
import { MdOutlineCloudUpload } from 'react-icons/md';
import Details from '../../components/products/Details';
import CategoryTreeSelect from '../../components/products/CategoryTreeSelect';

import { transformCategoriesForTreeSelect } from '../../utils/CategoryUtils';

import {
  getProductDetails,
  updateProduct,
  handleImageUpload,
} from '../../api/product';
import { getCategories } from '../../api/category';
import { setTranslation } from '../../utils/translationUtils';

export default function ProductEdit() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  useDisclosure();

  const [productId, setProductId] = useState(null);
  const [productPrice, setProductPrice] = useState(0);
  const [currentLanguage] = useState('en');
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [isFeatured, setIsFeatured] = useState(false);
  const [image, setImage] = useState(null);
  const [soldQuantity, setSoldQuantity] = useState(0);
  const [loading, setLoading] = useState(true);

  const [languages, setLanguages] = useState([
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
  ]);
  const [translations, setTranslations] = useState({
    en: {
      name: '',
      description: '',
      price: 0,
    },
    et: {
      name: '',
      description: '',
    },
  });
  const [quantity, setQuantity] = useState(0);
  const [languageToDelete, setLanguageToDelete] = useState(null);

  const [categories, setCategories] = useState([]);
  const [selectedCategoryUuid, setSelectedCategoryUuid] = useState('');
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);

  const [productType, setProductType] = useState('physical');

  const fetchProduct = useCallback(async () => {
    try {
      const product = await getProductDetails(uuid);

      setProductId(product.id);
      setProductPrice(product.price);
      setIsFeatured(product.is_featured);
      setQuantity(product.quantity || 0);
      setSoldQuantity(product.sold_quantity || 0);

      if (product.images && product.images.length > 0) {
        setImage(product.images[0].image_url);
      } else {
        console.warn('No images found for product.');
        setImage(null);
      }

      const translationsObj = {
        en: {
          name: product.translations.en.product_name || product.name,
          description: product.translations.en.product_description || '',
          price: product.price,
        },
        et: {
          name: product.translations.et.product_name || '',
          description: product.translations.et.product_description || '',
        },
      };

      setTranslations(translationsObj);

      if (product.category_uuid) {
        setSelectedCategory(product.category_uuid);
      } else {
        setSelectedCategory(null);
      }

      setProductType(product.product_type || 'physical');
    } catch (error) {
      console.error('Error fetching product:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch product details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid, toast]);

  useEffect(() => {
    if (categoriesData.length > 0) {
      const treeCategories = transformCategoriesForTreeSelect(
        categoriesData,
        currentLanguage,
      );

      if (selectedCategory) {
        const updatedTreeCategories = markSelectedCategory(
          treeCategories,
          selectedCategory,
        );
        setCategoriesTree(updatedTreeCategories);
      } else {
        setCategoriesTree(treeCategories);
      }
    }
  }, [categoriesData, selectedCategory, currentLanguage]);

  const markSelectedCategory = (nodes, selectedCategoryUuid) => {
    return nodes.map((node) => ({
      ...node,
      children: node.children
        ? markSelectedCategory(node.children, selectedCategoryUuid)
        : [],
    }));
  };

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoadingCategories(true);
      try {
        const data = await getCategories();
        setCategoriesData(data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        toast({
          title: 'Error fetching categories.',
          description: 'Unable to load categories. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingCategories(false);
      }
    };

    fetchCategories();
  }, [toast, currentLanguage]);

  const handleCategoryChange = (selectedUuid) => {
    setSelectedCategory(selectedUuid);
    console.log('Selected Category UUID:', selectedUuid);
  };

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  const handleImageChange = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validTypes.includes(file.type)) {
        console.error('Invalid file type:', file.type);
        toast({
          title: 'Invalid file type.',
          description: 'Only PNG, JPG, and GIF files are allowed.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        console.error('File size exceeds limit:', file.size);
        toast({
          title: 'File too large.',
          description: 'Image size should be less than 5MB.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setImage(file);
      console.log('Current Image:', file);
    } else {
      console.warn('No files were accepted by Dropzone.');
    }
  };

  const handleSave = async () => {
    const translationsPayload = {
      en: {
        product_name: translations.en.name,
        product_description: translations.en.description,
      },
      et: {
        product_name: translations.et.name,
        product_description: translations.et.description,
      },
    };

    const updatedProduct = {
      price: Number(productPrice),
      is_featured: isFeatured,
      is_active: true,
      is_archived: false,
      translations: translationsPayload,
      quantity: quantity,
      category_uuid: selectedCategory || null,
      product_type: productType || null,
    };

    try {
      await updateProduct(uuid, updatedProduct);

      if (image && typeof image !== 'string') {
        await handleImageUpload([image], uuid);
      }

      for (const lang of languages.map((l) => l.code)) {
        if (translations[lang]) {
          if (translations[lang].name?.trim()) {
            await setTranslation(
              `product_name_${uuid}`,
              translations[lang].name,
              lang,
            );
          }

          if (translations[lang].description?.trim()) {
            await setTranslation(
              `product_description_${uuid}`,
              translations[lang].description,
              lang,
            );
          }
        }
      }

      toast({
        title: 'Product updated.',
        description: 'The product has been updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate('/admin/products');
    } catch (error) {
      console.error('Error updating product:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while updating the product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleQuantityChange = (value) => {
    const newQuantity = parseInt(value, 10) || 0;
    if (newQuantity >= soldQuantity) {
      setQuantity(newQuantity);
    } else {
      toast({
        title: 'Invalid Quantity',
        description: 'Quantity cannot be less than the sold quantity.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleTranslationChange = (lang, field, value) => {
    setTranslations((prev) => ({
      ...prev,
      [lang]: {
        ...prev[lang],
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    return () => {
      if (image && typeof image === 'object') {
        URL.revokeObjectURL(image.preview);
      }
    };
  }, [image]);

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        columns={{ sm: 1, xl: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        {/* Left Column: Product Details */}
        <Flex direction="column">
          {/* Product Image */}
          <Card mb="20px">
            <Box width="100%" maxW={{ base: '100%', xl: '500px' }} mx="auto">
              <Image
                borderRadius="20px"
                width="100%"
                height="auto"
                objectFit="cover"
                src={
                  image && typeof image === 'object'
                    ? URL.createObjectURL(image)
                    : image || 'https://via.placeholder.com/396'
                }
                alt="Product Image"
                fallbackSrc="https://via.placeholder.com/396"
                onLoad={() => {
                  if (image && typeof image === 'object') {
                    console.log('Image loaded successfully:', image.name);
                  }
                }}
                onError={(err) => {
                  console.error('Error loading image:', err);
                  toast({
                    title: 'Image Load Error',
                    description:
                      'There was an error loading the image preview.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  });
                }}
              />
            </Box>
          </Card>

          {/* Product Info Form with Translations */}
          <Card p="30px" mb="20px">
            <Flex direction="column">
              <Text mb="20px" fontWeight="bold" fontSize="xl">
                Product ID: {productId || 'N/A'}
              </Text>
              {/* Language Tabs */}
              <Tabs variant="enclosed" colorScheme="blue">
                <TabList>
                  {languages.map((lang) => (
                    <Tab key={lang.code}>{lang.name}</Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {languages.map((lang) => (
                    <TabPanel key={lang.code}>
                      {/* Product Name for the selected language */}
                      <Box mb="20px">
                        <Text mb="10px">Product Name</Text>
                        <Input
                          placeholder="Product Name"
                          value={translations[lang.code]?.name || ''}
                          onChange={(e) =>
                            handleTranslationChange(
                              lang.code,
                              'name',
                              e.target.value,
                            )
                          }
                        />
                      </Box>
                      {/* Product Description for the selected language */}
                      <Box mb="20px">
                        <Text mb="10px">Product Description</Text>
                        <Textarea
                          placeholder="Product Description"
                          value={translations[lang.code]?.description || ''}
                          onChange={(e) =>
                            handleTranslationChange(
                              lang.code,
                              'description',
                              e.target.value,
                            )
                          }
                        />
                      </Box>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
              {/* Category Selection */}
              <Box mb="20px">
                <Text mb="10px" fontWeight="bold">
                  Select Category
                </Text>
                {isLoadingCategories ? (
                  <Flex justify="center" align="center" height="100px">
                    <Spinner />
                  </Flex>
                ) : (
                  <CategoryTreeSelect
                    categories={categoriesTree}
                    selectedCategoryUuid={selectedCategory}
                    onSelectCategory={handleCategoryChange}
                  />
                )}
              </Box>

              {/* **Product Type Selection** */}
              <Box mb="20px">
                <Text mb="10px" fontWeight="bold">
                  Product Type
                </Text>
                <Select
                  placeholder="Select product type"
                  value={productType}
                  onChange={(e) => {
                    console.log('Product Type changed to:', e.target.value);
                    setProductType(e.target.value);
                  }}
                >
                  <option value="physical">Physical</option>
                  <option value="virtual">Virtual</option>
                </Select>
              </Box>
              {/* End of Product Type Selection */}

              {/* Quantity Input */}
              <Box mb="20px">
                <Text mb="10px">Stock Quantity</Text>
                <Input
                  type="number"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={(e) => handleQuantityChange(e.target.value)}
                />
                <Text mt="10px">
                  <strong>Sold Quantity:</strong> {soldQuantity}
                </Text>
              </Box>
            </Flex>

            {/* Featured Product Switch */}
            <Flex align="center" mt="10px">
              <Text fontWeight="bold" mr="10px">
                Featured Product
              </Text>
              <Switch
                id="isFeatured"
                isChecked={isFeatured}
                onChange={(e) => {
                  console.log('Is Featured toggled to:', e.target.checked);
                  setIsFeatured(e.target.checked);
                }}
              />
            </Flex>
          </Card>
        </Flex>

        {/* Right Column: Images and Additional Details */}
        <Flex direction="column">
          {/* Dropzone component */}
          <Card p="30px" mb="20px">
            <Text fontSize="xl" fontWeight="bold" mb="10px">
              Product Images
            </Text>
            <Dropzone
              onDrop={(acceptedFiles) => handleImageChange(acceptedFiles)}
            >
              <Flex
                direction="column"
                align="center"
                justify="center"
                h="200px"
              >
                <Icon as={MdOutlineCloudUpload} w="50px" h="50px" mb="8px" />
                <Text fontSize="lg" fontWeight="bold">
                  Drag and drop an image here, or click to select a file
                </Text>
                <Text fontSize="sm" color="gray.500">
                  PNG, JPG, and GIF files are allowed
                </Text>
              </Flex>
            </Dropzone>

            {/* Display selected file details */}
            {image && typeof image === 'object' && (
              <Box mt="10px">
                <Text fontSize="sm" color="gray.600">
                  Selected file: {image.name} ({(image.size / 1024).toFixed(2)}{' '}
                  KB)
                </Text>
              </Box>
            )}
          </Card>

          {/* Details component */}
          <Details
            productPrice={productPrice}
            setProductPrice={(value) => {
              console.log('Product Price changed to:', value);
              setProductPrice(value);
            }}
          />

          {/* Submit Button */}
          <Button
            mt="20px"
            colorScheme="blue"
            onClick={handleSave}
            isDisabled={!productPrice}
          >
            Save Changes
          </Button>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
