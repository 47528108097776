// src/components/discounts/DiscountTemplates.jsx

import React, { useState } from 'react';
import {
  Flex,
  Box,
  Icon,
  Text,
  Badge,
  Stack,
  IconButton,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  MdChevronLeft,
  MdChevronRight,
  MdLightbulb,
  MdWarning,
} from 'react-icons/md';
import discountTemplates from './templates';

const DiscountTemplates = () => {
  const scrollRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const cardBg = useColorModeValue('white', 'navy.800');
  const textColorPrimary = useColorModeValue('navy.700', 'white');
  const textColorSecondary = useColorModeValue('gray.500', 'white');

  const scroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  const openTemplateDetails = (template) => {
    setSelectedTemplate(template);
    onOpen();
  };

  // Add state for insights count
  const [insightsCount] = useState(8); // You can make this dynamic based on actual insights

  return (
    <Accordion allowToggle defaultIndex={[]} mb={0}>
      <AccordionItem border="none">
        <AccordionButton px={0}>
          <Box flex="1" textAlign="left">
            <Flex align="center">
              <Icon
                as={MdLightbulb}
                color="yellow.500"
                w="24px"
                h="24px"
                mr={2}
              />
              <Heading size="md" color={textColorPrimary}>
                Discounts Smart Insights Advisor
              </Heading>
              <Badge
                ml={2}
                colorScheme="blue"
                variant="solid"
                borderRadius="full"
                fontSize="xs"
                px={2}
              >
                {insightsCount} New
              </Badge>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={2} pt={2}>
          <Box position="relative">
            <IconButton
              aria-label="Scroll left"
              icon={<MdChevronLeft />}
              onClick={() => (scrollRef.current.scrollLeft -= 300)}
              position="absolute"
              left={2}
              top="50%"
              transform="translateY(-50%)"
              zIndex={2}
              variant="ghost"
              colorScheme="gray"
              _hover={{ bg: 'rgba(0, 0, 0, 0.1)' }}
              isRound
              size="lg"
            />

            <Box
              ref={scrollRef}
              overflowX="auto"
              css={{
                '&::-webkit-scrollbar': { display: 'none' },
                scrollbarWidth: 'none',
                scrollBehavior: 'smooth',
              }}
              px={6}
            >
              <Flex gap={6}>
                {discountTemplates.map((template, index) => (
                  <Box
                    key={index}
                    minW="300px" // Fixed width for each card
                    bg={cardBg}
                    p={6}
                    borderRadius="xl"
                    boxShadow="sm"
                    cursor="pointer"
                    transition="transform 0.2s"
                    _hover={{ transform: 'translateY(-5px)' }}
                    onClick={() => openTemplateDetails(template)}
                  >
                    <Flex align="center" mb={3}>
                      <Icon
                        as={template.icon}
                        color={template.color}
                        boxSize={6}
                        mr={3}
                      />
                      <Text fontWeight="bold" color={textColorPrimary}>
                        {template.title}
                      </Text>
                    </Flex>
                    <Text fontSize="sm" color={textColorSecondary} mb={3}>
                      {template.description}
                    </Text>
                    <Stack spacing={3}>
                      <Box>
                        <Text fontWeight="semibold" fontSize="sm">
                          Strategy:
                        </Text>
                        <Text fontSize="sm" color={textColorSecondary}>
                          Structure: {template.strategy.structure}
                        </Text>
                        <Text fontSize="sm" color={textColorSecondary}>
                          Timing: {template.strategy.timing}
                        </Text>
                        <Text fontSize="sm" color={textColorSecondary}>
                          Application: {template.strategy.application}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontWeight="semibold" fontSize="sm">
                          Recommended for:
                        </Text>
                        <Flex wrap="wrap" gap={2}>
                          {template.recommendedFor.map((tag, i) => (
                            <Badge key={i} colorScheme="brand" variant="subtle">
                              {tag}
                            </Badge>
                          ))}
                        </Flex>
                      </Box>
                    </Stack>
                  </Box>
                ))}
              </Flex>
            </Box>

            <IconButton
              aria-label="Scroll right"
              icon={<MdChevronRight />}
              onClick={() => (scrollRef.current.scrollLeft += 300)}
              position="absolute"
              right={2}
              top="50%"
              transform="translateY(-50%)"
              zIndex={2}
              variant="ghost"
              colorScheme="gray"
              _hover={{ bg: 'rgba(0, 0, 0, 0.1)' }}
              isRound
              size="lg"
            />
          </Box>
        </AccordionPanel>
      </AccordionItem>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedTemplate?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {selectedTemplate && (
              <Stack spacing={4}>
                <Text>{selectedTemplate.description}</Text>
                <Box>
                  <Text fontWeight="bold" mb={2}>
                    Strategy:
                  </Text>
                  <Text>Structure: {selectedTemplate.strategy.structure}</Text>
                  <Text>Timing: {selectedTemplate.strategy.timing}</Text>
                  <Text>
                    Application: {selectedTemplate.strategy.application}
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={2}>
                    Best For:
                  </Text>
                  <Flex wrap="wrap" gap={2}>
                    {selectedTemplate.recommendedFor.map((tag, i) => (
                      <Badge key={i} colorScheme="brand">
                        {tag}
                      </Badge>
                    ))}
                  </Flex>
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={2}>
                    Key Metrics:
                  </Text>
                  <Flex wrap="wrap" gap={2}>
                    {selectedTemplate.kpis.map((kpi, i) => (
                      <Badge key={i} variant="outline">
                        {kpi}
                      </Badge>
                    ))}
                  </Flex>
                </Box>
                <Flex align="center" color="orange.500">
                  <Icon as={MdWarning} mr={2} />
                  <Text fontSize="sm">{selectedTemplate.warning}</Text>
                </Flex>
              </Stack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Accordion>
  );
};

export default DiscountTemplates;
