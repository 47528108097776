// NewProduct.js

import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Icon,
  useToast,
  Image,
  Text,
  Switch,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Spinner,
  Select,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card/Card';
import InputField from '../../components/fields/InputField';
import Dropzone from '../../components/products/Dropzone';
import { MdOutlineCloudUpload } from 'react-icons/md';
import Details from '../../components/products/Details';
import { createProduct, handleImageUpload } from '../../api/product';
import { getCategories } from '../../api/category';
import { setTranslation } from '../../utils/translationUtils';
import CategoryTreeSelect from '../../components/products/CategoryTreeSelect';
import { transformCategoriesForTreeSelect } from '../../utils/CategoryUtils';

export default function NewProduct() {
  const navigate = useNavigate();
  const toast = useToast();

  const [isFeatured, setIsFeatured] = useState(false);
  const [image, setImage] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [productPrice, setProductPrice] = useState('');
  const [languages, setLanguages] = useState([
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
  ]);
  const [translations, setTranslations] = useState({
    en: { name: '', description: '' },
    et: { name: '', description: '' },
  });

  const [productType, setProductType] = useState('physical');

  const [categories, setCategories] = useState([]);
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [selectedCategoryUuid, setSelectedCategoryUuid] = useState('');
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoadingCategories(true);
      try {
        const data = await getCategories();
        setCategories(data);
        const treeCategories = transformCategoriesForTreeSelect(data, 'en');
        setCategoriesTree(treeCategories);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        toast({
          title: 'Error fetching categories.',
          description: 'Unable to load categories. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingCategories(false);
      }
    };

    fetchCategories();
  }, [toast]);

  const handleImageDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      console.log('Accepted file:', file);
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validTypes.includes(file.type)) {
        console.error('Invalid file type:', file.type);
        toast({
          title: 'Invalid file type.',
          description: 'Only PNG, JPG, and GIF files are allowed.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        console.error('File size exceeds limit:', file.size);
        toast({
          title: 'File too large.',
          description: 'Image size should be less than 5MB.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      setImage(file);
      console.log('Image set in state:', file);
    } else {
      console.warn('No files were accepted by Dropzone.');
    }
  };

  const handleSubmit = async () => {
    const translationsPayload = {};
    languages.forEach((lang) => {
      translationsPayload[lang.code] = {
        product_name: translations[lang.code].name,
        product_description: translations[lang.code].description,
      };
    });

    const data = {
      price: Number(productPrice),
      is_featured: isFeatured,
      is_active: true,
      is_archived: false,
      quantity: quantity,
      category_uuid: selectedCategoryUuid || null,
      product_type: productType,
      translations: translationsPayload,
    };

    console.log('Submitting product data:', data);
    console.log('Current image state:', image);

    try {
      const response = await createProduct(data);
      console.log('Create Product Response:', response);

      const { uuid } = response;
      console.log('Extracted UUID:', uuid);

      if (!uuid) {
        throw new Error('Product UUID not returned from the server.');
      }

      if (image) {
        console.log('Uploading Image:', image);
        const uploadResponse = await handleImageUpload([image], uuid);
        console.log('Image upload response:', uploadResponse);
        console.log('Image uploaded successfully');
      } else {
        console.log('No image to upload.');
      }

      for (const lang of languages.map((l) => l.code)) {
        if (translations[lang]) {
          if (translations[lang].name?.trim()) {
            await setTranslation(
              `product_name_${uuid}`,
              translations[lang].name,
              lang,
            );
          }

          if (translations[lang].description?.trim()) {
            await setTranslation(
              `product_description_${uuid}`,
              translations[lang].description,
              lang,
            );
          }
        }
      }

      toast({
        title: 'Product created.',
        description: 'The product has been created successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate('/admin/products');
    } catch (error) {
      console.error('Error creating product:', error);
      if (error.response) {
        console.error('Server response:', error.response.data);
      }
      toast({
        title: 'Error',
        description:
          error.message || 'An error occurred while creating the product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleQuantityChange = (value) => {
    const newQuantity = parseInt(value, 10) || 0;
    if (newQuantity < 0) {
      console.warn('Quantity cannot be negative!.');
      toast({
        title: 'Invalid Quantity',
        description: 'Quantity cannot be negative!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setQuantity(0);
    } else {
      console.log('Quantity changed to:', newQuantity);
      setQuantity(newQuantity);
    }
  };

  useEffect(() => {
    return () => {
      if (image && typeof image === 'object') {
        URL.revokeObjectURL(image.preview);
      }
    };
  }, [image]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        columns={{ sm: 1, xl: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        {/* Left Column */}
        <Flex direction="column">
          {/* Product Image */}
          <Card mb="20px">
            <Box width="100%" maxW={{ base: '100%', xl: '500px' }} mx="auto">
              <Image
                borderRadius="20px"
                width="100%"
                height="auto"
                objectFit="cover"
                src={
                  image && typeof image === 'object'
                    ? URL.createObjectURL(image)
                    : 'https://via.placeholder.com/400x300'
                }
                alt="Product Image Preview"
                onLoad={() => {
                  if (image) {
                    console.log('Image loaded successfully:', image.name);
                  }
                }}
                onError={(err) => {
                  console.error('Error loading image:', err);
                  toast({
                    title: 'Image Load Error',
                    description:
                      'There was an error loading the image preview.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  });
                }}
                fallbackSrc="https://via.placeholder.com/400x300" // Optional: Placeholder image
              />
            </Box>
          </Card>
          {/* Product Info Form with Translations */}
          <Card p="30px">
            <Flex direction="column">
              {/* Language Tabs */}
              <Tabs variant="enclosed" colorScheme="blue">
                <TabList>
                  {languages.map((lang) => (
                    <Tab key={lang.code}>{lang.name}</Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {languages.map((lang) => (
                    <TabPanel key={lang.code}>
                      {/* Product Name for the selected language */}
                      <InputField
                        mb="20px"
                        id={`productName-${lang.code}`}
                        label="Product Name"
                        placeholder="Product Name"
                        value={translations[lang.code].name}
                        onChange={(e) => {
                          const value = e.target.value;
                          setTranslations((prev) => ({
                            ...prev,
                            [lang.code]: {
                              ...prev[lang.code],
                              name: value,
                            },
                          }));
                        }}
                      />
                      {/* Product Description for the selected language */}
                      <InputField
                        mb="20px"
                        id={`productDescription-${lang.code}`}
                        label="Product Description"
                        placeholder="Product Description"
                        value={translations[lang.code].description}
                        onChange={(e) => {
                          const value = e.target.value;
                          setTranslations((prev) => ({
                            ...prev,
                            [lang.code]: {
                              ...prev[lang.code],
                              description: value,
                            },
                          }));
                        }}
                        variant="textarea"
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>

              {/* Category Selection */}
              <Box mb="20px">
                <Text fontWeight="bold" mb="8px">
                  Category
                </Text>
                {isLoadingCategories ? (
                  <Flex justify="center" align="center" height="100px">
                    <Spinner />
                  </Flex>
                ) : categories.length > 0 ? (
                  <CategoryTreeSelect
                    categories={categoriesTree}
                    selectedCategoryUuid={selectedCategoryUuid}
                    onSelectCategory={(uuid) => {
                      console.log('Selected Category UUID:', uuid);
                      setSelectedCategoryUuid(uuid);
                    }}
                  />
                ) : (
                  <Flex align="center">
                    <Text>No categories available.</Text>
                    <Button
                      ml="10px"
                      colorScheme="blue"
                      size="sm"
                      onClick={() => navigate('/admin/categories/new')} // Adjust the route as needed
                    >
                      Create Category
                    </Button>
                  </Flex>
                )}
              </Box>

              {/* Quantity Input */}
              <InputField
                mb="20px"
                id="quantity"
                label="Quantity"
                placeholder="Quantity"
                type="number"
                value={quantity}
                onChange={(e) => handleQuantityChange(e.target.value)}
              />

              {/* Product Type Selector */}
              <Box mb="20px">
                <Text mb="8px" fontWeight="bold">
                  Product Type
                </Text>
                <Select
                  placeholder="Select product type"
                  value={productType}
                  onChange={(e) => {
                    console.log('Product Type changed to:', e.target.value);
                    setProductType(e.target.value);
                  }}
                >
                  <option value="physical">Physical</option>
                  <option value="virtual">Virtual</option>
                </Select>
              </Box>

              {/* Featured Product Switch */}
              <Flex align="center" mt="10px">
                <Text fontWeight="bold" mr="10px">
                  Featured Product
                </Text>
                <Switch
                  id="isFeatured"
                  isChecked={isFeatured}
                  onChange={(e) => {
                    console.log('Is Featured toggled to:', e.target.checked);
                    setIsFeatured(e.target.checked);
                  }}
                />
              </Flex>
            </Flex>
          </Card>
        </Flex>
        {/* Right Column */}
        <Flex direction="column">
          {/* Dropzone component */}
          <Card p="30px" mb="20px">
            <Text fontSize="xl" fontWeight="bold" mb="10px">
              Product Images
            </Text>
            <Dropzone
              onDrop={(acceptedFiles) => handleImageDrop(acceptedFiles)}
            >
              <Flex
                direction="column"
                align="center"
                justify="center"
                h="200px"
              >
                <Icon as={MdOutlineCloudUpload} w="50px" h="50px" mb="8px" />
                <Text fontSize="lg" fontWeight="bold">
                  Drag and drop an image here, or click to select a file
                </Text>
                <Text fontSize="sm" color="gray.500">
                  PNG, JPG, and GIF files are allowed
                </Text>
              </Flex>
            </Dropzone>
            {/* Display selected file details */}
            {image && (
              <Box mt="10px">
                <Text fontSize="sm" color="gray.600">
                  Selected file: {image.name} ({(image.size / 1024).toFixed(2)}{' '}
                  KB)
                </Text>
              </Box>
            )}
          </Card>
          {/* Details component */}
          <Details
            productPrice={productPrice}
            setProductPrice={(value) => {
              console.log('Product Price changed to:', value);
              setProductPrice(value);
            }}
          />
          {/* Submit Button */}
          <Button
            mt="20px"
            colorScheme="blue"
            onClick={handleSubmit}
            isDisabled={
              !translations.en.name ||
              !productPrice ||
              quantity < 1 ||
              !selectedCategoryUuid
            }
          >
            Submit
          </Button>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
