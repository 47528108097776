import useFetch from './useFetch';
import { getLoyaltyPrograms } from '../api/product';
import { getTranslatedValue } from '../utils/translationUtils';

const CURRENT_LANGUAGE = 'en';

const useLoyaltyPrograms = () => {
  const { data, loading, error } = useFetch(getLoyaltyPrograms, []);

  const processedData = data
    ? data
        .filter((program) => program.is_published === 1)
        .map((program) => ({
          ...program,
          name: getTranslatedValue(
            program.translations,
            'name',
            CURRENT_LANGUAGE,
          ),
          description: getTranslatedValue(
            program.translations,
            'description',
            CURRENT_LANGUAGE,
          ),
        }))
    : [];

  return { loyaltyPrograms: processedData, loading, error };
};

export default useLoyaltyPrograms;
