import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useDeferredValue,
} from 'react';
import {
  Box,
  Flex,
  Select,
  Spinner,
  Text,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Badge,
  Input,
  Card,
  Icon,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';

import { SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  MdEdit,
  MdFilterList,
  MdArrowDownward,
  MdArrowUpward,
} from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { getOrders } from '../../api/order';
import Pagination from '../../components/pagination';
import { format } from 'date-fns';
import DebouncedInput from '../../components/common/DebouncedInput';
import useBrandColor from '../../hooks/useBrandColor';
import debounce from 'lodash/debounce';
import SearchInput from '../../components/orders/SearchInput';

export default function OrdersOverview() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get the current page from URL or default to 1
  const currentPage = parseInt(searchParams.get('page')) || 1;

  const brandColor = useBrandColor();

  // Initialize state from URL parameters
  const [productTypeFilter, setProductTypeFilter] = useState(
    searchParams.get('product_type') || 'all',
  );

  const [statusFilter, setStatusFilter] = useState(
    searchParams.get('status') || 'all',
  );
  const [dateRange, setDateRange] = useState({
    start: searchParams.get('start_date') || '',
    end: searchParams.get('end_date') || '',
  });
  const [pagination, setPagination] = useState({
    pageIndex: currentPage - 1,
    pageSize: parseInt(searchParams.get('limit')) || 20,
    pageCount: 0,
    total: 0,
  });
  const [sorting, setSorting] = useState([{ id: 'purchase_date', desc: true }]);

  // Update URL when filters change
  const updateUrlParams = useCallback(
    (newParams) => {
      const current = Object.fromEntries([...searchParams]);
      const updated = { ...current, ...newParams };

      // Remove undefined or empty string values
      Object.keys(updated).forEach(
        (key) =>
          (updated[key] === undefined || updated[key] === '') &&
          delete updated[key],
      );

      setSearchParams(updated, { replace: true }); // Use replace to prevent adding to history
    },
    [searchParams, setSearchParams],
  );

  const handleProductTypeChange = useCallback(
    (value) => {
      setProductTypeFilter(value);
      updateUrlParams({
        product_type: value !== 'all' ? value : undefined,
        page: 1,
      });
    },
    [updateUrlParams],
  );

  // Handle page change
  const handlePageChange = useCallback(
    (newPage) => {
      setPagination((prev) => ({
        ...prev,
        pageIndex: newPage,
      }));
      updateUrlParams({ page: newPage + 1 }); // Add 1 because pageIndex is 0-based
    },
    [updateUrlParams],
  );

  // Update handlers to use URL state
  const handleStatusChange = useCallback(
    (value) => {
      setStatusFilter(value);
      updateUrlParams({
        status: value !== 'all' ? value : undefined,
        page: 1,
      });
    },
    [updateUrlParams],
  );

  const handleDateRangeChange = useCallback(
    (type, value) => {
      setDateRange((prev) => ({ ...prev, [type]: value }));
      updateUrlParams({
        [type === 'start' ? 'start_date' : 'end_date']: value,
        page: 1,
      });
    },
    [updateUrlParams],
  );

  const handleSearch = useCallback(
    (value) => {
      const current = Object.fromEntries([...searchParams]);
      if (value) {
        current.search = value;
      } else {
        delete current.search;
      }
      current.page = '1';
      setSearchParams(current, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  // Add debounced search handler
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        handleSearch(value);
      }, 500),
    [handleSearch],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const currentSort = sorting[0] || { id: 'purchase_date', desc: true };

        const filters = {
          status: statusFilter,
          start_date: dateRange.start,
          end_date: dateRange.end,
          global_filter: searchParams.get('search'),
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          sort_by: currentSort.id,
          sort_order: currentSort.desc ? 'desc' : 'asc',
        };
        if (productTypeFilter !== 'all') {
          filters.product_type = productTypeFilter;
        }

        const response = await getOrders(filters);

        setOrders(
          response.orders.map((order) => ({
            id: order.id,
            uuid: order.uuid,
            user_email: order.user_email || order.user_primary_email || 'N/A',
            product_name: order.product_name,
            product_type: order.product_type,
            purchase_date: new Date(order.purchase_date).toISOString(),
            purchase_price: parseFloat(order.purchase_price),
            status: order.status?.toLowerCase() || 'unknown',
          })),
        );

        setPagination((prev) => ({
          ...prev,
          pageCount: response.pagination.pageCount,
          total: response.pagination.total,
        }));
      } catch (error) {
        console.error('Error fetching orders:', error);
        toast({
          title: 'Error fetching orders',
          description: 'Unable to load orders. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [
    productTypeFilter,
    pagination.pageIndex,
    pagination.pageSize,
    statusFilter,
    dateRange,
    searchParams,
    sorting,
    toast,
  ]);

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const bgColor = useColorModeValue('white', 'navy.700');
  const shadowColor = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    '14px 17px 40px 4px rgba(12, 44, 55, 0.18)',
  );

  const getStatusColor = (status) => {
    const statusColors = {
      complete: 'green',
      paid: 'purple',
      processing: 'yellow',
      initiated: 'cyan',
      failed: 'red',
    };
    return statusColors[status] || 'gray';
  };

  const columnHelper = createColumnHelper();

  const SortableHeader = ({ column, children }) => {
    return (
      <Flex
        cursor="pointer"
        onClick={() => column.toggleSorting()}
        userSelect="none"
        align="center"
      >
        {children}
        <Box ml={1}>
          {column.getIsSorted() && (
            <Icon
              as={
                column.getIsSorted() === 'desc'
                  ? MdArrowDownward
                  : MdArrowUpward
              }
              w={4}
              h={4}
            />
          )}
        </Box>
      </Flex>
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: ({ column }) => (
          <SortableHeader column={column}>ID</SortableHeader>
        ),
        sortingFn: (rowA, rowB) => {
          return parseInt(rowA.original.id) - parseInt(rowB.original.id);
        },
        cell: (info) => (
          <Text color={textColor} fontWeight="500" fontSize="sm" minW="40px">
            #{info.getValue()}
          </Text>
        ),
        size: 50,
        maxWidth: 50,
      }),
      columnHelper.accessor('user_email', {
        header: ({ column }) => (
          <SortableHeader column={column}>Customer</SortableHeader>
        ),
        sortingFn: (rowA, rowB) => {
          return rowA.original.user_email.localeCompare(
            rowB.original.user_email,
          );
        },
        cell: (info) => (
          <Text
            color={textColor}
            fontSize="sm"
            noOfLines={1}
            title={info.getValue()}
          >
            {info.getValue()}
          </Text>
        ),
        size: 200,
        maxWidth: 200,
      }),
      columnHelper.accessor('product_name', {
        header: ({ column }) => (
          <SortableHeader column={column}>Product</SortableHeader>
        ),
        cell: (info) => (
          <Text
            color={textColor}
            fontSize="sm"
            noOfLines={1}
            title={info.getValue()}
          >
            {info.getValue()}
          </Text>
        ),
        size: 200,
        maxWidth: 250,
      }),
      columnHelper.accessor('purchase_date', {
        header: ({ column }) => (
          <SortableHeader column={column}>
            <Text textAlign="right" w="100%">
              Purchase Date
            </Text>
          </SortableHeader>
        ),
        sortingFn: (rowA, rowB) => {
          const dateA = new Date(rowA.original.purchase_date);
          const dateB = new Date(rowB.original.purchase_date);
          return dateA.getTime() - dateB.getTime();
        },
        cell: (info) => (
          <Text color={textColor} fontSize="sm" textAlign="right">
            {format(new Date(info.getValue()), 'MMM dd, yyyy')}
          </Text>
        ),
        size: 120,
        maxWidth: 120,
      }),
      columnHelper.accessor('purchase_price', {
        header: ({ column }) => (
          <SortableHeader column={column}>
            <Text textAlign="right" w="100%">
              Price
            </Text>
          </SortableHeader>
        ),
        sortingFn: (rowA, rowB) => {
          return rowA.original.purchase_price - rowB.original.purchase_price;
        },
        cell: (info) => (
          <Text color={textColor} fontSize="sm" textAlign="right">
            €{parseFloat(info.getValue()).toFixed(2)}
          </Text>
        ),
        size: 100,
        maxWidth: 100,
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => (
          <Flex justify="center">
            <Badge
              colorScheme={getStatusColor(info.getValue())}
              fontSize="sm"
              px={2}
              py={1}
              borderRadius="full"
            >
              {info.getValue().charAt(0).toUpperCase() +
                info.getValue().slice(1)}
            </Badge>
          </Flex>
        ),
        size: 100,
        maxWidth: 100,
      }),
      columnHelper.accessor('uuid', {
        header: '',
        cell: (info) => (
          <Button
            variant="ghost"
            size="sm"
            color={brandColor}
            leftIcon={<Icon as={MdEdit} />}
            onClick={() => navigate(`/admin/orders/${info.getValue()}`)}
            _hover={{ bg: 'transparent' }}
          ></Button>
        ),
        size: 50,
        maxWidth: 50,
      }),
    ],
    [columnHelper, navigate, textColor, brandColor],
  );

  // Filter orders based on status and date range
  const filteredOrders = useMemo(() => {
    return orders.filter((order) => {
      // Product type filter
      if (
        productTypeFilter !== 'all' &&
        order.product_type !== productTypeFilter
      ) {
        return false;
      }
      // Status filter
      if (statusFilter !== 'all' && order.status !== statusFilter) {
        return false;
      }

      // Date range filter
      if (dateRange.start || dateRange.end) {
        const orderDate = new Date(order.purchase_date);
        if (dateRange.start && orderDate < new Date(dateRange.start)) {
          return false;
        }
        if (dateRange.end && orderDate > new Date(dateRange.end)) {
          return false;
        }
      }

      // Global search filter
      if (searchParams.get('search')) {
        const searchTerm = searchParams.get('search').toLowerCase();
        return (
          order.id.toString().toLowerCase().includes(searchTerm) ||
          order.user_email.toLowerCase().includes(searchTerm) ||
          order.product_name.toLowerCase().includes(searchTerm) ||
          order.status.toLowerCase().includes(searchTerm)
        );
      }

      return true;
    });
  }, [orders, statusFilter, dateRange, searchParams, productTypeFilter]);

  // Update table configuration
  const table = useReactTable({
    data: filteredOrders,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount: pagination.pageCount,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (loading) {
    return (
      <Flex justify="center" align="center" h="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex p={6} mb={2} justify="space-between" align="center">
          <Box flex={1}>
            <SearchInput
              onSearch={handleSearch}
              initialValue={searchParams.get('search') || ''}
            />
          </Box>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              leftIcon={<Icon as={MdFilterList} />}
              variant="outline"
              ml={4}
            >
              Filters
            </MenuButton>
            <MenuList>
              <Box px={4} py={2}>
                <Text mb={2} fontWeight="medium" fontSize="sm">
                  Product Type
                </Text>
                <Select
                  value={productTypeFilter}
                  onChange={(e) => handleProductTypeChange(e.target.value)}
                  size="sm"
                  mb={3}
                >
                  <option value="all">All Products</option>
                  <option value="regular">Regular</option>
                  <option value="loyalty">Loyalty</option>
                </Select>
                <Text mb={2} fontWeight="medium" fontSize="sm">
                  Status
                </Text>
                <Select
                  value={statusFilter}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  size="sm"
                  mb={3}
                >
                  <option value="all">All Statuses</option>
                  <option value="initiated">Initiated</option>
                  <option value="processing">Processing</option>
                  <option value="paid">Paid</option>
                  <option value="complete">Complete</option>
                  <option value="failed">Failed</option>
                </Select>

                <Text mb={2} fontWeight="medium" fontSize="sm">
                  Date Range
                </Text>
                <Flex gap={2}>
                  <Input
                    type="date"
                    value={dateRange.start}
                    onChange={(e) =>
                      handleDateRangeChange('start', e.target.value)
                    }
                    size="sm"
                  />
                  <Input
                    type="date"
                    value={dateRange.end}
                    onChange={(e) =>
                      handleDateRangeChange('end', e.target.value)
                    }
                    size="sm"
                  />
                </Flex>
              </Box>
            </MenuList>
          </Menu>
        </Flex>

        <Box px={6} pb={6}>
          <Box overflowX="auto" maxW="100%">
            <Table variant="simple" size="sm">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const content = flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      );

                      return (
                        <Th
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                          cursor={
                            header.column.getCanSort() ? 'pointer' : 'default'
                          }
                          color="gray.400"
                          borderColor={borderColor}
                        >
                          {content}
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td key={cell.id} borderColor={borderColor}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <Flex justify="space-between" align="center" mt={4}>
            <Text fontSize="sm" color="gray.600">
              Showing {orders.length} of {pagination.total} orders
            </Text>
            <Pagination
              currentPage={pagination.pageIndex}
              pageCount={pagination.pageCount}
              onChange={handlePageChange}
            />
          </Flex>
        </Box>
      </Card>
    </Box>
  );
}
