// src/data/discountTemplates.js

import {
  MdTrendingUp,
  MdCalendarToday,
  MdShoppingCart,
  MdPeople,
  MdAccessTime,
  MdStars,
  MdBolt,
  MdCategory,
} from 'react-icons/md';

const discountTemplates = [
  {
    icon: MdTrendingUp,
    color: 'emerald.500',
    title: 'Tier-Based Progressive Discount',
    description:
      'Reward higher tier members with better discounts to encourage program progression',
    strategy: {
      structure: 'Bronze: 5%, Silver: 10%, Gold: 15%, Platinum: 20%',
      timing: 'Permanent for tier members',
      application: 'Automatically applied based on member tier',
    },
    recommendedFor: [
      'Luxury brands',
      'High-margin products',
      'Subscription services',
    ],
    kpis: [
      'Tier progression rate',
      'Average order value',
      'Customer lifetime value',
    ],
    warning: 'Ensure margins can support higher tier discounts',
  },
  {
    icon: MdCalendarToday,
    color: 'blue.500',
    title: 'Time-Limited Flash Sale',
    description: 'Create urgency with short-duration deep discounts',
    strategy: {
      structure: '25-40% off for 24-48 hours',
      timing: 'Random or planned short intervals',
      application: 'Limited time window, all customers',
    },
    recommendedFor: [
      'Seasonal products',
      'Excess inventory',
      'New product launches',
    ],
    kpis: ['Sales velocity', 'Conversion rate', 'Traffic spike'],
    warning: "Don't overuse to avoid discount dependency",
  },
  {
    icon: MdShoppingCart,
    color: 'purple.500',
    title: 'Bundle Value Discount',
    description: 'Encourage larger purchases with progressive cart discounts',
    strategy: {
      structure: 'Spend €50: 5% off, €100: 10% off, €200: 15% off',
      timing: 'Ongoing or seasonal',
      application: 'Automatic at cart thresholds',
    },
    recommendedFor: [
      'Multi-product retailers',
      'High average order value stores',
    ],
    kpis: ['Cart size', 'Units per transaction', 'Margin per order'],
    warning: 'Set thresholds above average order value',
  },
  {
    icon: MdPeople,
    color: 'orange.500',
    title: 'New Customer Acquisition',
    description: 'Convert first-time visitors with welcome discount',
    strategy: {
      structure: '15-20% off first purchase',
      timing: 'One-time use',
      application: 'Unique code for new customers',
    },
    recommendedFor: [
      'New brands',
      'Competitive markets',
      'High lifetime value products',
    ],
    kpis: ['New customer acquisition cost', 'First purchase conversion rate'],
    warning: 'Verify new customer status before applying',
  },
  {
    icon: MdAccessTime,
    color: 'red.500',
    title: 'Off-Peak Activation',
    description: 'Drive sales during slow periods with tactical discounts',
    strategy: {
      structure: '10-15% off during specific hours/days',
      timing: 'During identified slow periods',
      application: 'Time-based automatic application',
    },
    recommendedFor: [
      'Restaurants',
      'Service businesses',
      'Seasonal businesses',
    ],
    kpis: ['Off-peak revenue lift', 'Capacity utilization'],
    warning: 'Avoid cannibalizing peak period sales',
  },
  {
    icon: MdStars,
    color: 'yellow.500',
    title: 'VIP Early Access',
    description: 'Give top customers privileged access to sales',
    strategy: {
      structure: 'Early access + additional 5-10% off',
      timing: '24-48 hours before public sale',
      application: 'Exclusive to top tier customers',
    },
    recommendedFor: [
      'Fashion retailers',
      'Limited edition products',
      'Premium brands',
    ],
    kpis: ['VIP engagement rate', 'Early access conversion'],
    warning: 'Ensure sufficient inventory for early access',
  },
  {
    icon: MdBolt,
    color: 'indigo.500',
    title: 'Quick Return Incentive',
    description: 'Encourage rapid repeat purchases',
    strategy: {
      structure: '20% off if used within 7 days of last purchase',
      timing: 'Short window after purchase',
      application: 'Time-sensitive unique code',
    },
    recommendedFor: ['Consumable products', 'Fashion', 'Regular use items'],
    kpis: ['Return customer rate', 'Time between purchases'],
    warning: 'Monitor for potential abuse',
  },
  {
    icon: MdCategory,
    color: 'pink.500',
    title: 'Category Boost',
    description: 'Drive sales in specific product categories',
    strategy: {
      structure: '25% off selected category',
      timing: '1-2 weeks',
      application: 'Category-specific automatic discount',
    },
    recommendedFor: ['Multi-category retailers', 'New category launches'],
    kpis: ['Category revenue growth', 'Cross-category purchase rate'],
    warning: 'Rotate categories to maintain interest',
  },
];

export default discountTemplates;
