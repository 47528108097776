import { Routes, Route, Navigate } from 'react-router-dom';
import React from 'react';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import ProtectedRoute from './components/ProtectedRoute';
import { ChakraProvider } from '@chakra-ui/react';
import CookieConsentComponent from './components/cookies/CookieConsentComponent';
import initialTheme from './theme/theme';
import { AuthProvider } from './contexts/AuthContext';
import { AutomationProvider } from 'contexts/AutomationContext';
import { NavigationProvider } from './contexts/NavigationContext';
import ReactGA from 'react-ga';
import './assets/css/App.css';

function App() {
  const TRACKING_ID = 'G-PXWQPHWWP1';
  ReactGA.initialize(TRACKING_ID);

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  //   const tagManagerArgs = {
  //     gtmId: 'GTM-PP7MNZXN',
  //   };
  //   TagManager.initialize(tagManagerArgs);
  //   clarity.init('omm4aiv4vw');
  // }, []);

  return (
    <AuthProvider>
      <ChakraProvider theme={initialTheme}>
        <AutomationProvider>
          <NavigationProvider>
            <Routes>
              <Route path="auth/*" element={<AuthLayout />} />
              <Route
                path="admin/*"
                element={
                  <ProtectedRoute>
                    <AdminLayout theme={initialTheme} />
                  </ProtectedRoute>
                }
              />
              <Route path="/" element={<Navigate to="/admin" replace />} />
            </Routes>
            <CookieConsentComponent /> {/* Updated component usage */}
          </NavigationProvider>
        </AutomationProvider>
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;
