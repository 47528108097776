// src/utils/discountUtil.js

/**
 * Sorts products by availability and then alphabetically by name.
 * @param {Array} products - The list of products to sort.
 * @returns {Array} - The sorted list of products.
 */
export const sortProductsByAvailability = (products) => {
  return [...products].sort((a, b) => {
    // Determine if products are sold out
    const aIsSoldOut = a.is_sold_out || a.quantity === 0 || a.quantity === null;
    const bIsSoldOut = b.is_sold_out || b.quantity === 0 || b.quantity === null;

    // Primary sort: Availability (not sold out first)
    if (aIsSoldOut !== bIsSoldOut) {
      return Number(aIsSoldOut) - Number(bIsSoldOut);
    }

    // Secondary sort: Alphabetically by name
    return a.name.localeCompare(b.name);

    // Uncomment the following line if you prefer sorting by price instead:
    // return a.price - b.price;
  });
};
