// src/api/axios.js
import axios from 'axios';
import cookie from 'cookie';

// Create an axios instance with the base URL from the environment variable
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getCookie = (name) => {
  const cookies = cookie.parse(document.cookie);
  return cookies[name];
};

// Add a request interceptor to include tokens and handle multipart/form/data if needed
apiClient.interceptors.request.use(
  (config) => {
    const CompanyToken = getCookie('x-company');
    const UserToken = getCookie('token');

    if (CompanyToken) {
      config.headers['x-company'] = CompanyToken;
    }

    if (UserToken) {
      config.headers.Authorization = `Bearer ${UserToken}`;
    }

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  },
);

// Add a response interceptor to handle errors
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('API Error:', {
      status: error.response?.status,
      message: error.response?.data?.message || error.message
    });
    return Promise.reject(error);
  },
);

export default apiClient;
