import React, { useState, useEffect } from 'react';
import {
  Flex,
  Spinner,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  useToast,
  Box,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import CategoriesTable from './CategoriesTable';
import CategoryTree from './CategoryTree';
import { getCategories } from '../../api/category';
import {
  flattenCategoryTree,
  transformCategories,
} from '../../utils/CategoryUtils';

export default function CategoriesOverview() {
  const [categories, setCategories] = useState([]);
  const [categoryTree, setCategoryTree] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const categoriesData = await getCategories();
        const tree = transformCategories(categoriesData, 'en');
        setCategoryTree(tree);
        const flatList = flattenCategoryTree(tree);
        setCategories(flatList);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast({
          title: 'Error fetching categories',
          description: 'There was an error fetching categories data.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [toast]);

  const handleUpdateCategoryData = (updatedCategory) => {
    const updateTree = (nodes) =>
      nodes.map((node) => {
        if (node.uuid === updatedCategory.uuid) {
          return {
            ...node,
            ...updatedCategory,
            name: updatedCategory.translations.en.category_name || node.name,
          };
        }
        if (node.child_categories) {
          return {
            ...node,
            child_categories: updateTree(node.child_categories),
          };
        }
        return node;
      });

    const updatedTree = updateTree(categoryTree);
    setCategoryTree(updatedTree);
    const flatList = flattenCategoryTree(updatedTree);
    setCategories(flatList);
  };

  const handleSetCategoryTree = (updatedTree) => {
    setCategoryTree(updatedTree);
    const flatList = flattenCategoryTree(updatedTree);
    setCategories(flatList);
  };

  const handleMoveCategory = (newCategoryTree) => {
    setCategoryTree(newCategoryTree);
    const flatList = flattenCategoryTree(newCategoryTree);
    setCategories(flatList);
  };

  return (
    <Flex
      direction="column"
      mt={{ sm: '150px', md: '125px', lg: '75px' }}
      p={4}
    >
      <Tabs variant="enclosed" colorScheme="blue">
        <TabList>
          <Tab>Tree View</Tab>
          <Tab>Table View</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {loading ? (
              <Flex justify="center" align="center" h="200px">
                <Spinner size="lg" />
              </Flex>
            ) : (
              <CategoryTree
                initialData={categoryTree}
                onUpdateCategories={handleSetCategoryTree}
                onMoveCategory={handleMoveCategory}
              />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Flex justify="center" align="center" h="200px">
                <Spinner size="lg" />
              </Flex>
            ) : (
              <CategoriesTable
                categories={categories}
                setCategories={setCategories}
                onUpdateCategory={handleUpdateCategoryData}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
