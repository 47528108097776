export const transformCategories = (categories, defaultLang = 'en') => {
  return categories.map((category) => ({
    ...category,
    name:
      category.translations?.[defaultLang]?.category_name || 'Unnamed Category',
    child_categories: category.child_categories
      ? transformCategories(category.child_categories, defaultLang)
      : [],
  }));
};

export const flattenCategoryTree = (tree) => {
  const flat = [];

  const traverse = (nodes, parentId = null) => {
    nodes.forEach((node) => {
      flat.push({
        ...node,
        parent_id: parentId,
      });
      if (node.child_categories && node.child_categories.length > 0) {
        traverse(node.child_categories, node.id);
      }
    });
  };

  traverse(tree);
  return flat;
};

export const flattenCategories = (
  categories,
  language = 'en',
  depth = 0,
  parentName = '',
) => {
  let flatList = [];
  categories.forEach((category) => {
    const name =
      category.translations[language]?.category_name || 'Unnamed Category';
    const fullName = parentName ? `${parentName} > ${name}` : name;
    flatList.push({ ...category, displayName: fullName });
    if (category.child_categories && category.child_categories.length > 0) {
      flatList = flatList.concat(
        flattenCategories(
          category.child_categories,
          language,
          depth + 1,
          fullName,
        ),
      );
    }
  });
  return flatList;
};
export const transformCategoriesForTreeSelect = (
  categories,
  language = 'en',
) => {
  return categories.map((category) => ({
    label: category.translations[language]?.category_name || 'Unnamed Category',
    value: category.uuid, // Assuming 'uuid' is the unique identifier
    children: category.child_categories
      ? transformCategoriesForTreeSelect(category.child_categories, language)
      : [],
    // Optionally, you can add additional properties like 'disabled' or 'checked'
  }));
};

// Utility function to prune category tree
export const pruneCategoryTree = (categories, allowedCategoryUuids) => {
  const prune = (categoriesList) => {
    return categoriesList.reduce((acc, category) => {
      let children = [];
      if (category.children && category.children.length > 0) {
        children = prune(category.children);
      }
      if (allowedCategoryUuids.has(category.value) || children.length > 0) {
        acc.push({
          ...category,
          children,
        });
      }
      return acc;
    }, []);
  };

  return prune(categories);
};
