// src/pages/discounts/EditDiscount.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  Checkbox,
  VStack,
  Alert,
  AlertIcon,
  Box,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  CheckboxGroup,
  Stack,
  SimpleGrid,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getDiscount,
  updateProductDiscount,
  deleteProductDiscount,
} from '../../api/discount';
import useProducts from '../../hooks/useProducts';
import useLoyaltyPrograms from '../../hooks/useLoyaltyPrograms';
import useLoyaltyLevels from '../../hooks/useLoyaltyLevels';
import ProductCheckboxCard from './ProductCheckboxCard';
import { sortProductsByAvailability } from '../../utils/discountUtil'; // Import here

const EditDiscount = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [discountKind, setDiscountKind] = useState('fixed_price');
  const [discountScope, setDiscountScope] = useState('');
  const [isParent, setIsParent] = useState(false);
  const [formData, setFormData] = useState({
    product_uuid: '',
    product_uuids: [],
    discount_price: '',
    discount_percentage: '',
    loyalty_program_uuid: '',
    loyalty_level_uuid: '',
    setPeriod: false,
    start_time: '',
    end_time: '',
    is_active: true,
  });

  const {
    products,
    loading: productsLoading,
    error: productsError,
  } = useProducts();

  const {
    loyaltyPrograms,
    loading: loyaltyProgramsLoading,
    error: loyaltyProgramsError,
  } = useLoyaltyPrograms();
  const {
    loyaltyLevels,
    loading: loyaltyLevelsLoading,
    error: loyaltyLevelsError,
  } = useLoyaltyLevels();

  const [filteredLoyaltyLevels, setFilteredLoyaltyLevels] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    const fetchDiscount = async () => {
      try {
        const data = await getDiscount(uuid);
        const isEditingParent =
          data.parent_discount && data.parent_discount.uuid === uuid;

        let selectedProductUUIDs = [];

        if (isEditingParent) {
          if (data.child_discounts && data.child_discounts.length > 0) {
            selectedProductUUIDs = data.child_discounts
              .map((child) => child.product_uuid)
              .filter((uuid) => uuid);
          } else if (
            data.parent_discount.discount_type === 'selected_products' &&
            data.parent_discount.product_uuid
          ) {
            selectedProductUUIDs = [data.parent_discount.product_uuid];
          }
        } else {
          // Editing a child discount
          const childDiscount = data.child_discounts.find(
            (child) => child.uuid === uuid,
          );
          selectedProductUUIDs = childDiscount
            ? [childDiscount.product_uuid]
            : [];
        }

        setFormData({
          product_uuid: isEditingParent
            ? ''
            : data.parent_discount.product_uuid || '',
          product_uuids: selectedProductUUIDs,
          discount_price: data.parent_discount.discount_price || '',
          discount_percentage: data.parent_discount.discount_percentage || '',
          loyalty_program_uuid: data.parent_discount.loyalty_program_uuid || '',
          loyalty_level_uuid: data.parent_discount.loyalty_level_uuid || '',
          setPeriod:
            data.parent_discount.start_time || data.parent_discount.end_time
              ? true
              : false,
          start_time: data.parent_discount.start_time
            ? new Date(data.parent_discount.start_time)
                .toISOString()
                .slice(0, 16)
            : '',
          end_time: data.parent_discount.end_time
            ? new Date(data.parent_discount.end_time).toISOString().slice(0, 16)
            : '',
          is_active: data.parent_discount.is_active,
        });
        setIsParent(isEditingParent);
        setDiscountScope(data.parent_discount.discount_type);
        setDiscountKind(
          data.parent_discount.discount_price !== null
            ? 'fixed_price'
            : 'percentage',
        );
      } catch (err) {
        setError('Failed to fetch discount details.');
      } finally {
        setLoading(false);
      }
    };

    fetchDiscount();
  }, [uuid]);

  useEffect(() => {
    if (formData.loyalty_program_uuid) {
      const filtered = loyaltyLevels.filter(
        (level) => level.loyalty_program_uuid === formData.loyalty_program_uuid,
      );
      setFilteredLoyaltyLevels(filtered);

      const isValidLevel = filtered.some(
        (level) => level.uuid === formData.loyalty_level_uuid,
      );
      if (!isValidLevel) {
        if (!isInitialLoad) {
          setFormData((prev) => ({ ...prev, loyalty_level_uuid: '' }));
        }
      }
    } else {
      setFilteredLoyaltyLevels(loyaltyLevels);
      if (!isInitialLoad) {
        setFormData((prev) => ({ ...prev, loyalty_level_uuid: '' }));
      }
    }

    if (
      isInitialLoad &&
      !loading &&
      !loyaltyLevelsLoading &&
      !loyaltyProgramsLoading
    ) {
      setIsInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.loyalty_program_uuid,
    loyaltyLevels,
    isInitialLoad,
    loading,
    loyaltyLevelsLoading,
    loyaltyProgramsLoading,
    formData.loyalty_level_uuid,
  ]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleProductChange = (selectedValues) => {
    setFormData((prev) => ({
      ...prev,
      product_uuids: selectedValues,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Validate product_uuids for 'selected_products'
    if (discountScope === 'selected_products') {
      if (!formData.product_uuids || formData.product_uuids.length === 0) {
        setError(
          'At least one product must be selected for Selected Products scope.',
        );
        return;
      }
    }

    // Validate Loyalty Program Selection when 'all_products' is selected
    if (discountScope === 'all_products' && !formData.loyalty_program_uuid) {
      setError(
        'Please select a Loyalty Program when applying discount to All Products.',
      );
      return;
    }

    // Validate Period
    if (formData.setPeriod) {
      if (formData.start_time && formData.end_time) {
        if (new Date(formData.start_time) > new Date(formData.end_time)) {
          setError('Start Time must be before End Time.');
          return;
        }
      }
    }

    // Additional Validation: Ensure discountKind fields are filled
    if (discountKind === 'fixed_price' && !formData.discount_price) {
      setError('Discount Amount is required for Fixed Price Discount.');
      return;
    }

    if (discountKind === 'percentage' && !formData.discount_percentage) {
      setError('Discount Percentage is required for Percentage Discount.');
      return;
    }

    try {
      const updateData = {
        discount_type: discountScope,
        product_uuids:
          discountScope === 'selected_products' ? formData.product_uuids : [],
        discount_price:
          discountKind === 'fixed_price'
            ? parseFloat(formData.discount_price)
            : null,
        discount_percentage:
          discountKind === 'percentage'
            ? parseFloat(formData.discount_percentage)
            : null,
        start_time: formData.setPeriod
          ? new Date(formData.start_time).toISOString()
          : null,
        end_time: formData.setPeriod
          ? new Date(formData.end_time).toISOString()
          : null,
        is_active: formData.is_active,
        loyalty_program_uuid: formData.loyalty_program_uuid || null,
        loyalty_level_uuid: formData.loyalty_level_uuid || null,
      };

      await updateProductDiscount(uuid, updateData);
      setSuccess('Discount updated successfully!');
      navigate('/admin/discounts');
    } catch (err) {
      console.error('Error updating discount:', err);
      setError(err.message || 'Failed to update discount.');
    }
  };

  const handleProductToggle = (productUuid) => {
    setFormData((prev) => {
      const newProductUuids = prev.product_uuids.includes(productUuid)
        ? prev.product_uuids.filter((uuid) => uuid !== productUuid)
        : [...prev.product_uuids, productUuid];
      return {
        ...prev,
        product_uuids: newProductUuids,
      };
    });
  };

  const handleDelete = async () => {
    try {
      await deleteProductDiscount(uuid);
      setSuccess('Discount deleted successfully!');
      navigate('/admin/discounts');
    } catch (err) {
      console.error('Error deleting discount:', err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(`Failed to delete discount: ${err.response.data.error}`);
      } else {
        setError(err.message || 'Failed to delete discount.');
      }
    } finally {
      onClose();
    }
  };

  if (
    loading ||
    productsLoading ||
    loyaltyProgramsLoading ||
    loyaltyLevelsLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  // Enhanced Filtering: Exclude loyalty products
  let filteredProducts = products.filter(
    (product) =>
      product.product_type === 'regular' &&
      !product.loyalty_program_uuid && // Exclude if associated with a loyalty program
      !product.loyalty_level_uuid, // Exclude if associated with a loyalty level
  );

  const sortedFilteredProducts = sortProductsByAvailability(filteredProducts);

  // ** New: Filtered Published Loyalty Programs **
  const publishedPrograms = loyaltyPrograms.filter(
    (program) => program.is_published === 1,
  );

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <form onSubmit={handleSubmit} noValidate>
        <VStack spacing={4} align="stretch">
          {/* Discount Kind Selection */}
          <FormControl id="discountKind" isRequired>
            <FormLabel>Discount Kind</FormLabel>
            <Select
              value={discountKind}
              onChange={(e) => {
                setDiscountKind(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  discount_price: '',
                  discount_percentage: '',
                }));
              }}
            >
              <option value="fixed_price">Discount Amount</option>
              <option value="percentage">Percentage</option>
            </Select>
          </FormControl>

          {/* Discount Price */}
          {discountKind === 'fixed_price' && (
            <FormControl id="discount_price" isRequired>
              <FormLabel>Discount Amount</FormLabel>
              <Input
                type="number"
                name="discount_price"
                value={formData.discount_price}
                onChange={handleChange}
                placeholder="Enter Discount Amount"
                min="0"
                step="0.01"
              />
            </FormControl>
          )}

          {/* Discount Percentage */}
          {discountKind === 'percentage' && (
            <FormControl id="discount_percentage" isRequired>
              <FormLabel>Discount Percentage</FormLabel>
              <Input
                type="number"
                name="discount_percentage"
                value={formData.discount_percentage}
                onChange={handleChange}
                placeholder="Enter Discount Percentage"
                min="0"
                max="100"
                step="0.1"
              />
            </FormControl>
          )}

          {/* Discount Scope Selection */}
          <FormControl id="discountScope" isRequired>
            <FormLabel>Discount Scope</FormLabel>
            <Select
              value={discountScope}
              onChange={(e) => {
                setDiscountScope(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  product_uuid: '',
                  product_uuids: [],
                }));
              }}
              placeholder="Select Discount Scope"
            >
              <option value="selected_products">Selected Products</option>
              <option value="all_products">All Products</option>
            </Select>
          </FormControl>

          {/* Selected Products Selection */}
          {discountScope === 'selected_products' && sortedFilteredProducts && (
            <FormControl id="product_uuids" isRequired>
              <FormLabel>Select Products</FormLabel>
              {productsLoading ? (
                <Spinner size="sm" />
              ) : productsError ? (
                <Alert status="error">
                  <AlertIcon />
                  {productsError}
                </Alert>
              ) : (
                <Box>
                  {sortedFilteredProducts.length === 0 ? (
                    <Alert status="warning">
                      <AlertIcon />
                      No regular products available for selection.
                    </Alert>
                  ) : (
                    <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                      {sortedFilteredProducts.map((product) => (
                        <ProductCheckboxCard
                          key={product.uuid}
                          product={product}
                          isChecked={formData.product_uuids.includes(
                            product.uuid,
                          )}
                          onChange={handleProductToggle}
                          discountKind={discountKind}
                          discountPrice={formData.discount_price}
                          discountPercentage={formData.discount_percentage}
                        />
                      ))}
                    </SimpleGrid>
                  )}
                </Box>
              )}
            </FormControl>
          )}

          {/* Loyalty Program Selection */}
          {(discountScope === 'all_products' ||
            discountScope === 'selected_products') && (
            <>
              <FormControl id="loyalty_program_uuid">
                <FormLabel>Loyalty Program</FormLabel>
                {loyaltyProgramsLoading ? (
                  <Spinner size="sm" />
                ) : loyaltyProgramsError ? (
                  <Alert status="error">
                    <AlertIcon />
                    {loyaltyProgramsError}
                  </Alert>
                ) : publishedPrograms.length > 0 ? (
                  <Select
                    name="loyalty_program_uuid"
                    value={formData.loyalty_program_uuid}
                    onChange={handleChange}
                    placeholder="Select Loyalty Program"
                  >
                    {publishedPrograms.map((program) => (
                      <option key={program.uuid} value={program.uuid}>
                        {program.name}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Alert status="warning">
                    <AlertIcon />
                    No published loyalty programs available.
                  </Alert>
                )}
              </FormControl>

              {/* Loyalty Level Selection (Optional) */}
              {formData.loyalty_program_uuid && (
                <FormControl id="loyalty_level_uuid">
                  <FormLabel>Loyalty Level (Optional)</FormLabel>
                  {loyaltyLevelsLoading ? (
                    <Spinner size="sm" />
                  ) : loyaltyLevelsError ? (
                    <Alert status="error">
                      <AlertIcon />
                      {loyaltyLevelsError}
                    </Alert>
                  ) : filteredLoyaltyLevels.length > 0 ? (
                    <Select
                      name="loyalty_level_uuid"
                      value={formData.loyalty_level_uuid}
                      onChange={handleChange}
                      placeholder="Select Loyalty Level (Optional)"
                    >
                      {filteredLoyaltyLevels.map((level) => (
                        <option key={level.uuid} value={level.uuid}>
                          {level.name}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Alert status="info">
                      <AlertIcon />
                      No loyalty levels available for the selected program.
                    </Alert>
                  )}
                </FormControl>
              )}
            </>
          )}

          {/* Set Period Checkbox */}
          <FormControl id="setPeriod">
            <Checkbox
              name="setPeriod"
              isChecked={formData.setPeriod}
              onChange={(e) => {
                const isChecked = e.target.checked;
                setFormData((prev) => ({
                  ...prev,
                  setPeriod: isChecked,
                  start_time: isChecked ? prev.start_time : '',
                  end_time: isChecked ? prev.end_time : '',
                }));
              }}
            >
              Period (optional, if not set then always on)
            </Checkbox>
          </FormControl>

          {/* Start Time */}
          {formData.setPeriod && (
            <FormControl id="start_time">
              <FormLabel>Start Date (starting from)</FormLabel>
              <Input
                type="datetime-local"
                name="start_time"
                value={formData.start_time}
                onChange={handleChange}
              />
            </FormControl>
          )}

          {/* End Time */}
          {formData.setPeriod && (
            <FormControl id="end_time">
              <FormLabel>End Date (up to)</FormLabel>
              <Input
                type="datetime-local"
                name="end_time"
                value={formData.end_time}
                onChange={handleChange}
              />
            </FormControl>
          )}

          {/* is_active Checkbox */}
          <FormControl id="is_active">
            <Checkbox
              name="is_active"
              isChecked={formData.is_active}
              onChange={handleChange}
            >
              Active
            </Checkbox>
          </FormControl>

          {/* Error Alert */}
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          {/* Success Alert */}
          {success && (
            <Alert status="success">
              <AlertIcon />
              {success}
            </Alert>
          )}

          {/* Submit and Delete Buttons */}
          <Button type="submit" colorScheme="blue">
            Update Discount
          </Button>
          <Button colorScheme="red" onClick={onOpen}>
            Delete Discount
          </Button>
        </VStack>
      </form>

      {/* Confirmation Dialog for Deletion */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Discount
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this discount? This action cannot
              be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDelete}
                ml={3}
                isLoading={loading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default EditDiscount;
