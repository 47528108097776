import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Card,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  useToast,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategory, updateCategory } from '../../api/category';
import CategoryTree from './CategoryTree';

export default function CategoryDetail() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [parentId, setParentId] = useState(null);
  const [parentName, setParentName] = useState('');
  const [childCategories, setChildCategories] = useState([]);
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);

  const getLanguageName = (code) => {
    const languageMap = {
      en: 'English',
      et: 'Estonian',
    };
    return languageMap[code] || code.toUpperCase();
  };

  const fetchCategoryData = async () => {
    try {
      const data = await getCategory(uuid);
      setCurrentCategory(data);
      setTranslations(data.translations || {});

      setParentId(data.parent_id);
      setParentName(data.parent_name || 'None');
      setChildCategories(data.child_categories || []);
      setLanguages(data.languages || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching category data or translations:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch category data and translations.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, [uuid]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const lang of languages) {
      if (
        !translations[lang.code] ||
        !translations[lang.code].category_name ||
        !translations[lang.code].category_name.trim()
      ) {
        toast({
          title: 'Validation Error',
          description: `Category name is required in ${getLanguageName(lang.code)}.`,
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }

    setIsSubmitting(true);

    const processedTranslations = { ...translations };
    if (
      !processedTranslations.et ||
      !processedTranslations.et.category_name.trim()
    ) {
      delete processedTranslations.et;
    }

    const dataToUpdate = {
      ...(parentId !== null && { parent_id: parentId }), // Use integer ID here
      translations: processedTranslations,
    };

    console.log('PUT /categories/:uuid payload:', dataToUpdate);

    try {
      await updateCategory(uuid, dataToUpdate);
      toast({
        title: 'Success',
        description: 'Category updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      await fetchCategoryData();
    } catch (error) {
      console.error('Error updating category:', error);
      toast({
        title: 'Error',
        description:
          error.error || error.message || 'Failed to update category.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTranslationChange = (lang, field, value) => {
    setTranslations((prev) => ({
      ...prev,
      [lang]: {
        ...prev[lang],
        [field]: value,
      },
    }));
  };

  const transformCategoriesForTree = (categories, defaultLang) => {
    return categories.map((category) => ({
      id: category.id, // Ensure integer ID is included
      uuid: category.uuid,
      name:
        category.translations[defaultLang]?.category_name || 'Unnamed Category',
      translations: category.translations,
      child_categories: category.child_categories
        ? transformCategoriesForTree(category.child_categories, defaultLang)
        : [],
    }));
  };

  const handleTreeUpdate = async (updatedCategories) => {
    setChildCategories(updatedCategories);

    try {
      const updatePromises = [];

      const flattenCategories = (categories, parentId = null) => {
        let flat = [];
        categories.forEach((cat) => {
          let processedTranslations = { ...cat.translations };
          if (
            !processedTranslations.et ||
            !processedTranslations.et.category_name.trim()
          ) {
            delete processedTranslations.et;
          }

          flat.push({
            uuid: cat.uuid,
            parent_id: parentId,
            translations: processedTranslations,
          });

          if (cat.children && cat.children.length > 0) {
            flat = flat.concat(flattenCategories(cat.children, cat.id)); // Pass integer ID here
          }
        });
        return flat;
      };

      const flatCategories = flattenCategories(updatedCategories);

      console.log('Flattened Categories Payload:', flatCategories);

      const supportedLanguages = ['en', 'et'];
      flatCategories.forEach((cat) => {
        if (cat.parent_id !== null && typeof cat.parent_id !== 'number') {
          throw new Error(
            `Invalid parent_id for category UUID: ${cat.uuid}. Expected an integer or null.`,
          );
        }

        for (const lang of supportedLanguages) {
          if (
            !cat.translations['en'] ||
            !cat.translations['en'].category_name ||
            !cat.translations['en'].category_name.trim()
          ) {
            throw new Error(
              `Category name is required in English for category UUID: ${cat.uuid}.`,
            );
          }
        }

        console.log(
          `Updating Category UUID: ${cat.uuid} with Parent ID: ${cat.parent_id}`,
        );

        updatePromises.push(
          updateCategory(cat.uuid, {
            parent_id: cat.parent_id, // Use integer ID here
            translations: cat.translations,
          }),
        );
      });

      await Promise.all(updatePromises);

      toast({
        title: 'Success',
        description: 'Child categories updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating child categories:', error);

      let errorMessage = 'Failed to update child categories.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }

      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" h="400px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const treeData = currentCategory
    ? transformCategoriesForTree([currentCategory], languages[0]?.code || 'en')
    : [];

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      px={{ base: '16px', md: '24px' }}
    >
      {/* Category Info Form with Language Tabs */}
      <Card p="30px" mb="20px">
        <form onSubmit={handleSubmit}>
          <Flex direction="column">
            {/* Conditionally Render Parent Category */}
            {parentId !== null && (
              <FormControl mb="20px">
                <FormLabel>Parent Category</FormLabel>
                <Input value={parentName} isReadOnly />
              </FormControl>
            )}

            {/* Language Tabs */}
            <Tabs variant="enclosed" colorScheme="blue">
              <TabList>
                {languages.map((lang) => (
                  <Tab key={lang.code}>{lang.name}</Tab>
                ))}
              </TabList>

              <TabPanels>
                {languages.map((lang) => (
                  <TabPanel key={lang.code}>
                    <SimpleGrid columns={1} spacing="20px">
                      {/* Category Name */}
                      <FormControl
                        isRequired={lang.code === languages[0]?.code}
                      >
                        <FormLabel>Category Name ({lang.name})</FormLabel>
                        <Input
                          placeholder={`Enter category name in ${lang.name}`}
                          value={translations[lang.code]?.category_name || ''}
                          onChange={(e) =>
                            handleTranslationChange(
                              lang.code,
                              'category_name',
                              e.target.value,
                            )
                          }
                        />
                      </FormControl>

                      {/* Category Description */}
                      <FormControl>
                        <FormLabel>Description ({lang.name})</FormLabel>
                        <Textarea
                          placeholder={`Enter category description in ${lang.name}`}
                          value={
                            translations[lang.code]?.category_description || ''
                          }
                          onChange={(e) =>
                            handleTranslationChange(
                              lang.code,
                              'category_description',
                              e.target.value,
                            )
                          }
                        />
                      </FormControl>
                    </SimpleGrid>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>

            {/* Submit Button */}
            <Flex mt="20px" justify="flex-end">
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Update Category
              </Button>
            </Flex>
          </Flex>
        </form>
      </Card>

      {/* Render child categories using CategoryTree */}
      <Card p="30px" mb="20px">
        <Text fontSize="xl" mb="20px">
          Categories
        </Text>
        <CategoryTree
          initialData={treeData}
          onUpdateCategories={handleTreeUpdate}
          // Removed translationsMap and related props
        />
      </Card>
    </Box>
  );
}
