import React, { useState, useEffect } from 'react';
import {
  Button,
  Flex,
  Image,
  Text,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { getLatestLoyaltyPrograms } from 'api/dashboard';
import { BsArrowRight } from 'react-icons/bs';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

export default function ViewAllLoyaltyPrograms(props) {
  const { ...rest } = props;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');

  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    const fetchPrograms = async () => {
      setIsLoading(true);
      try {
        const data = await getLatestLoyaltyPrograms({ limit });
        setPrograms(data);
      } catch (error) {
        console.error('Error fetching all loyalty programs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrograms();
  }, [limit]);

  const loadMore = () => {
    setLimit((prevLimit) => prevLimit + 20);
  };

  return (
    <Card
      justifyContent="center"
      direction="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      pb="20px"
      {...rest}
    >
      <Flex justify="space-between" align="center" mb="26px" pt="20px">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          All Loyalty Programs
        </Text>
        <Link to="/">
          <Button
            variant="ghost"
            leftIcon={<BsArrowRight />}
            color={brandColor}
            fontWeight="bold"
          >
            Back
          </Button>
        </Link>
      </Flex>
      {isLoading ? (
        <Flex justify="center" align="center" minH="150px">
          <Spinner />
        </Flex>
      ) : (
        <>
          {programs.map((program, index) => (
            <Flex
              key={`${program.id}-${index}`}
              alignItems="center"
              w="100%"
              mb="15px"
            >
              <Image
                src={`https://api.perxify.com/${program.image}` || ''}
                alt={program.name}
                boxSize="40px"
                borderRadius="full"
                mr="10px"
              />
              <Flex direction="column" align="start" me="auto">
                <Text color={textColor} fontSize="md" fontWeight="700">
                  {program.name}
                </Text>

                <Text fontSize="xs" color="secondaryGray.500">
                  Sold on{' '}
                  {format(parseISO(program.purchase_date), 'dd MMM yyyy')}
                </Text>
              </Flex>

              <Text ms="auto" color={textColor} fontSize="sm" fontWeight="700">
                €{program.price.toFixed(2)}
              </Text>
            </Flex>
          ))}
          {}
          <Flex justify="center" mt="20px">
            <Button onClick={loadMore} colorScheme="brand">
              Load More
            </Button>
          </Flex>
        </>
      )}
    </Card>
  );
}
