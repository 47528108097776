import { createColumnHelper } from '@tanstack/react-table';
import { Text, Badge, Flex, Tooltip, Box } from '@chakra-ui/react';
import { MdEdit, MdContentCopy } from 'react-icons/md';
import useBrandColor from '../../hooks/useBrandColor';
import { MdExpandMore, MdChevronRight } from 'react-icons/md';

const columnHelper = createColumnHelper();

const useDiscountColumns = (
  textColor,
  navigate,
  handleDuplicate,
  loyaltyPrograms,
  loyaltyLevels,
) => {
  const brandColor = useBrandColor();

  return [
    columnHelper.display({
      id: 'expander',
      header: () => null,
      cell: ({ row }) => {
        const { discount_type } = row.original;

        // Prevent expansion if discount_type is 'all_products'
        if (discount_type === 'all_products') {
          return null;
        }

        const canExpand = row.getCanExpand();
        return canExpand ? (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Text
              as="span"
              cursor="pointer"
              onClick={row.getToggleExpandedHandler()}
              fontSize="24px"
            >
              {row.getIsExpanded() ? <MdExpandMore /> : <MdChevronRight />}
            </Text>
          </Box>
        ) : null;
      },
      enableSorting: false,
      enableHiding: false,
    }),

    // Product Name Column
    columnHelper.accessor('product_name', {
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Product Name
        </Text>
      ),
      cell: (info) => {
        const { discount_type } = info.row.original;
        let productName = 'Multiple Products';

        if (discount_type === 'all_products') {
          productName = 'All Products';
        } else if (info.getValue()) {
          productName = info.getValue();
        }

        return (
          <Text
            fontWeight={
              info.row.original.parent_id === null ||
              info.row.original.parent_id === 0
                ? 'bold'
                : 'normal'
            }
          >
            {productName}
          </Text>
        );
      },
    }),

    // Discount Price Column
    columnHelper.display({
      id: 'discount',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Discount
        </Text>
      ),
      cell: (info) => {
        const { discount_percentage, discount_price } = info.row.original;

        let discountText = '';
        if (discount_percentage !== null && discount_price !== null) {
          discountText = `${discount_percentage}% / €${discount_price}`;
        } else if (discount_percentage !== null) {
          discountText = `${discount_percentage}%`;
        } else if (discount_price !== null) {
          discountText = `€${discount_price}`;
        } else {
          discountText = '-';
        }

        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {discountText}
          </Text>
        );
      },
    }),
    // To Whom Column
    columnHelper.accessor('discount_type', {
      id: 'to_whom',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          To Whom
        </Text>
      ),
      cell: (info) => {
        const { loyalty_program_uuid, loyalty_level_uuid } = info.row.original;

        let displayText = '';
        if (!loyalty_program_uuid && !loyalty_level_uuid) {
          displayText = 'Everyone';
        } else if (loyalty_program_uuid && !loyalty_level_uuid) {
          const loyaltyProgram = loyaltyPrograms?.find(
            (lp) => lp.uuid === loyalty_program_uuid,
          );
          const loyaltyProgramName = loyaltyProgram
            ? loyaltyProgram.name
            : 'this loyalty program';

          displayText = `Users of ${loyaltyProgramName}`;
        } else if (loyalty_level_uuid) {
          const loyaltyLevel = loyaltyLevels?.find(
            (ll) => ll.uuid === loyalty_level_uuid,
          );
          const loyaltyLevelName = loyaltyLevel
            ? loyaltyLevel.name
            : 'this loyalty level';

          displayText = `Users of ${loyaltyLevelName}`;
        }

        return <Text>{displayText}</Text>;
      },
    }),
    // Period Column
    columnHelper.accessor('start_time', {
      id: 'period',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Period
        </Text>
      ),
      cell: (info) => {
        const startTime = info.getValue();
        const endTime = info.row.original.end_time;

        const start = startTime
          ? new Date(startTime).toLocaleDateString()
          : null;
        const end = endTime ? new Date(endTime).toLocaleDateString() : null;

        if (!start && !end) {
          return <Text>Always</Text>;
        } else if (start && !end) {
          return <Text>{start} - Always</Text>;
        } else if (!start && end) {
          return <Text>Now - {end}</Text>;
        } else {
          return (
            <Text>
              {start} - {end}
            </Text>
          );
        }
      },
    }),
    // Which Products Column
    columnHelper.accessor('discount_type', {
      id: 'which_products',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Which Products
        </Text>
      ),
      cell: (info) => {
        const { discount_type, children, product_name } = info.row.original;

        if (discount_type === 'all_products') {
          return <Text>All Products</Text>;
        }

        let productNames = [];

        if (info.row.getIsExpanded() && info.row.subRows.length > 0) {
          // If the row is expanded, show all child product names
          productNames = info.row.subRows.map(
            (subRow) => subRow.original.product_name,
          );
        } else if (children && children.length > 0) {
          // If the row has children but is not expanded, aggregate their product names
          productNames = children.map((child) => child.product_name);
        } else if (product_name) {
          // If there's a product_name directly on the row
          productNames = [product_name];
        }

        let displayText = '';
        if (productNames.length > 0) {
          displayText = productNames[0];
          if (productNames.length > 1) {
            displayText += `, +${productNames.length - 1} more`;
          }
        } else {
          displayText = 'No Products';
        }

        return (
          <Tooltip label={productNames.join(', ')}>
            <Text>{displayText}</Text>
          </Tooltip>
        );
      },
    }),

    // Active Status Column
    columnHelper.accessor('is_active', {
      id: 'active_status',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Active
        </Text>
      ),
      cell: (info) => (
        <Badge colorScheme={info.getValue() ? 'green' : 'red'}>
          {info.getValue() ? 'Active' : 'Inactive'}
        </Badge>
      ),
    }),
    // Actions Column

    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Actions
        </Text>
      ),
      cell: (info) => {
        const isParent = !info.row.original.parent_id;

        // If it's not a parent discount, return null or an empty fragment
        if (!isParent) {
          return null; // Or you can return an empty <Flex />
        }

        return (
          <Flex>
            <Tooltip label="Edit" shouldWrapChildren>
              <Text
                w="16px"
                h="16px"
                as={MdEdit}
                cursor="pointer"
                color={brandColor}
                mr="8px"
                onClick={() =>
                  navigate(`/admin/discounts/edit/${info.row.original.uuid}`)
                }
              />
            </Tooltip>
            <Tooltip label="Duplicate" shouldWrapChildren>
              <Text
                w="16px"
                h="16px"
                as={MdContentCopy}
                cursor="pointer"
                color={brandColor}
                mr="8px"
                onClick={() => handleDuplicate(info.row.original.uuid)}
              />
            </Tooltip>
          </Flex>
        );
      },
    }),
  ];
};

export default useDiscountColumns;
