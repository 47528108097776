import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  HStack,
  useToast,
  Tooltip,
  Box,
  SimpleGrid,
  Badge,
  Heading,
  Collapse,
  IconButton,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { MdEdit } from 'react-icons/md';
import { RiArchiveFill, RiInboxUnarchiveFill } from 'react-icons/ri';
import {
  MdChevronRight,
  MdChevronLeft,
  MdExpandMore,
  MdExpandLess,
} from 'react-icons/md';
import {
  MdTrendingDown,
  MdAccessTime,
  MdTrackChanges,
  MdInsights,
  MdLightbulb,
  MdArrowOutward,
} from 'react-icons/md';

import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  createLoyaltyProgram,
  updateLoyaltyProgram,
  getLoyaltyPrograms,
} from 'api/loyalty';
import DebouncedInput from 'components/DebouncedInput';
import { LoyaltyArchiveAlert } from 'components/loyalty/LoyaltyAlerts';
import { createPages } from 'utils/helpers';

const LoyaltySmartInsights = () => {
  const [isOpen, setIsOpen] = useState(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const cardBg = useColorModeValue('white', 'navy.700');
  const borderColors = {
    critical: 'red.500',
    warning: 'orange.500',
    opportunity: 'green.500',
    insight: 'blue.500',
  };

  const insights = [
    {
      type: 'critical',
      icon: MdTrendingDown,
      color: 'red.500',
      title: 'Engagement Decline in Gold Program',
      description: '20% drop in member activity over last 30 days',
      action: 'Review Program',
      program: 'Gold Rewards',
      metrics: {
        previous: '85% engagement',
        current: '65% engagement',
      },
    },
    {
      type: 'warning',
      icon: MdAccessTime,
      color: 'orange.500',
      title: 'Program Maturity Alert',
      description: 'Silver Program approaching optimal refresh window',
      metrics: ['11 months age', '12-15 months optimal'],
      action: 'Plan Refresh',
      program: 'Silver Rewards',
    },
    {
      type: 'opportunity',
      icon: MdTrackChanges,
      color: 'green.500',
      title: 'New Tier Opportunity',
      description: '125 Gold members near new tier qualification',
      metrics: ['125 members', '€15,000 potential'],
      action: 'Create new level',
      program: 'Gold to Platinum',
    },
    {
      type: 'insight',
      icon: MdInsights,
      color: 'blue.500',
      title: 'Reward Redemption Pattern',
      description: '60% of unredeemed points held by inactive members',
      metrics: ['€25,000 unredeemed', '€15,000 at risk'],
      action: 'View Analysis',
      program: 'All Programs',
    },
  ];

  return (
    <Box mb={6}>
      <Flex
        align="center"
        mb={4}
        cursor="pointer"
        onClick={() => setIsOpen(!isOpen)}
        _hover={{ opacity: 0.8 }}
      >
        <Icon as={MdLightbulb} w="24px" h="24px" color="yellow.500" mr={2} />
        <Heading size="md" color={textColor}>
          Loyalty Smart Insights
        </Heading>
        <Badge ml={2} colorScheme="blue" variant="solid" borderRadius="full">
          4 New
        </Badge>
        <IconButton
          icon={isOpen ? <MdExpandLess /> : <MdExpandMore />}
          variant="ghost"
          size="sm"
          ml="auto"
          aria-label={isOpen ? 'Collapse insights' : 'Expand insights'}
        />
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          {insights.map((insight, index) => (
            <Box
              key={index}
              bg={cardBg}
              p={4}
              borderRadius="lg"
              borderLeft="4px solid"
              borderLeftColor={insight.color}
              boxShadow="sm"
            >
              <Flex justify="space-between" mb={2}>
                <Flex align="center">
                  <Icon
                    as={insight.icon}
                    w="20px"
                    h="20px"
                    color={insight.color}
                    mr={2}
                  />
                  <Text fontWeight="bold" color={textColor}>
                    {insight.title}
                  </Text>
                </Flex>
                <Badge
                  colorScheme={insight.type === 'critical' ? 'red' : 'blue'}
                >
                  {insight.program}
                </Badge>
              </Flex>

              <Text color={textColor} mb={3} fontSize="sm">
                {insight.description}
              </Text>

              <Flex justify="space-between" align="center">
                <HStack spacing={2}>
                  {Array.isArray(insight.metrics) ? (
                    insight.metrics.map((metric, i) => (
                      <Badge key={i} variant="subtle">
                        {metric}
                      </Badge>
                    ))
                  ) : (
                    <>
                      <Badge variant="subtle">{insight.metrics.previous}</Badge>
                      <Badge variant="subtle">{insight.metrics.current}</Badge>
                    </>
                  )}
                </HStack>

                <Button
                  size="sm"
                  variant="ghost"
                  rightIcon={<MdArrowOutward />}
                  color={insight.color}
                  _hover={{ bg: `${insight.color}50` }}
                >
                  {insight.action}
                </Button>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </Collapse>
    </Box>
  );
};

export default function LoyaltyOverview() {
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(null);

  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const navigate = useNavigate();
  const cancelRef = React.useRef();
  const toast = useToast();

  const handleArchive = async () => {
    try {
      const selectedProgram = loyaltyPrograms.find(
        (program) => program.uuid === selectedProgramId,
      );
      const newStatus = !selectedProgram.is_archived;

      await updateLoyaltyProgram(selectedProgramId, {
        is_archived: newStatus,
      });

      toast({
        title: `Loyalty program ${newStatus ? 'archived' : 'unarchived'}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      const updatedPrograms = loyaltyPrograms.map((program) =>
        program.uuid === selectedProgramId
          ? { ...program, is_archived: newStatus }
          : program,
      );
      setLoyaltyPrograms(updatedPrograms);
    } catch (error) {
      console.error('Error:', error);
      const selectedProgram = loyaltyPrograms.find(
        (program) => program.uuid === selectedProgramId,
      );
      const newStatus = !selectedProgram.is_archived;
      toast({
        title: `Error ${newStatus ? 'archiving' : 'unarchiving'} loyalty program`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsArchiveDialogOpen(false);
    }
  };

  useEffect(() => {
    const fetchLoyaltyPrograms = async () => {
      try {
        const programs = await getLoyaltyPrograms();
        setLoyaltyPrograms(programs);
      } catch (error) {
        console.error('Error fetching loyalty programs:', error);
      }
    };

    fetchLoyaltyPrograms();
  }, []);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Name
        </Text>
      ),
      cell: (info) => {
        const translations = info.row.original.translations;
        console.log('Translations:', translations);

        const enName = translations?.find(
          (t) => t.language === 'en' && t.key === 'name',
        )?.value;
        console.log('enName:', enName);

        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {enName || 'Name not available'}
          </Text>
        );
      },
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Description
        </Text>
      ),
      cell: (info) => {
        const translations = info.row.original.translations;

        const enDesc = translations?.find(
          (t) => t.language === 'en' && t.key === 'description',
        )?.value;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {enDesc}
          </Text>
        );
      },
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Date Created
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor('is_published', {
      id: 'is_published',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Published
        </Text>
      ),
      cell: (info) => {
        const isPublished = info.row.original.is_published;

        const status = ['Draft', 'Published', 'Scheduled'][isPublished];

        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {status}
          </Text>
        );
      },
    }),

    columnHelper.accessor('is_archived', {
      id: 'is_archived',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        const isArchived = info.row.original.is_archived;
        const isActive = info.row.original.is_active;
        let status = 'Active';
        if (isArchived) {
          status = 'Archived';
        } else if (!isActive) {
          status = 'Inactive';
        }
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {status}
          </Text>
        );
      },
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          ACTIONS
        </Text>
      ),
      cell: (info) => {
        const isPublished = info.row.original.is_published;
        return (
          <Flex align="center">
            <HStack spacing="4">
              <Icon
                w="16px"
                h="16px"
                as={MdEdit}
                cursor="pointer"
                color={brandColor}
                onClick={() => {
                  const programId = info.row.original.uuid;
                  if (programId) {
                    navigate(`/admin/loyalty-programs/${programId}`);
                  } else {
                    console.error('programId is undefined');
                  }
                }}
              />

              {isPublished === 1 && (
                <Icon
                  w="16px"
                  h="16px"
                  as={
                    info.row.original.is_archived
                      ? RiInboxUnarchiveFill
                      : RiArchiveFill
                  }
                  cursor="pointer"
                  color={info.row.original.is_archived ? brandColor : 'red.500'}
                  onClick={() => {
                    const programId = info.row.original.uuid;
                    if (programId) {
                      setSelectedProgramId(programId);
                      setIsArchiveDialogOpen(true);
                    } else {
                      console.error('programId is undefined');
                    }
                  }}
                />
              )}
            </HStack>
          </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: loyaltyPrograms,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const createNewLoyaltyProgram = async () => {
    const templateData = {
      is_active: false,
      is_published: 0,
      is_archived: false,
    };

    try {
      const response = await createLoyaltyProgram(templateData);
      if (response?.uuid) {
        navigate(`/admin/loyalty-programs/${response.uuid}`);
      }
    } catch (error) {
      console.error('Error creating loyalty program:', error);
      toast({
        title: 'Error creating loyalty program',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column">
        <LoyaltySmartInsights />

        <Flex direction="column" gap={4}>
          <Card p="16px">
            <Flex
              align={{ sm: 'center', lg: 'center' }}
              justify={{ sm: 'center', lg: 'center' }}
              mb="36px"
              mt="24px"
            >
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter(String(value))}
                className="p-2 font-lg shadow border border-block"
              />
              <Button
                ml="auto"
                size="md"
                colorScheme="blue"
                onClick={createNewLoyaltyProgram}
                leftIcon={<AddIcon />}
              >
                New Loyalty
              </Button>
            </Flex>
            <Table variant="simple" color="gray.500">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        borderColor={borderColor}
                        key={header.id}
                        colSpan={header.colSpan}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {header.isPlaceholder ? null : (
                          <Flex
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                            justify="space-between"
                            align="center"
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color="gray.400"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted()] ?? null}
                          </Flex>
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr px="20px" key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Tooltip
                        label={cell.getValue()}
                        aria-label="Full text"
                        borderRadius="md"
                        key={cell.id}
                        bg="gray.600"
                      >
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          maxW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor={borderColor}
                          align="center"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      </Tooltip>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Flex
              w="100%"
              justify="space-between"
              align="center"
              px="20px"
              py="24px"
            >
              <Text
                fontSize="sm"
                color="gray.500"
                fontWeight="normal"
                mb={{ sm: '24px', md: '0px' }}
              >
                Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
                {pagination.pageSize * (pagination.pageIndex + 1) <=
                loyaltyPrograms.length
                  ? pagination.pageSize * (pagination.pageIndex + 1)
                  : loyaltyPrograms.length}{' '}
                of {loyaltyPrograms.length} entries
              </Text>
              <div className="flex items-center gap-2">
                <Stack direction="row" spacing="4px" ms="auto">
                  <Button
                    variant="no-effects"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                    transition="all .5s ease"
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg="transparent"
                    border="1px solid"
                    borderColor={useColorModeValue('gray.200', 'white')}
                    display={
                      pagination.pageSize === 5
                        ? 'none'
                        : table.getCanPreviousPage()
                          ? 'flex'
                          : 'none'
                    }
                    _hover={{
                      bg: 'whiteAlpha.100',
                      opacity: '0.7',
                    }}
                  >
                    <Icon
                      as={MdChevronLeft}
                      w="16px"
                      h="16px"
                      color={textColor}
                    />
                  </Button>
                  {createPages(table.getPageCount()).map(
                    (pageNumber, index) => (
                      <Button
                        variant="no-effects"
                        transition="all .5s ease"
                        onClick={() => table.setPageIndex(pageNumber - 1)}
                        w="40px"
                        h="40px"
                        borderRadius="50%"
                        bg={
                          pageNumber === pagination.pageIndex + 1
                            ? brandColor
                            : 'transparent'
                        }
                        border={
                          pageNumber === pagination.pageIndex + 1
                            ? 'none'
                            : '1px solid lightgray'
                        }
                        _hover={
                          pageNumber === pagination.pageIndex + 1
                            ? {
                                opacity: '0.7',
                              }
                            : {
                                bg: 'whiteAlpha.100',
                              }
                        }
                        key={index}
                      >
                        <Text
                          fontSize="sm"
                          color={
                            pageNumber === pagination.pageIndex + 1
                              ? '#fff'
                              : textColor
                          }
                        >
                          {pageNumber}
                        </Text>
                      </Button>
                    ),
                  )}
                  <Button
                    variant="no-effects"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                    transition="all .5s ease"
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg="transparent"
                    border="1px solid"
                    borderColor={useColorModeValue('gray.200', 'white')}
                    display={
                      pagination.pageSize === 5
                        ? 'none'
                        : table.getCanNextPage()
                          ? 'flex'
                          : 'none'
                    }
                    _hover={{
                      bg: 'whiteAlpha.100',
                      opacity: '0.7',
                    }}
                  >
                    <Icon
                      as={MdChevronRight}
                      w="16px"
                      h="16px"
                      color={textColor}
                    />
                  </Button>
                </Stack>
              </div>
            </Flex>

            <LoyaltyArchiveAlert
              isArchiveDialogOpen={isArchiveDialogOpen}
              setIsArchiveDialogOpen={setIsArchiveDialogOpen}
              selectedProgramId={selectedProgramId}
              loyaltyPrograms={loyaltyPrograms}
              handleArchive={handleArchive}
              cancelRef={cancelRef}
            />
          </Card>
        </Flex>
      </Flex>
    </Box>
  );
}
