import { Editor, Transforms, Element as SlateElement, Text } from 'slate';

export const isFormatActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n => n[format] === true,
        mode: 'all',
    });
    return !!match;
};

export const toggleFormat = (editor, format) => {
    const isActive = isFormatActive(editor, format);
    Transforms.setNodes(
        editor,
        { [format]: isActive ? null : true },
        { match: Text.isText, split: true }
    );
};

export const insertBlock = (editor, type) => {
    const block = {
        type,
        children: [{ text: '' }],
        attrs: type === 'highlights' ? { items: [] } : { section1: '', section2: '', section3: '' }
    };

    Transforms.insertNodes(editor, block);
};

export const serialize = nodes => {
    if (!nodes) return null;

    return nodes.map(n => {
        if (Text.isText(n)) {
            let text = { text: n.text };
            if (n.bold) text.bold = true;
            if (n.italic) text.italic = true;
            if (n.underline) text.underline = true;
            if (n.strikethrough) text.strikethrough = true;
            return text;
        }

        const children = n.children?.map(n => serialize([n])[0]);

        if (n.type === 'highlights' || n.type === 'benefits') {
            return {
                type: n.type,
                attrs: n.attrs || {},
                children
            };
        }

        return {
            type: n.type,
            children
        };
    });
};

export const deserialize = content => {
    if (!content) {
        return [{ type: 'paragraph', children: [{ text: '' }] }];
    }

    try {
        // If content is already an array of nodes
        if (Array.isArray(content)) {
            return content.map(node => {
                if (!node.children) {
                    return { ...node, children: [{ text: '' }] };
                }
                return node;
            });
        }

        // If content is a string, try to parse it
        if (typeof content === 'string') {
            try {
                const parsed = JSON.parse(content);

                // Handle single node objects (like benefits/highlights)
                if (parsed && typeof parsed === 'object' && !Array.isArray(parsed)) {
                    if (parsed.type === 'benefits') {
                        return [{
                            type: 'benefits',
                            attrs: parsed.attrs || { section1: '', section2: '', section3: '' },
                            children: [{ text: '' }]
                        }];
                    }
                    if (parsed.type === 'highlights') {
                        return [{
                            type: 'highlights',
                            attrs: parsed.attrs || { items: [] },
                            children: [{ text: '' }]
                        }];
                    }
                }

                // Handle array of nodes
                if (Array.isArray(parsed)) {
                    return parsed.map(node => {
                        if (!node.children) {
                            return { ...node, children: [{ text: '' }] };
                        }
                        return node;
                    });
                }

                // Handle plain object as single node
                if (parsed && typeof parsed === 'object') {
                    return [{ ...parsed, children: parsed.children || [{ text: '' }] }];
                }

                // Handle plain text
                return [{ type: 'paragraph', children: [{ text: String(parsed) }] }];
            } catch (e) {
                // If JSON parsing fails, treat as plain text
                return [{
                    type: 'paragraph',
                    children: [{ text: content }]
                }];
            }
        }

        // Handle plain text
        return [{
            type: 'paragraph',
            children: [{ text: String(content) }]
        }];
    } catch (error) {
        console.error('Error deserializing content:', error);
        return [{ type: 'paragraph', children: [{ text: '' }] }];
    }
}; 