// src/components/orders/OrderDetail.jsx
import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Button,
  useToast,
  Text,
  Badge,
  Spinner,
  Select,
  Card,
  Stack,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Icon,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonGroup,
} from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getOrderDetails,
  updateOrderStatus,
  addOrderComment,
  getOrderStatusLogs
} from '../../api/order';
import { getProductDetails } from '../../api/product';
import { getUserDetails } from '../../api/user';
import { format } from 'date-fns';

export default function OrderDetail() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [orderDetails, setOrderDetails] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [comment, setComment] = useState('');
  const [statusLogs, setStatusLogs] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(true);

  // Fetch the order details
  useEffect(() => {
    const fetchData = async () => {
      try {
        // First fetch order details
        const order = await getOrderDetails(uuid);
        setOrderDetails(order);

        // Then fetch related data in parallel
        const [product, user, logs] = await Promise.all([
          getProductDetails(order.product_uuid),
          getUserDetails(order.user_uuid),
          getOrderStatusLogs(uuid)
        ]);

        setProductImage(product.images[0]?.image_url || null);
        setUserDetails(user);
        setStatusLogs(logs);
      } catch (error) {
        console.error('Error fetching order details:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch order details.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
        setLoadingLogs(false);
      }
    };

    fetchData();
  }, [uuid, toast]);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setUpdatingStatus(true);
    try {
      await updateOrderStatus(uuid, newStatus, comment);
      setOrderDetails((prevDetails) => ({
        ...prevDetails,
        status: newStatus,
      }));

      // Refresh status logs
      const logs = await getOrderStatusLogs(uuid);
      setStatusLogs(logs);

      setComment(''); // Clear comment after successful update

      toast({
        title: 'Success',
        description: `Order status updated to ${newStatus}.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating order status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update order status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUpdatingStatus(false);
    }
  };

  const handleAddComment = async () => {
    if (!comment.trim()) return;

    try {
      await addOrderComment(uuid, comment);

      // Refresh status logs
      const logs = await getOrderStatusLogs(uuid);
      setStatusLogs(logs);

      setComment(''); // Clear comment

      toast({
        title: 'Success',
        description: 'Comment added successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding comment:', error);
      toast({
        title: 'Error',
        description: 'Failed to add comment.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getStatusColor = (status) => {
    const statusColors = {
      complete: 'green',
      paid: 'purple',
      processing: 'yellow',
      initiated: 'cyan',
      failed: 'red',
    };
    return statusColors[status] || 'gray';
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" h="200px">
        <Spinner size="lg" />
      </Flex>
    );
  }

  if (!orderDetails) {
    return (
      <Text textAlign="center" mt="20px">
        No order details found.
      </Text>
    );
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
        {/* Order Information Card */}
        <Card p={6}>
          <Stack spacing={4}>
            <Box>
              <Button
                leftIcon={<Icon as={MdArrowBack} />}
                variant="ghost"
                onClick={() => navigate('/admin/orders')}
                mr={4}
              >
                Back
              </Button>
            </Box>
            <Box>
              <Text color="gray.500" fontSize="sm">
                Order ID
              </Text>
              <Text fontSize="md" fontWeight="medium">
                {orderDetails.id}
              </Text>
            </Box>

            <Box>
              <Text color="gray.500" fontSize="sm">
                Customer
              </Text>
              <Text fontSize="md" fontWeight="medium">
                {userDetails ? userDetails.full_name || userDetails.email : 'Loading...'}
              </Text>
            </Box>

            <Box>
              <Text color="gray.500" fontSize="sm">
                Product
              </Text>
              <Text fontSize="md" fontWeight="medium">
                {orderDetails.product_name}
              </Text>
            </Box>

            <Box>
              <Text color="gray.500" fontSize="sm">
                Purchase Date
              </Text>
              <Text fontSize="md" fontWeight="medium">
                {format(new Date(orderDetails.purchase_date), 'MMMM dd, yyyy, h:mm a')}
              </Text>
            </Box>

            <Box>
              <Text color="gray.500" fontSize="sm">
                Price
              </Text>
              <Text fontSize="md" fontWeight="medium">
                €{orderDetails.purchase_price.toFixed(2)}
              </Text>
            </Box>

            <Divider />

            <Box>
              <Text color="gray.500" fontSize="sm" mb={2}>
                Status
              </Text>
              <Badge
                colorScheme={getStatusColor(orderDetails.status)}
                fontSize="sm"
                px={2}
                py={1}
                borderRadius="full"
              >
                {orderDetails.status?.charAt(0).toUpperCase() + orderDetails.status?.slice(1)}
              </Badge>
            </Box>

            <Box>
              <Text color="gray.500" fontSize="sm" mb={2}>
                Update Status
              </Text>
              <Select
                value={orderDetails.status}
                onChange={handleStatusChange}
                isDisabled={updatingStatus}
                size="sm"
              >
                <option value="initiated">Initiated</option>
                <option value="paid">Paid</option>
                <option value="processing">Processing</option>
                <option value="complete">Complete</option>
                <option value="failed">Failed</option>
              </Select>
              <Textarea
                placeholder="Add a comment (optional)"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                size="sm"
                mt={2}
              />
            </Box>
          </Stack>
        </Card>

        {/* Product Image Card */}
        <Card p={6}>
          <Text color="gray.500" fontSize="sm" mb={4}>
            Product Image
          </Text>
          {productImage && (
            <>
              <Image
                src={productImage}
                alt={orderDetails.product_name}
                objectFit="cover"
                borderRadius="md"
                cursor="pointer"
                onClick={onOpen}
                maxH="300px"
                mx="auto"
              />

              <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                  <ModalCloseButton />
                  <ModalBody p={0}>
                    <Image
                      src={productImage}
                      alt={orderDetails.product_name}
                      w="100%"
                      borderRadius="md"
                    />
                  </ModalBody>
                </ModalContent>
              </Modal>
            </>
          )}
          {!productImage && (
            <Flex
              justify="center"
              align="center"
              bg="gray.100"
              h="300px"
              borderRadius="md"
            >
              <Text color="gray.500">No image available</Text>
            </Flex>
          )}
        </Card>
      </SimpleGrid>

      {/* Replace the Status Update section with just Comments */}
      <Card mt={4} p={6}>
        <Stack spacing={4}>
          <Text fontSize="lg" fontWeight="medium">
            Add Comment
          </Text>
          <Textarea
            placeholder="Add a comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            size="sm"
          />

          <ButtonGroup>
            <Button
              colorScheme="blue"
              onClick={handleAddComment}
              isDisabled={!comment.trim()}
              size="sm"
            >
              Add Comment
            </Button>
          </ButtonGroup>
        </Stack>
      </Card>

      {/* Status and Comments Log */}
      <Card mt={4} p={6}>
        <Text fontSize="lg" fontWeight="medium" mb={4}>
          Status History & Comments
        </Text>
        {loadingLogs ? (
          <Flex justify="center" py={4}>
            <Spinner />
          </Flex>
        ) : (
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>User</Th>
                <Th>Status Change</Th>
                <Th>Comment</Th>
              </Tr>
            </Thead>
            <Tbody>
              {statusLogs.map((log) => (
                <Tr key={log.id}>
                  <Td>{format(new Date(log.updated_at), 'MMM dd, yyyy HH:mm')}</Td>
                  <Td>{log.user_name || 'Unknown User'}</Td>
                  <Td>
                    {log.status_from !== log.status_to ? (
                      <>
                        <Badge colorScheme={getStatusColor(log.status_from)} mr={2}>
                          {log.status_from}
                        </Badge>
                        →
                        <Badge colorScheme={getStatusColor(log.status_to)} ml={2}>
                          {log.status_to}
                        </Badge>
                      </>
                    ) : (
                      <Badge colorScheme={getStatusColor(log.status_from)}>
                        {log.status_from}
                      </Badge>
                    )}
                  </Td>
                  <Td>{log.comment}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Card>
    </Box>
  );
}
