import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Flex,
  Text,
  Button,
  Card,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Alert,
  AlertIcon,
  Spinner,
} from '@chakra-ui/react';
import { getTranslation } from 'utils/translationUtils';
import { SlateBlockEditor } from './SlateBlockEditor';
import { getCompany, updateCmsBlocks } from 'api/company';
import { languages as countryLanguages } from 'countries-list';

const BLOCK_CONFIG = {
  hero: {
    key: 'cms_content_hero',
    title: 'Welcome area',
  },
  overview: {
    key: 'cms_content_overview',
    title: 'Overview',
  },
  benefits: {
    key: 'cms_content_benefits',
    title: 'Benefits',
  },
  highlights: {
    key: 'cms_content_highlights',
    title: 'Highlights',
  },
  footer: {
    key: 'cms_content_footer',
    title: 'Footer',
  },
};

const EditorBlock = React.memo(({ languageCode, blockKey, config, blocks, editorsRef }) => {
  const editorRef = React.useRef(null);

  React.useEffect(() => {
    if (!editorsRef.current[languageCode]) {
      editorsRef.current[languageCode] = {};
    }
    editorsRef.current[languageCode][blockKey] = editorRef;

    return () => {
      if (editorsRef.current[languageCode]) {
        delete editorsRef.current[languageCode][blockKey];
      }
    };
  }, [languageCode, blockKey, editorsRef]);

  return (
    <React.Fragment>
      <Text fontSize="xl" fontWeight="medium" mt={4} mb={2} pl={4}>
        {config.title}
      </Text>
      <SlateBlockEditor
        key={`editor-${languageCode}-${blockKey}`}
        ref={editorRef}
        initialContent={blocks[languageCode]?.[config.key]}
        blockKey={blockKey}
      />
    </React.Fragment>
  );
});

EditorBlock.displayName = 'EditorBlock';

export default function CmsBlocks() {
  const [blocks, setBlocks] = useState({});
  const [languages, setLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const editorsRef = useRef({});

  const fetchLanguages = useCallback(async () => {
    console.log('Starting fetchLanguages');
    try {
      const company = await getCompany();
      // console.log('Company data received:', company);

      const languageCodes = Array.isArray(company.languages)
        ? company.languages
        : Object.keys(company.languages);

      // console.log('Language codes from company:', languageCodes);

      const validLanguages = languageCodes
        .filter((code) => countryLanguages[code.toLowerCase()])
        .map((code) => {
          const langCode = code.toLowerCase();
          return {
            code: langCode,
            name: countryLanguages[langCode]?.name || code,
            nativeName: countryLanguages[langCode]?.native || code,
          };
        });

      // console.log('Valid languages:', validLanguages);

      if (validLanguages.length === 0) {
        console.warn('No valid languages found in company data');
        // Set default language if none found
        validLanguages.push({
          code: 'en',
          name: 'English',
          nativeName: 'English'
        });
      }

      setLanguages(validLanguages);
      editorsRef.current = validLanguages.reduce((acc, lang) => ({
        ...acc,
        [lang.code]: {},
      }), {});

      return validLanguages; // Return languages for use in the chain
    } catch (error) {
      console.error('Error fetching languages:', error);
      setError('Failed to load languages');
      throw error;
    }
  }, []);

  const fetchCmsBlocks = useCallback(async (validLanguages) => {
    console.log('Starting fetchCmsBlocks with languages:', validLanguages);
    setError(null);
    try {
      const newBlocks = {};

      for (const lang of validLanguages) {
        newBlocks[lang.code] = {};
        // console.log(`Fetching blocks for language: ${lang.code}`);

        for (const [blockKey, config] of Object.entries(BLOCK_CONFIG)) {
          try {
            // console.log(`Fetching content for ${lang.code}-${config.key}`);
            const content = await getTranslation(config.key, lang.code);
            // console.log(`Content received for ${lang.code}-${config.key}:`, content);
            if (content) {
              newBlocks[lang.code][config.key] = content;
            }
          } catch (err) {
            console.error(`Error fetching content for ${lang.code}-${config.key}:`, err);
          }
        }
      }

      // console.log('Final blocks object:', newBlocks);
      setBlocks(newBlocks);
    } catch (error) {
      console.error('Error fetching cms blocks:', error);
      setError('Failed to load CMS blocks');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log('CmsBlocks component mounted');
    fetchLanguages()
      .then((validLanguages) => {
        console.log('Languages fetched successfully:', validLanguages);
        return fetchCmsBlocks(validLanguages);
      })
      .catch((error) => {
        console.error('Error in initialization sequence:', error);
        setIsLoading(false);
      });
  }, [fetchLanguages, fetchCmsBlocks]);

  const handleSaveCmsBlocks = async () => {
    setIsSaving(true);
    setError(null);

    try {
      const updatedBlocks = {};

      // First, ensure all editors have saved their latest content
      for (const lang of languages) {
        updatedBlocks[lang.code] = {};
        for (const [blockKey, config] of Object.entries(BLOCK_CONFIG)) {
          const editor = editorsRef.current[lang.code]?.[blockKey]?.current;
          if (!editor) continue;

          try {
            const content = editor.getContent();
            if (content) {
              updatedBlocks[lang.code][config.key] = content;
            }
          } catch (error) {
            console.error(`Error saving ${blockKey} for ${lang.code}:`, error);
            throw new Error(`Failed to save ${blockKey} for ${lang.code}`);
          }
        }
      }

      await updateCmsBlocks(updatedBlocks);
      toast({
        title: 'Success',
        description: 'CMS blocks updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      setError(err.message || 'Failed to save CMS blocks');
      console.error('Error saving CMS blocks:', err);
    } finally {
      setIsSaving(false);
    }
  };

  const renderEditors = (languageCode) =>
    Object.entries(BLOCK_CONFIG).map(([blockKey, config]) => (
      <EditorBlock
        key={`${languageCode}-${blockKey}`}
        languageCode={languageCode}
        blockKey={blockKey}
        config={config}
        blocks={blocks}
        editorsRef={editorsRef}
      />
    ));

  if (isLoading) {
    return (
      <Card mb="20px" w="100%">
        <Flex justify="center" align="center" p={8}>
          <Spinner />
        </Flex>
      </Card>
    );
  }

  return (
    <Card mb="20px" w="100%" shadow="sm">
      <Flex direction="column" p={5}>
        <Flex align="center" justify="space-between" mb={5}>
          <Text fontSize="2xl" fontWeight="bold">
            CMS Blocks
          </Text>
          <Button
            colorScheme="blue"
            onClick={handleSaveCmsBlocks}
            isLoading={isSaving}
            loadingText="Saving..."
          >
            Save Changes
          </Button>
        </Flex>

        {error && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}

        <Tabs variant="enclosed">
          <TabList>
            {languages.map(lang => (
              <Tab key={`tab-${lang.code}`}>
                {lang.name || lang.code}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {languages.map(lang => (
              <TabPanel key={`panel-${lang.code}`}>
                {renderEditors(lang.code)}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        <Button
          colorScheme="blue"
          mt={5}
          onClick={handleSaveCmsBlocks}
          isLoading={isSaving}
          loadingText="Saving..."
        >
          Save Changes
        </Button>
      </Flex>
    </Card>
  );
}

