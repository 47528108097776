import React, { useMemo, useCallback } from 'react';
import { createEditor } from 'slate';
import { Slate, Editable, withReact, useSlate, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { BenefitsElement } from './slate/BenefitsElement';
import { HighlightsElement } from './slate/HighlightsElement';
import { isFormatActive, toggleFormat, insertBlock, serialize, deserialize } from './slate/utils';
import {
    Box,
    ButtonGroup,
    IconButton,
    Tooltip,
} from '@chakra-ui/react';
import {
    RiBold,
    RiItalic,
    RiUnderline,
    RiStrikethrough,
    RiH1,
    RiH2,
    RiH3,
    RiListUnordered,
    RiListOrdered,
    RiAlignLeft,
    RiAlignCenter,
    RiAlignRight,
    RiLayoutColumnLine,
    RiGalleryLine,
} from 'react-icons/ri';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right'];

const DEFAULT_PARAGRAPH = {
    type: 'paragraph',
    children: [{ text: '' }]
};

const DEFAULT_EDITOR_VALUE = [DEFAULT_PARAGRAPH];

const INITIAL_VALUES = {
    highlights: [{
        type: 'highlights',
        attrs: { items: [] },
        children: [{ text: '' }]
    }],
    benefits: [{
        type: 'benefits',
        attrs: { section1: '', section2: '', section3: '' },
        children: [{ text: '' }]
    }],
    default: DEFAULT_EDITOR_VALUE
};

const CustomElement = ({ attributes, children, element }) => {
    const editor = useSlate();
    switch (element.type) {
        case 'benefits':
            return <BenefitsElement {...attributes} element={element} editor={editor}>{children}</BenefitsElement>;
        case 'highlights':
            return <HighlightsElement {...attributes} element={element} editor={editor}>{children}</HighlightsElement>;
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
        case 'heading-three':
            return <h3 {...attributes}>{children}</h3>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

const CustomLeaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }
    if (leaf.italic) {
        children = <em>{children}</em>;
    }
    if (leaf.underline) {
        children = <u>{children}</u>;
    }
    if (leaf.strikethrough) {
        children = <del>{children}</del>;
    }

    return <span {...attributes}>{children}</span>;
};

const ToolbarButton = ({ format, icon, tooltip }) => {
    const editor = useSlate();
    return (
        <Tooltip label={tooltip}>
            <IconButton
                icon={icon}
                onClick={() => toggleFormat(editor, format)}
                isActive={isFormatActive(editor, format)}
                aria-label={format}
                size="sm"
                variant={isFormatActive(editor, format) ? 'solid' : 'ghost'}
                colorScheme="gray"
            />
        </Tooltip>
    );
};

const Toolbar = () => {
    const editor = useSlate();
    return (
        <ButtonGroup spacing={1} mb={2}>
            <ToolbarButton format="bold" icon={<RiBold />} tooltip="Bold" />
            <ToolbarButton format="italic" icon={<RiItalic />} tooltip="Italic" />
            <ToolbarButton format="underline" icon={<RiUnderline />} tooltip="Underline" />
            <ToolbarButton format="strikethrough" icon={<RiStrikethrough />} tooltip="Strike" />
            <ToolbarButton format="heading-one" icon={<RiH1 />} tooltip="Heading 1" />
            <ToolbarButton format="heading-two" icon={<RiH2 />} tooltip="Heading 2" />
            <ToolbarButton format="heading-three" icon={<RiH3 />} tooltip="Heading 3" />
            <ToolbarButton format="bulleted-list" icon={<RiListUnordered />} tooltip="Bullet List" />
            <ToolbarButton format="numbered-list" icon={<RiListOrdered />} tooltip="Numbered List" />
            <ToolbarButton format="align-left" icon={<RiAlignLeft />} tooltip="Align Left" />
            <ToolbarButton format="align-center" icon={<RiAlignCenter />} tooltip="Align Center" />
            <ToolbarButton format="align-right" icon={<RiAlignRight />} tooltip="Align Right" />
            <Box borderRight="1px" borderColor="gray.200" mx={2} />
            <Tooltip label="Add Benefits Section">
                <IconButton
                    icon={<RiLayoutColumnLine />}
                    onClick={() => insertBlock(editor, 'benefits')}
                />
            </Tooltip>
            <Tooltip label="Add Highlights Section">
                <IconButton
                    icon={<RiGalleryLine />}
                    onClick={() => insertBlock(editor, 'highlights')}
                />
            </Tooltip>
        </ButtonGroup>
    );
};

const SlateBlockEditor = React.forwardRef(({ initialContent, blockKey, onChange }, ref) => {
    // Create editor instance
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);

    // Get initial value based on blockKey or initialContent
    const initialValue = useMemo(() => {
        if (initialContent) {
            try {
                if (typeof initialContent === 'string') {
                    try {
                        const parsed = JSON.parse(initialContent);
                        if (parsed.type === 'highlights' || parsed.type === 'benefits') {
                            return INITIAL_VALUES[parsed.type];
                        }
                        return deserialize(initialContent);
                    } catch {
                        return deserialize(initialContent);
                    }
                }
                if (Array.isArray(initialContent) && initialContent.length > 0) {
                    return initialContent;
                }
            } catch (error) {
                console.error('Error parsing initial content:', error);
            }
        }
        return blockKey ? INITIAL_VALUES[blockKey] || DEFAULT_EDITOR_VALUE : DEFAULT_EDITOR_VALUE;
    }, [initialContent, blockKey]);

    // Handle focus and blur
    const handleFocus = useCallback(() => {
        ReactEditor.focus(editor);
    }, [editor]);

    const handleBlur = useCallback(() => {
        if (ReactEditor.isFocused(editor)) {
            ReactEditor.blur(editor);
        }
    }, [editor]);

    // Expose editor methods via ref
    React.useImperativeHandle(ref, () => ({
        getContent: () => serialize(editor.children),
        focus: handleFocus,
        blur: handleBlur,
    }), [editor, handleFocus, handleBlur]);

    const renderElement = useCallback(props => <CustomElement {...props} />, []);
    const renderLeaf = useCallback(props => <CustomLeaf {...props} />, []);

    return (
        <Box>
            <Slate editor={editor} initialValue={initialValue} onChange={onChange}>
                <Toolbar />
                <Box
                    sx={{
                        border: '1px solid',
                        borderColor: 'gray.200',
                        borderRadius: 'md',
                        minHeight: '200px',
                        padding: '1rem',
                    }}
                >
                    <Editable
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder="Start writing here..."
                        spellCheck
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </Box>
            </Slate>
        </Box>
    );
});

SlateBlockEditor.displayName = 'SlateBlockEditor';

export { SlateBlockEditor }; 