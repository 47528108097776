// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
  Switch,
  VStack,
  Box,
  useToast,
  Image,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  useDisclosure,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card.js';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getLoyaltyPrograms,
  updateLoyaltyProgram,
  updateLoyaltyLevel,
  updateProduct,
  createLoyaltyLevel,
  createProduct,
  getLoyaltyLevels,
  deleteLoyaltyProgram,
} from 'api/loyalty';

import LoyaltyLevelModal from 'components/loyalty/LoyaltyLevelModal';
import { convertToDays, convertFromDays } from 'utils/loyaltyUtils';
import {
  LoyaltyDeleteAlert,
  LoyaltyPublishAlert,
} from 'components/loyalty/LoyaltyAlerts';
import { getCompany } from 'api/company';
import { getTranslation, setTranslation } from 'utils/translationUtils';

export default function LoyaltyDetail() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const navigate = useNavigate();
  const toast = useToast();
  const { uuid } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const [images, setImages] = useState([]);
  const [isProductActive, setIsProductActive] = useState(true);
  const [isProductPublished, setIsProductPublished] = useState(0);
  const [useCustomContracts, setUseCustomContracts] = useState(true);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [programData, setProgramData] = useState({
    web3_loyalty_contract: '',
    web3_voucher_contract: '',
    products: [],
  });
  const [languages, setLanguages] = useState([
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
  ]);

  const [translations, setTranslationsState] = useState({
    en: {
      name: '',
      description: '',
    },
    et: {
      name: '',
      description: '',
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [levelModalData, setLevelModalData] = useState({
    product_uuid: '',
    loyalty_uuid: '',
    translations: {
      en: { name: '', description: '' },
      et: { name: '', description: '' },
    },
    price: 0,
    expirationTimePeriod: 0,
    expirationTimeUnit: 'days',
    is_archived: false,
    is_active: true,
    quantity: 0,
    sold_quantity: 0,
    is_infinite: false,
    has_expiration: false,
    images: [],
  });

  // Fetch program data if editing an existing program
  useEffect(() => {
    if (uuid) {
      getLoyaltyPrograms()
        .then(async (response) => {
          const filteredRespone = response.filter(
            (program) => program.uuid === uuid,
          );

          const company = await getCompany();
          if (company.langauges) {
            setLanguages(
              company.langauges.map((lang) => ({
                code: lang,
                name:
                  lang === 'en' ? 'English' : lang === 'et' ? 'Estonian' : lang,
              })),
            );
          }
          setProgramData(
            filteredRespone[0] || {
              web3_loyalty_contract: '',
              web3_voucher_contract: '',
              products: [],
            },
          );
          setIsProductActive(filteredRespone[0].is_active);
          setIsProductPublished(filteredRespone[0].is_published);
          setUseCustomContracts(false);

          let translationsObj = {};

          for (const lang of languages) {
            translationsObj[lang.code] = {
              name:
                (await getTranslation(`loyalty_name_${uuid}`, lang.code)) || '',
              description:
                (await getTranslation(
                  `loyalty_description_${uuid}`,
                  lang.code,
                )) || '',
            };
          }

          setTranslationsState(translationsObj);
        })

        .catch((error) => {
          console.error('Error fetching program data', error);
        });
    }
  }, [uuid, languages]);

  const handleSave = async () => {
    try {
      const { products, ...updatedProgramData } = {
        ...programData,
        is_active: isProductActive,
        is_published: isProductPublished,
      };
      await updateLoyaltyProgram(uuid, updatedProgramData).catch((error) => {
        console.error('Error updating loyalty program:', error);
      });

      toast({
        title: 'Success',
        description: 'Loyalty program updated successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving loyalty program:', error);
      toast({
        title: 'Error',
        description: 'Error saving loyalty program',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = useCallback((e) => {
    const { id, value } = e.target;
    setProgramData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }, []);

  const handleModalInputChange = (e, id) => {
    const value = e.target ? e.target.value : e;
    const inputId = id || e.target.id;
    if (value === '') {
      setLevelModalData((prevState) => ({
        ...prevState,
        [inputId]: '',
      }));
    } else {
      setLevelModalData((prevState) => ({
        ...prevState,
        [inputId]:
          inputId === 'price' || inputId === 'quantity' ? value : value,
      }));
    }
  };

  const handlePublish = () => {
    const { products, ...updatedProgramData } = {
      ...programData,
      is_active: isProductActive,
      is_published: isProductPublished,
    };
    updateLoyaltyProgram(uuid, updatedProgramData)
      .then(() => {
        toast({
          title: 'Success',
          description:
            'Loyalty program has been scheduled for publishing. This will take a few minutes.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/admin/loyalty-programs');
      })
      .catch((error) => {
        console.error('Error updating loyalty program:', error);
      });
  };

  const handleDelete = async (id) => {
    try {
      await deleteLoyaltyProgram(id);
      navigate('/admin/loyalty-programs');
      toast({
        title: 'Loyalty program deleted',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error deleting loyalty program',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleConfirmPublish = () => {
    onClose();
    handlePublish();
  };

  const handleDeleteConfirm = () => {
    onDeleteDialogClose();
    handleDelete(uuid);
  };

  const debouncedTranslationChange = useCallback(
    debounce(async (lang, field, value) => {
      try {
        const fieldMapping = {
          name: 'loyalty_name',
          description: 'loyalty_description',
        };

        await setTranslation(`${fieldMapping[field]}_${uuid}`, value, lang);
      } catch (error) {
        console.error('Error saving translation:', error);
        toast({
          title: 'Error saving changes',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }, 1000),
    [uuid, toast],
  );

  const handleTranslationChange = (lang, field, value) => {
    setTranslationsState((prev) => ({
      ...prev,
      [lang]: {
        ...prev[lang],
        [field]: value,
      },
    }));

    debouncedTranslationChange(lang, field, value);
  };

  const TIME_UNITS = [
    {
      days: 365,
      name: 'years',
    },
    {
      days: 30,
      name: 'months',
    },
    {
      days: 7,
      name: 'weeks',
    },
    {
      days: 1,
      name: 'days',
    },
  ];

  const convertDaysToOptimalUnit = (days) => {
    if (!days) return { value: 0, unit: 'days' };

    for (const unit of TIME_UNITS) {
      if (days >= unit.days && days % unit.days === 0) {
        return {
          value: days / unit.days,
          unit: unit.name,
        };
      }
    }

    return { value: days, unit: 'days' };
  };

  const openModal = async (productData) => {
    if (productData) {
      const loyaltyLevelsData = await getLoyaltyLevels();
      const selectedLevel = loyaltyLevelsData.filter(
        (level) => level.uuid === productData.loyalty_level_uuid,
      )[0];

      const { value, unit } = convertDaysToOptimalUnit(
        selectedLevel.expiration_period || 0,
      );

      const translationsArray = [];
      for (const lang of languages) {
        // Add name translation
        translationsArray.push({
          language: lang.code,
          key: 'name',
          value:
            productData.translations.find(
              (t) => t.language === lang.code && t.key === 'name',
            )?.value || '',
        });
        // Add description translation
        translationsArray.push({
          language: lang.code,
          key: 'description',
          value:
            productData.translations.find(
              (t) => t.language === lang.code && t.key === 'description',
            )?.value || '',
        });
      }

      setImages(productData.images ? productData.images : []);
      setLevelModalData({
        product_uuid: productData.product_uuid,
        loyalty_uuid: productData.loyalty_level_uuid,
        translations: translationsArray,
        price: productData.price,
        expirationTimePeriod: value,
        expirationTimeUnit: unit,
        is_archived: productData.is_archived,
        is_active: productData.is_active,
        quantity: productData.quantity,
        sold_quantity: productData.sold_quantity,
        is_infinite: !productData.quantity ? true : false,
        has_expiration: !selectedLevel.expiration_period ? false : true,
        images: productData.images,
      });
    } else {
      const translationsArray = [];
      for (const lang of languages) {
        translationsArray.push(
          { language: lang.code, key: 'name', value: '' },
          { language: lang.code, key: 'description', value: '' },
        );
      }
      setLevelModalData({
        product_uuid: '',
        loyalty_uuid: '',
        translations: translationsArray,
        price: 0,
        expirationTimePeriod: 0,
        expirationTimeUnit: 'days',
        is_archived: false,
        is_active: true,
        quantity: 0,
        sold_quantity: 0,
        is_infinite: false,
        has_expiration: false,
        images: [],
      });
    }

    setIsModalOpen(true);
  };

  const handleModalSave = () => {
    const {
      price,
      quantity,
      is_infinite,
      has_expiration,
      expirationTimePeriod,
      expirationTimeUnit,
    } = levelModalData;

    // Validation checks
    if (!translations || translations.length === 0) {
      toast({
        title: 'Error',
        description: 'Please provide translations for the loyalty level.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (price === undefined || price === null) {
      toast({
        title: 'Error',
        description: 'Valid price is required.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (!is_infinite && (quantity === undefined || quantity === null)) {
      toast({
        title: 'Error',
        description: 'Quantity is required when not infinite.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (has_expiration && (!expirationTimePeriod || !expirationTimeUnit)) {
      toast({
        title: 'Error',
        description: 'Specify expiration time period and unit.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const days = convertToDays(
      levelModalData.expirationTimePeriod,
      levelModalData.expirationTimeUnit,
    );

    const isEditing = !!levelModalData.loyalty_uuid;

    const levelRequest = isEditing
      ? updateLoyaltyLevel(levelModalData.loyalty_uuid, {
          expiration_period: has_expiration ? days : 0,
          is_active: levelModalData.is_active,
          is_archived: levelModalData.is_archived,
        })
      : createLoyaltyLevel({
          expiration_period: has_expiration ? days : 0,
          is_active: levelModalData.is_active,
          is_archived: levelModalData.is_archived,
          loyalty_program_uuid: uuid,
        });
    levelRequest
      .then((response) => {
        const levelUuid = response.uuid;

        const productRequest = isEditing
          ? updateProduct(levelModalData.product_uuid, {
              price: levelModalData.price,
              quantity: levelModalData.quantity,
              loyalty_program_uuid: uuid,
              loyalty_level_uuid: levelUuid,
              is_active: levelModalData.is_active,
              is_archived: levelModalData.is_archived,
            })
          : createProduct({
              price: levelModalData.price ? levelModalData.price : 0,
              quantity: levelModalData.quantity ? levelModalData.quantity : 0,
              loyalty_program_uuid: uuid,
              is_active: levelModalData.is_active,
              is_archived: levelModalData.is_archived,
              loyalty_level_uuid: levelUuid,
            });

        productRequest
          .then(async (response) => {
            const newProduct = {
              ...response,
              product_uuid: response.uuid,
            };

            const levelTranslations = levelModalData.translations;
            const levelUuid = newProduct.product_uuid;
            for (const translation of levelTranslations) {
              if (
                translation.key === 'name' ||
                translation.key === 'description'
              ) {
                await setTranslation(
                  `product_${translation.key}_${levelUuid}`,
                  translation.value,
                  translation.language,
                );
              }
            }
            // Update the program data with the new product
            setProgramData((prevState) => ({
              ...prevState,
              products: isEditing
                ? prevState.products.map((product) =>
                    product.product_uuid === newProduct.product_uuid
                      ? {
                          ...product,
                          ...newProduct,
                          images: levelModalData.images,
                          translations: levelModalData.translations, // Use translations array directly
                        }
                      : product,
                  )
                : [
                    ...prevState.products,
                    {
                      ...newProduct,
                      images: levelModalData.images,
                      translations: levelModalData.translations, // Use translations array directly
                    },
                  ],
            }));

            setLevelModalData({
              product_uuid: '',
              loyalty_uuid: '',
              translations: {
                en: { name: '', description: '' },
                et: { name: '', description: '' },
              },
              price: 0,
              expirationTimePeriod: 0,
              expirationTimeUnit: 'days',
              is_archived: false,
              is_active: true,
              quantity: 0,
              is_infinite: false,
              has_expiration: false,
              images: [],
            });

            setIsModalOpen(false);
          })
          .catch((error) => {
            toast({
              title: 'Error',
              description: `Error saving product: ${error}`,
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
          });
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: `Error saving loyalty level: ${error}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleArchive = async () => {
    try {
      const response = await updateLoyaltyLevel(levelModalData.loyalty_uuid, {
        is_archived: !levelModalData.is_archived,
      });

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Loyalty level archived successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setIsModalOpen(false);
        setLevelModalData((prevState) => ({
          ...prevState,
          is_archived: !levelModalData.is_archived,
        }));
      } else {
        toast({
          title: 'Error',
          description: 'Error archiving loyalty level',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error archiving loyalty level:', error);
    } finally {
      setIsArchiveDialogOpen(false);
    }
  };

  return (
    <FormControl pt={{ sm: '125px', md: '75px' }}>
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
        {/* Left Column: Program Information */}
        <Card>
          <Flex direction="column" minHeight="100%">
            {/* Header Section */}
            <Flex direction="column" mb="40px" ms="10px">
              <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                  Program Information
                </Text>
                <Flex align="center">
                  <Text
                    fontSize="xl"
                    color={textColorPrimary}
                    mr="10px"
                    fontWeight="bold"
                  >
                    Active Status
                  </Text>
                  <Switch
                    isChecked={isProductActive}
                    onChange={(e) => setIsProductActive(e.target.checked)}
                    colorScheme="blue"
                    size="lg"
                  />
                </Flex>
              </Flex>
              <Text fontSize="md" color={textColorSecondary}>
                Edit the details of your loyalty program
              </Text>
            </Flex>

            {/* Content Section */}
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px" flex="1">
              {/* Left Column: Name and Description */}
              <Tabs width="100%">
                <TabList>
                  {languages.map((lang) => (
                    <Tab key={lang.code}>{lang.name}</Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {languages.map((lang) => (
                    <TabPanel key={lang.code}>
                      <InputField
                        mb="25px"
                        id={`name-${lang.code}`}
                        label="Program Name"
                        placeholder="Enter program name"
                        value={translations[lang.code]?.name || ''}
                        onChange={(e) =>
                          handleTranslationChange(
                            lang.code,
                            'name',
                            e.target.value,
                          )
                        }
                      />
                      <TextField
                        mb="25px"
                        id={`description-${lang.code}`}
                        label="Description"
                        placeholder="Enter program description"
                        h="150px"
                        value={translations[lang.code]?.description || ''}
                        onChange={(e) =>
                          handleTranslationChange(
                            lang.code,
                            'description',
                            e.target.value,
                          )
                        }
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
              {/* Right Column: Contract Addresses and Toggle */}
              <Flex direction="column">
                <Flex justify="space-between" align="center" mb="25px">
                  <Box ml="10px">
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color={textColorPrimary}
                      mb="8px"
                    >
                      Use Custom Contracts
                    </Text>
                    <Text fontSize="sm" color={textColorSecondary}>
                      Perxify's or custom contracts
                    </Text>
                  </Box>
                  <Switch
                    isChecked={useCustomContracts}
                    onChange={() => setUseCustomContracts(!useCustomContracts)}
                  />
                </Flex>

                <InputField
                  mb="25px"
                  id="web3_loyalty_contract"
                  label="Loyalty Contract Address"
                  placeholder="Enter loyalty contract address"
                  value={programData.web3_loyalty_contract}
                  onChange={handleInputChange}
                  isDisabled={!useCustomContracts}
                />
                <InputField
                  mb="25px"
                  id="web3_voucher_contract"
                  label="Voucher Contract Address"
                  placeholder="Enter voucher contract address"
                  value={programData.web3_voucher_contract}
                  onChange={handleInputChange}
                  isDisabled={!useCustomContracts}
                />
              </Flex>
            </SimpleGrid>

            {/* Buttons Section */}
            <Flex mt="auto" justify="flex-end" gap="20px">
              {isProductPublished === 0 && (
                <Button
                  bgColor="#FFD600"
                  minW="183px"
                  fontSize="sm"
                  fontWeight="500"
                  onClick={onOpen} // Open the alert dialog
                >
                  Publish
                </Button>
              )}
              {(isProductPublished === 1 || isProductPublished === 2) && (
                <Button
                  bgColor="#FFD600"
                  minW="183px"
                  fontSize="sm"
                  fontWeight="500"
                  isDisabled
                >
                  {isProductPublished === 1 && 'Published'}
                  {isProductPublished === 2 && (
                    <>
                      Publishing...
                      <Spinner size="sm" ml="2" />
                    </>
                  )}
                </Button>
              )}
              {isProductPublished === 0 ? (
                <Button
                  colorScheme="red"
                  minW="183px"
                  fontSize="sm"
                  fontWeight="500"
                  onClick={onDeleteDialogOpen}
                >
                  Delete
                </Button>
              ) : (
                <Button
                  variant="brand"
                  minW="183px"
                  fontSize="sm"
                  fontWeight="500"
                  onClick={handleSave}
                >
                  Save changes
                </Button>
              )}
            </Flex>
          </Flex>
        </Card>
        {/* Right Column: Loyalty Levels */}
        <Card>
          <Flex direction="column" mb="40px" ms="10px">
            <Flex justify="space-between" align="center">
              <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                Loyalty Levels
              </Text>
              <Button
                colorScheme="blue"
                minW="140px"
                h="35px"
                fontSize="xl"
                lineHeight="35px"
                onClick={() => openModal()}
              >
                +
              </Button>
            </Flex>
            <Text fontSize="md" color={textColorSecondary}>
              Manage the levels of your loyalty program
            </Text>
          </Flex>
          <VStack spacing="20px">
            {programData.products && programData.products.length > 0 ? (
              programData.products.map((product) => {
                const featuredImage = product.images?.find(
                  (image) => image.is_featured,
                );

                return (
                  <Box
                    key={product.product_uuid}
                    w="100%"
                    p="10px"
                    borderRadius="lg"
                    boxShadow="md"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center">
                      {featuredImage && (
                        <Box
                          borderRadius="10px"
                          overflow="hidden"
                          width="100px"
                          height="100px"
                          position="relative"
                          pt="10px"
                          mr="10px"
                        >
                          <Image
                            src={featuredImage.image_url}
                            alt={
                              product.translations.filter(
                                (translation) => translation.key === 'name',
                              )[0]?.value
                            }
                            objectFit="contain"
                            style={{
                              borderRadius: '10px',
                              clipPath: 'inset(0 round 10px)',
                            }}
                          />
                        </Box>
                      )}
                      <Box>
                        <Flex alignItems="center" mb="5px">
                          <Text fontSize="md" fontWeight="bold">
                            {product.translations?.find(
                              (t) => t.language === 'en' && t.key === 'name',
                            )?.value || 'Unnamed Level'}
                          </Text>
                          {product.quantity > 0 &&
                            product.sold_quantity >= product.quantity && (
                              <Text
                                fontSize="md"
                                color="red.500"
                                fontWeight="bold"
                                ml="10px"
                              >
                                Sold Out
                              </Text>
                            )}
                        </Flex>
                        <Text fontSize="sm" color={textColorSecondary} mb="5px">
                          Is Active: {product.is_active ? 'Yes' : 'No'}
                        </Text>
                        <Text fontSize="sm" color={textColorSecondary} mb="5px">
                          Default Expiration:{' '}
                          {convertFromDays(product.expiration_period)}
                        </Text>
                        <Text fontSize="sm" color={textColorSecondary} mb="5px">
                          Quantity: {product.quantity || 'Infinite'}
                        </Text>
                        {product.quantity > 0 && (
                          <Text fontSize="sm" color={textColorSecondary}>
                            Sold Quantity:{' '}
                            {product.sold_quantity ? product.sold_quantity : 0}
                          </Text>
                        )}
                      </Box>
                    </Box>
                    <Box
                      as="button"
                      onClick={() => openModal(product)}
                      aria-label="Edit"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      p="4"
                      mr="30px"
                      borderRadius="md"
                      _hover={{ bg: 'gray.200' }}
                    >
                      <EditIcon w={5} h={5} />
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Text fontSize="md" color={textColorSecondary}>
                Levels will appear here
              </Text>
            )}
          </VStack>
        </Card>
      </SimpleGrid>

      {/* Alert Dialogs */}
      <LoyaltyDeleteAlert
        {...{
          isDeleteDialogOpen,
          onDeleteDialogClose,
          handleDeleteConfirm,
          cancelRef,
        }}
      />

      <LoyaltyPublishAlert
        {...{ isOpen, onClose, handleConfirmPublish, cancelRef }}
      />

      {/* Loyalty Level Modal */}
      <LoyaltyLevelModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        levelModalData={levelModalData}
        setLevelModalData={setLevelModalData}
        handleModalInputChange={handleModalInputChange}
        images={images}
        setImages={setImages}
        handleModalSave={handleModalSave}
        isArchiveDialogOpen={isArchiveDialogOpen}
        setIsArchiveDialogOpen={setIsArchiveDialogOpen}
        handleArchive={handleArchive}
        toast={toast}
        languages={languages}
      />
    </FormControl>
  );
}
