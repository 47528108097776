import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CompanySelector from '../../../components/company/CompanySelector';
import Cookies from 'js-cookie';
import { setAuthCookies } from './setAuthCookies';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';

import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/types/Default';

import illustration from 'assets/svg/Perxify-logo.svg';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

import { googleSignIn } from '../../../services/googleAuthService';
import { login } from '../../../services/authService';
import {
  fetchCompanies,
  setCompanyCookie,
} from '../../../services/companyService';
import { updateUserPassword } from 'api/user';

export default function SignIn() {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorBrand = useColorModeValue('blue.500', 'white');
  const brandStars = useColorModeValue('blue.500', 'blue.500');
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const googleText = useColorModeValue('navy.700', 'white');
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );

  // State variables
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [, setError] = useState('');
  const [, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [token, setToken] = useState(() => Cookies.get('token') || null);
  const [isOtp, setIsOtp] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companies, setCompanies] = useState([]);

  // Handlers
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleClick = () => setShow(!show);

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = Cookies.get('token');
    const storedCompany = Cookies.get('x-company');
    if (storedToken && storedCompany) {
      setToken(storedToken);
      navigate('/admin/dashboard');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { token, is_otp } = await login(email, password);
      if (!token) throw new Error('Token not found');

      setToken(token);
      setAuthCookies(token); // Set token in cookies
      setIsOtp(is_otp === 1 ? true : false);

      if (isOtp) {
        setIsModalOpen(true);
        return;
      }

      const companies = await fetchCompanies();
      setCompanies(companies);

      if (companies.length === 1) {
        handleCompanySelected(companies[0]);
      }
    } catch (err) {
      setError('Invalid email or password');
      toast({
        title: 'An error occurred.',
        description: 'Invalid email or password',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = () => {
    googleSignIn(); // Initiates the redirect to Google sign-in
  };

  const handleCompanySelected = useCallback(
    (company) => {
      setCompanyCookie(company);
      setAuthCookies(token, company); // Set both token and company in cookies
      setSelectedCompany(company);
      navigate('/admin/dashboard');
    },
    [navigate, token],
  );

  const handlePasswordReset = async () => {
    if (newPassword !== confirmPassword) {
      toast({
        title: 'Error',
        description: 'Passwords do not match',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await updateUserPassword({
        currentPassword: password,
        newPassword,
      });

      toast({
        title: 'Success',
        description: 'Your password has been updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setIsModalOpen(false);
      setIsOtp(false);

      // Proceed to company selection or dashboard
      const companies = await fetchCompanies();
      setCompanies(companies);

      if (companies.length === 1) {
        handleCompanySelected(companies[0]);
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update password',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <DefaultAuth
        illustrationBackground={illustration}
        image={illustration}
        style={{ backgroundPosition: 'top right', backgroundSize: 'contain' }}
      >
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w="100%"
          mx={{ base: 'auto', lg: '0px' }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '14vh' }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Sign In
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', md: '420px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            <Button
              fontSize="sm"
              me="0px"
              mb="26px"
              py="15px"
              h="50px"
              borderRadius="16px"
              bg={googleBg}
              color={googleText}
              fontWeight="500"
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}
              onClick={handleGoogleSignIn}
            >
              <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
              Sign in with Google
            </Button>
            <Flex align="center" mb="25px">
              <HSeparator />
              <Text color="gray.400" mx="14px">
                or
              </Text>
              <HSeparator />
            </Flex>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  required
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  id="email"
                  type="email"
                  placeholder="mail@simmmple.com"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  autoComplete="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  required
                  display="flex"
                >
                  Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    required
                    fontSize="sm"
                    placeholder="Min. 8 characters"
                    mb="24px"
                    size="lg"
                    type={show ? 'text' : 'password'}
                    id="password"
                    variant="auth"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="current-password"
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                <Flex justifyContent="space-between" align="center" mb="24px">
                  <FormControl display="flex" alignItems="center">
                    <Checkbox
                      id="remember-login"
                      colorScheme="brand"
                      me="10px"
                    />
                    <FormLabel
                      htmlFor="remember-login"
                      mb="0"
                      fontWeight="normal"
                      color={textColor}
                      fontSize="sm"
                    >
                      Keep me logged in
                    </FormLabel>
                  </FormControl>
                  <NavLink to="/auth/forgot-password">
                    <Text
                      color={textColorBrand}
                      fontSize="sm"
                      w="124px"
                      fontWeight="500"
                    >
                      Forgot password?
                    </Text>
                  </NavLink>
                </Flex>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                >
                  Sign In
                </Button>
              </FormControl>
            </form>
          </Flex>
        </Flex>
        {token &&
          companies.length > 1 &&
          !selectedCompany &&
          isOtp === false && (
            <CompanySelector
              token={token}
              onCompanySelected={handleCompanySelected}
            />
          )}
      </DefaultAuth>

      <Modal isOpen={isModalOpen} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Your New Password</ModalHeader>
          <ModalBody>
            {/* Password Reset Form */}
            <FormControl>
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                minLength={6}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Confirm New Password</FormLabel>
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                minLength={6}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handlePasswordReset}
              disabled={
                !newPassword ||
                !confirmPassword ||
                newPassword !== confirmPassword
              }
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
