import React, { useState, useCallback } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  useToast,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import Card from 'components/card/Card.js';
import { updateUserPassword } from 'api/user';

const PasswordInput = ({
  label,
  placeholder,
  value,
  onChange,
  showPassword,
  onToggleShowPassword,
  mb,
}) => (
  <>
    <FormLabel>{label}</FormLabel>
    <InputGroup>
      <Input
        type={showPassword ? 'text' : 'password'}
        variant="auth"
        placeholder={placeholder}
        value={value}
        mb={mb}
        onChange={onChange}
      />
      <InputRightElement onClick={onToggleShowPassword} cursor="pointer">
        <Icon
          as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
          color="gray.400"
        />
      </InputRightElement>
    </InputGroup>
  </>
);

export default function Settings() {
  const toast = useToast();
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  });
  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  const handleUpdatePassword = useCallback(async () => {
    setIsLoading(true);
    try {
      if (passwords.newPassword !== passwords.newPasswordConfirmation) {
        toast({
          title: 'Passwords do not match',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (passwords.newPassword.length < 8) {
        toast({
          title: 'Password must be at least 8 characters long',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (passwords.currentPassword === passwords.newPassword) {
        toast({
          title: 'New password must be different from current password',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      await updateUserPassword({
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
      });

      setPasswords({
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      });
      toast({
        title: 'Password updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating password:', error);

      // Check for 400 status and specific error message
      if (
        error.response?.status === 400 &&
        error.response?.data?.error === 'Invalid password'
      ) {
        toast({
          title: 'Incorrect Password',
          description: 'The current password you entered is incorrect',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Error updating password',
          description: 'An unexpected error occurred. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [passwords, toast]);

  const handlePasswordChange = (field) => (event) => {
    setPasswords((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const toggleShowPassword = (field) => () => {
    setShowPasswords((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <FormControl>
      <Card>
        <Flex direction="column" mb="40px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Change Password
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            You can set a new password here. The password must be at least 8
            characters long.
          </Text>
        </Flex>
        <FormControl>
          <Flex flexDirection="column">
            {/* Current Password */}
            <PasswordInput
              label="Current Password"
              placeholder="Current password"
              value={passwords.currentPassword}
              onChange={handlePasswordChange('currentPassword')}
              showPassword={showPasswords.current}
              onToggleShowPassword={toggleShowPassword('current')}
              mb="30px"
            />

            {/* New Password */}
            <PasswordInput
              label="New Password"
              placeholder="New password"
              value={passwords.newPassword}
              onChange={handlePasswordChange('newPassword')}
              showPassword={showPasswords.new}
              onToggleShowPassword={toggleShowPassword('new')}
              mb="10px"
            />

            {/* Confirm New Password */}
            <PasswordInput
              label="Confirm New Password"
              placeholder="Confirm new password"
              value={passwords.newPasswordConfirmation}
              onChange={handlePasswordChange('newPasswordConfirmation')}
              showPassword={showPasswords.confirm}
              onToggleShowPassword={toggleShowPassword('confirm')}
              mb="20px"
            />
          </Flex>
        </FormControl>
        <Button
          variant="brand"
          minW="183px"
          fontSize="sm"
          fontWeight="500"
          ms="auto"
          onClick={handleUpdatePassword}
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          Change Password
        </Button>
      </Card>
    </FormControl>
  );
}
