import React, { useState, useCallback, useRef, useEffect } from 'react';
import { InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

const SearchInput = ({ onSearch, initialValue = '' }) => {
    const [value, setValue] = useState(initialValue);
    const searchTimeoutRef = useRef(null);

    // Update local value when initialValue changes
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleChange = useCallback((e) => {
        const newValue = e.target.value;
        setValue(newValue); // Update local state immediately for smooth typing

        // Clear existing timeout
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        // Set new timeout for search
        searchTimeoutRef.current = setTimeout(() => {
            onSearch(newValue);
        }, 500);
    }, [onSearch]);

    // Cleanup timeout on unmount
    useEffect(() => {
        return () => {
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current);
            }
        };
    }, []);

    return (
        <InputGroup maxW="300px" mb={4}>
            <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" />
            </InputLeftElement>
            <Input
                id="order-search"
                name="order-search"
                placeholder="Search orders..."
                onChange={handleChange}
                value={value}
                variant="filled"
                autoComplete="off"
            />
        </InputGroup>
    );
};

export default React.memo(SearchInput); 