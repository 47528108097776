import apiClient from './axios';

export const getUserDetails = async (userUuid) => {
  try {
    const response = await apiClient.get(`/user/${userUuid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await apiClient.get('/users');
    return response.data.map((user) => ({
      ...user,
      avatar_url: user.avatar_url,
    }));
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};
// api/user.js
export const getLoggedInUserDetails = async () => {
  try {
    const response = await apiClient.get('/user');
    return response.data;
  } catch (error) {
    console.error('Error fetching logged-in user details:', error);
    throw error;
  }
};

export const updateUserPassword = async (passwords) => {
  try {
    const response = await apiClient.put(`/update-password`, {
      current_password: passwords.currentPassword,
      new_password: passwords.newPassword,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user password:', error);
    throw error;
  }
};

export const isPasswordCorrect = async (password) => {
  try {
    const response = await apiClient.post('/check-password', {
      password,
    });
    return response.data;
  } catch (error) {
    // Check if error is due to invalid password
    if (error.response?.status === 400) {
      throw new Error('Current password is incorrect');
    }
    throw error;
  }
};
