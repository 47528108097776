// UsersOverview.js
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUsers } from 'api/user';
import { MdEdit, MdChevronRight, MdChevronLeft } from 'react-icons/md';
import {
  Button,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import CustomAvatar from '../../components/CustomAvatar'; // Import CustomAvatar
import { createPages } from 'utils/helpers';
import DebouncedInput from 'components/DebouncedInput';

export default function UsersOverview() {
  const [users, setUsers] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersData = await getUsers();
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const columnHelper = createColumnHelper();

  // Memoized cell render functions
  const renderFullNameCell = useCallback(
    (info) => (
      <Flex align="center">
        <CustomAvatar
          src={info.row.original.avatar_url}
          name={info.getValue()}
          size="lg"
        />
        <Text color={textColor} fontSize="md" fontWeight="500" ml="10px">
          {info.getValue()}
        </Text>
      </Flex>
    ),
    [textColor],
  );

  const renderEmailCell = useCallback(
    (info) => (
      <Text color={textColor} fontSize="md" fontWeight="500">
        {info.getValue()}
      </Text>
    ),
    [textColor],
  );

  const renderTotalPurchasesCell = useCallback(
    (info) => (
      <Text color={textColor} fontSize="md" fontWeight="500">
        {info.getValue()}
      </Text>
    ),
    [textColor],
  );

  const renderCreatedAtCell = useCallback(
    (info) => (
      <Text color={textColor} fontSize="md" fontWeight="500">
        {new Date(info.getValue()).toLocaleDateString()}
      </Text>
    ),
    [textColor],
  );

  const renderWalletAddressCell = useCallback((info) => {
    const walletAddress = info.getValue();
    const walletName = info.row.original.default_wallet?.wallet_name;

    return (
      <Popover>
        <PopoverTrigger>
          <Button size="sm" colorScheme="blue">
            Show Address
          </Button>
        </PopoverTrigger>
        <PopoverContent width="350px">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader textAlign="center" fontSize={'md'}>
            {walletName ? walletName : 'Wallet Address'}
          </PopoverHeader>
          <PopoverBody>
            <Text>{walletAddress ? walletAddress : 'No Address'}</Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }, []);

  const renderActionsCell = useCallback(
    (info) => (
      <Text
        w="16px"
        h="16px"
        as={MdEdit}
        cursor="pointer"
        color={brandColor}
        onClick={() => {
          const userUuid = info.row.original.uuid;
          if (userUuid) {
            navigate(`/admin/users/user/${userUuid}`);
          } else {
            console.error('userUuid is undefined');
          }
        }}
      />
    ),
    [brandColor, navigate],
  );

  // Memoized columns array
  const columns = useMemo(
    () => [
      columnHelper.accessor('full_name', {
        id: 'full_name',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            FULL NAME
          </Text>
        ),
        cell: renderFullNameCell,
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            EMAIL
          </Text>
        ),
        cell: renderEmailCell,
      }),
      columnHelper.accessor('total_purchases', {
        id: 'total_purchases',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            TOTAL PURCHASES
          </Text>
        ),
        cell: renderTotalPurchasesCell,
      }),
      columnHelper.accessor('created_at', {
        id: 'created_at',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            JOIN DATE
          </Text>
        ),
        cell: renderCreatedAtCell,
      }),
      columnHelper.accessor((row) => row.default_wallet?.wallet_address, {
        id: 'wallet_address',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            WALLET ADDRESS
          </Text>
        ),
        cell: renderWalletAddressCell,
      }),
      columnHelper.accessor('actions', {
        id: 'actions',
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            ACTIONS
          </Text>
        ),
        cell: renderActionsCell,
      }),
    ],
    [
      columnHelper,
      renderFullNameCell,
      renderEmailCell,
      renderTotalPurchasesCell,
      renderCreatedAtCell,
      renderWalletAddressCell,
      renderActionsCell,
    ],
  );

  // React Table instance
  const table = useReactTable({
    data: users,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Flex direction="column" mt={{ sm: '150px', md: '125px', lg: '75px' }}>
      <Card
        w="100%"
        px={{ base: '16px', md: '24px' }}
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          mb="36px"
          mt="24px"
        >
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-block"
          />
        </Flex>

        {/* Table */}
        <Table variant="simple" color="gray.500">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    pe="10px"
                    borderColor={borderColor}
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: '🔼',
                          desc: '🔽',
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr px="20px" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    borderColor={borderColor}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>

        {/* Pagination */}
        <Flex
          w="100%"
          justify="space-between"
          alignItems="center"
          px="20px"
          py="24px"
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: '24px', md: '0px' }}
          >
            Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
            {Math.min(
              pagination.pageSize * (pagination.pageIndex + 1),
              users.length,
            )}{' '}
            of {users.length} entries
          </Text>
          <div className="flex items-center gap-2">
            <Stack direction="row" spacing="4px" ms="auto">
              <Button
                variant="no-effects"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={useColorModeValue('gray.200', 'white')}
                display={
                  pagination.pageSize === 5
                    ? 'none'
                    : table.getCanPreviousPage()
                      ? 'flex'
                      : 'none'
                }
                _hover={{
                  bg: 'whiteAlpha.100',
                  opacity: '0.7',
                }}
              >
                <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
              </Button>
              {createPages(table.getPageCount()).map((pageNumber, index) => (
                <Button
                  variant="no-effects"
                  transition="all .5s ease"
                  onClick={() => table.setPageIndex(pageNumber - 1)}
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bg={
                    pageNumber === pagination.pageIndex + 1
                      ? brandColor
                      : 'transparent'
                  }
                  border={
                    pageNumber === pagination.pageIndex + 1
                      ? 'none'
                      : '1px solid lightgray'
                  }
                  _hover={
                    pageNumber === pagination.pageIndex + 1
                      ? {
                          opacity: '0.7',
                        }
                      : {
                          bg: 'whiteAlpha.100',
                        }
                  }
                  key={index}
                >
                  <Text
                    fontSize="sm"
                    color={
                      pageNumber === pagination.pageIndex + 1
                        ? '#fff'
                        : textColor
                    }
                  >
                    {pageNumber}
                  </Text>
                </Button>
              ))}
              <Button
                variant="no-effects"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={useColorModeValue('gray.200', 'white')}
                display={
                  pagination.pageSize === 5
                    ? 'none'
                    : table.getCanNextPage()
                      ? 'flex'
                      : 'none'
                }
                _hover={{
                  bg: 'whiteAlpha.100',
                  opacity: '0.7',
                }}
              >
                <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
              </Button>
            </Stack>
          </div>
        </Flex>
      </Card>
    </Flex>
  );
}
