// src/hooks/useProducts.js

import useFetch from './useFetch';
import { getProducts } from '../api/product';

const useProducts = (filters = {}) => {
  const { data, loading, error } = useFetch(
    () => getProducts(filters),
    [JSON.stringify(filters)],
  );
  return { products: Array.isArray(data) ? data : [], loading, error };
};

export default useProducts;
