import { FiChevronsDown } from 'react-icons/fi';
import { TbArrowsSplit, TbAmpersand } from 'react-icons/tb';

export const getLoyaltyProgramName = (uuid, loyaltyPrograms, language) => {
  const program = loyaltyPrograms.find((program) => program.uuid === uuid);
  const name = program.translations?.find(
    (translation) =>
      translation.language === language && translation.key === 'name',
  );
  return name ? name.value : 'Unknown Program';
};

export const getLoyaltyLevelName = (uuid, loyaltyPrograms, language) => {
  const level = loyaltyPrograms
    .flatMap((program) => program.products)
    .find((level) => level.loyalty_level_uuid === uuid);

  const name = level.translations?.find(
    (translation) =>
      translation.language === language && translation.key === 'name',
  );

  return name ? name.value : 'Unknown Level';
};

export const getRewardVoucherName = (uuid, vouchers, language) => {
  const voucher = vouchers.find((voucher) => voucher.uuid === uuid);
  const translation = voucher.translations[language];
  const voucherName = translation
    ? translation.product_name
    : 'Unnamed Voucher';

  return voucherName;
};

export const getIconBetweenSteps = (currentType, nextType, prevType) => {
  if (nextType === 'THEN') {
    return FiChevronsDown;
  }
  if (currentType === 'IF_AND' && nextType === 'IF_OR') {
    return TbArrowsSplit;
  }
  if (prevType === 'IF_OR' && currentType === 'IF_AND') {
    return TbArrowsSplit;
  }
  return currentType === 'IF_AND' ? TbAmpersand : TbArrowsSplit;
};
