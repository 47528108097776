// src/hooks/useDiscounts.js

import { useState, useEffect } from 'react';
import { getProductDiscounts } from '../api/discount';

const useDiscounts = (filters) => {
  const [discounts, setDiscounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDiscounts = async () => {
      setLoading(true);
      setError(null);
      try {
        console.log('Fetching discounts with filters:', filters);
        const response = await getProductDiscounts(filters);
        console.log('API Response:', response);

        if (response.data && typeof response.total === 'number') {
          // Filter out child discounts
          const parentsData = response.data.filter(
            (discount) =>
              discount.parent_id === 0 || discount.parent_id === null,
          );

          // Transform 'child_discounts' to 'children' and exclude 'child_discounts'
          const transformedDiscounts = parentsData.map((parent) => {
            const { child_discounts, ...rest } = parent;
            return {
              ...rest,
              children: child_discounts || [],
            };
          });

          setDiscounts(transformedDiscounts);
          setTotal(response.total);
        } else if (Array.isArray(response)) {
          // Handle case when response is a flat array without pagination
          setDiscounts(response);
          setTotal(response.length);
        } else {
          console.error('Unexpected API response structure:', response);
          setError('Unexpected API response structure');
        }
      } catch (err) {
        console.error('Error fetching discounts:', err);
        setError(err.message || 'Failed to fetch discounts');
      } finally {
        setLoading(false);
      }
    };

    fetchDiscounts();
  }, [filters]);

  return { discounts, total, loading, error };
};

export default useDiscounts;
