import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Button,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Alert,
  AlertDescription,
} from '@chakra-ui/react';

const EditCategoryForm = ({
  initialTranslations = {
    en: { category_name: '', category_description: '' },
    et: { category_name: '', category_description: '' },
  },
  onUpdate,
  onCancel,
}) => {
  const [translations, setTranslations] = useState(initialTranslations);
  const [error, setError] = useState('');

  useEffect(() => {
    setTranslations(initialTranslations);
  }, [initialTranslations]);

  const handleTranslationChange = (lang, field, value) => {
    setTranslations((prev) => ({
      ...prev,
      [lang]: {
        ...prev[lang],
        [field]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!translations.en.category_name.trim()) {
      setError('Category name in English is required.');
      return;
    }

    onUpdate(translations);
    onCancel();
  };

  return (
    <Box p={4} bg="gray.50" borderRadius="md" mt={2}>
      <form onSubmit={handleSubmit}>
        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>English</Tab>
            <Tab>Estonian</Tab>
          </TabList>
          <TabPanels>
            {['en', 'et'].map((lang) => (
              <TabPanel key={lang}>
                <VStack spacing={3}>
                  <FormControl isRequired={lang === 'en'}>
                    <FormLabel>
                      Category Name ({lang === 'en' ? 'English' : 'Estonian'})
                    </FormLabel>
                    <Input
                      value={translations[lang]?.category_name || ''}
                      onChange={(e) =>
                        handleTranslationChange(
                          lang,
                          'category_name',
                          e.target.value,
                        )
                      }
                      placeholder={`Category name in ${lang === 'en' ? 'English' : 'Estonian'}`}
                      size="sm"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      Category Description (
                      {lang === 'en' ? 'English' : 'Estonian'})
                    </FormLabel>
                    <Textarea
                      value={translations[lang]?.category_description || ''}
                      onChange={(e) =>
                        handleTranslationChange(
                          lang,
                          'category_description',
                          e.target.value,
                        )
                      }
                      placeholder={`Category description in ${
                        lang === 'en' ? 'English' : 'Estonian'
                      }`}
                      size="sm"
                    />
                  </FormControl>
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
        {error && (
          <Alert status="error" borderRadius="md" mt={2}>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <HStack w="full" spacing={2} mt={4}>
          <Button size="sm" type="submit" colorScheme="blue">
            Update
          </Button>
          <Button size="sm" variant="ghost" onClick={onCancel}>
            Cancel
          </Button>
        </HStack>
      </form>
    </Box>
  );
};

export default EditCategoryForm;
