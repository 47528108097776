import apiClient from './axios';

export const getCategories = async (filters = {}) => {
  try {
    const cleanedFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value != null),
    );

    const response = await apiClient.get('/categories', {
      params: cleanedFilters,
    });
    console.log('Categories response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const getCategory = async (uuid) => {
  try {
    const response = await apiClient.get(`/categories/${uuid}`);
    console.log('Category response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching category:', error);
    throw error;
  }
};

export const createCategory = async (data) => {
  try {
    console.log('Sending category creation data:', data);
    const response = await apiClient.post('/categories', data);
    return response.data;
  } catch (error) {
    console.error(
      'Error creating category:',
      error.response ? error.response.data : error.message,
    );
    throw error.response?.data || new Error('Failed to create category.');
  }
};

export const updateCategory = async (
  uuid,
  { parent_id, translations = {} },
) => {
  try {
    const response = await apiClient.put(`/categories/${uuid}`, {
      parent_id,
      translations,
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating category with UUID ${uuid}:`, error);
    throw error.response?.data || new Error('Failed to update category.');
  }
};

export const deleteCategory = async (uuid) => {
  try {
    const response = await apiClient.delete(`/categories/${uuid}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting category:', error);
    throw error.response?.data || new Error('Failed to delete category.');
  }
};
