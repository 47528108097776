import React, { useState, useEffect } from 'react';
import {
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { getLatestLoyaltyPrograms } from 'api/dashboard';
import { BsArrowRight } from 'react-icons/bs';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

export default function LatestLoyaltyPrograms(props) {
  const { ...rest } = props;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');

  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPrograms = async () => {
      setIsLoading(true);
      try {
        const data = await getLatestLoyaltyPrograms({ limit: 5 });
        setPrograms(data);
      } catch (error) {
        console.error('Error fetching latest loyalty programs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrograms();
  }, []);

  return (
    <Card
      justifyContent="center"
      direction="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      pb="20px"
      {...rest}
    >
      <Flex justify="space-between" align="center" mb="26px" pt="20px">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          Latest Sold Programs
        </Text>
        {}
      </Flex>
      {isLoading ? (
        <Flex justify="center" align="center" minH="150px">
          <Spinner />
        </Flex>
      ) : (
        programs.map((program, index) => (
          <Flex
            key={`${program.id}-${index}`}
            alignItems="center"
            w="100%"
            mb="15px"
          >
            <Image
              src={`https://api.perxify.com/${program.image}` || ''}
              alt={program.name}
              boxSize="40px"
              borderRadius="full"
              mr="10px"
            />
            <Flex direction="column" align="start" me="auto">
              <Text color={textColor} fontSize="md" fontWeight="700">
                {program.name}
              </Text>

              <Text fontSize="xs" color="secondaryGray.500">
                Sold on {format(parseISO(program.purchase_date), 'dd MMM yyyy')}
              </Text>
            </Flex>

            <Text ms="auto" color={textColor} fontSize="sm" fontWeight="700">
              €{program.price.toFixed(2)}
            </Text>
          </Flex>
        ))
      )}
      {}
      <Button
        p="0px"
        ms="auto"
        variant="no-hover"
        bg="transparent"
        my={{ sm: '1.5rem', lg: '0px' }}
        as={Link}
        to="/admin/loyalty-programs/view-all"
      >
        <Text
          fontSize="sm"
          color={brandColor}
          fontWeight="bold"
          cursor="pointer"
          transition="all .3s ease"
          my={{ sm: '1.5rem', lg: '0px' }}
          _hover={{ me: '4px' }}
        >
          View all
        </Text>
        <Icon
          as={BsArrowRight}
          w="18px"
          h="18px"
          color={brandColor}
          transition="all .3s ease"
          ms=".3rem"
          cursor="pointer"
          _hover={{ transform: 'translate(4px)' }}
        />
      </Button>
    </Card>
  );
}
