// src/pages/discounts/DiscountOverview.jsx

import React, { useState, useEffect, useMemo } from 'react';
import {
  Flex,
  Card,
  Spinner,
  Text,
  Button,
  useColorModeValue,
  Alert,
  AlertIcon,
  useToast,
  Box,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate, Outlet } from 'react-router-dom';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import { useDebounce } from 'use-debounce';
import Pagination from '../../components/pagination';
import DiscountFilters from '../../components/discounts/DiscountFilters';
import DiscountTable from '../../components/discounts/DiscountTable';
import useDiscountColumns from '../../components/discounts/discountColumns';

// Import custom hooks
import useProducts from '../../hooks/useProducts';
import useLoyaltyPrograms from '../../hooks/useLoyaltyPrograms';
import useLoyaltyLevels from '../../hooks/useLoyaltyLevels';
import useDiscounts from '../../hooks/useDiscounts';
import { createProductDiscount, getDiscount } from '../../api/discount';

// Import icons

// Import the DiscountTemplates component
import DiscountTemplates from './data/discountTemplates';

export default function DiscountOverview() {
  const navigate = useNavigate();
  const toast = useToast();
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  // Initialize filter states with localStorage
  const [productUUID, setProductUUID] = useState(() => {
    const stored = localStorage.getItem('discountOverview_productUUID');
    return stored || '';
  });
  const [loyaltyProgramUUID, setLoyaltyProgramUUID] = useState(() => {
    const stored = localStorage.getItem('discountOverview_loyaltyProgramUUID');
    return stored || '';
  });
  const [loyaltyLevelUUID, setLoyaltyLevelUUID] = useState(() => {
    const stored = localStorage.getItem('discountOverview_loyaltyLevelUUID');
    return stored || '';
  });
  const [isActive, setIsActive] = useState(() => {
    const stored = localStorage.getItem('discountOverview_isActive');
    const parsed = stored !== null ? JSON.parse(stored) : true;
    return parsed;
  });

  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 30 });

  // Debounced filter values to optimize API calls
  const [debouncedProductUUID] = useDebounce(productUUID, 300);
  const [debouncedLoyaltyProgramUUID] = useDebounce(loyaltyProgramUUID, 300);
  const [debouncedLoyaltyLevelUUID] = useDebounce(loyaltyLevelUUID, 300);
  const [debouncedIsActive] = useDebounce(isActive, 300);

  // Use custom hooks
  const {
    products,
    loading: productsLoading,
    error: productsError,
  } = useProducts();
  const {
    loyaltyPrograms,
    loading: loyaltyProgramsLoading,
    error: loyaltyProgramsError,
  } = useLoyaltyPrograms();
  const {
    loyaltyLevels,
    loading: loyaltyLevelsLoading,
    error: loyaltyLevelsError,
  } = useLoyaltyLevels();

  // Prepare filters for discounts
  const filters = useMemo(
    () => ({
      product_uuid: debouncedProductUUID || undefined,
      loyalty_program_uuid: debouncedLoyaltyProgramUUID || undefined,
      loyalty_level_uuid: debouncedLoyaltyLevelUUID || undefined,
      is_active: debouncedIsActive,
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
    }),
    [
      debouncedProductUUID,
      debouncedLoyaltyProgramUUID,
      debouncedLoyaltyLevelUUID,
      debouncedIsActive,
      pagination.pageIndex,
      pagination.pageSize,
    ],
  );

  const {
    discounts = [],
    total = 0,
    loading: discountsLoading,
    error: discountsError,
  } = useDiscounts(filters);

  const rootDiscounts = useMemo(
    () => discounts.filter((discount) => !discount.parent_discount_id),
    [discounts],
  );

  // Effect to log discounts and total
  useEffect(() => {
    console.log('Discounts:', discounts);
    console.log('Total Discounts:', total);
  }, [discounts, total]);

  const handleDuplicate = async (uuid) => {
    try {
      // Fetch the discount data
      const discountData = await getDiscount(uuid);

      // Destructure parent and child discounts
      const {
        parent_discount: parentDiscount,
        child_discounts: childDiscounts,
      } = discountData;

      const duplicatedData = {
        ...parentDiscount,
        uuid: undefined,
        parent_id: undefined,
        is_active: parentDiscount.is_active,
      };

      // Handle discount_type specific fields
      if (parentDiscount.discount_type === 'selected_products') {
        // Remove product_uuid and include product_uuids
        delete duplicatedData.product_uuid;
        let productUuids = [];

        if (childDiscounts && childDiscounts.length > 0) {
          productUuids = childDiscounts.map((child) => child.product_uuid);
        } else if (parentDiscount.product_uuid) {
          // If there are no child discounts but parent has a product_uuid
          productUuids = [parentDiscount.product_uuid];
        } else {
          // Handle error: No products to duplicate
          throw new Error(
            'No products found to duplicate for selected_products discount.',
          );
        }

        duplicatedData.product_uuids = productUuids;
      } else if (
        parentDiscount.discount_type === 'all_loyalty_products' ||
        parentDiscount.discount_type === 'all_general_products'
      ) {
        // For these types, remove both product_uuid and product_uuids
        delete duplicatedData.product_uuid;
        delete duplicatedData.product_uuids;
      } else {
        // For other types, ensure product_uuid is included and product_uuids is not
        duplicatedData.product_uuid = parentDiscount.product_uuid;
        delete duplicatedData.product_uuids;
      }

      // Remove any undefined fields
      Object.keys(duplicatedData).forEach((key) => {
        if (duplicatedData[key] === undefined) {
          delete duplicatedData[key];
        }
      });

      console.log('Duplicated Data:', duplicatedData);

      // Call the create discount API
      const newDiscount = await createProductDiscount(duplicatedData);

      // Navigate to the edit page of the new discount
      navigate(`/admin/discounts/edit/${newDiscount.parent_discount.uuid}`);

      toast({
        title: 'Discount duplicated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error duplicating discount:', error);
      toast({
        title: 'Failed to duplicate discount.',
        description:
          error.response?.data?.error ||
          error.message ||
          'An unexpected error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Define columns using the enhanced useDiscountColumns hook
  const columns = useDiscountColumns(
    textColor,
    navigate,
    handleDuplicate,
    loyaltyPrograms,
    loyaltyLevels,
  );

  // Update the data passed to the table
  const table = useReactTable({
    data: discounts, // Now only parent discounts
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pagination.pageSize),
    getSubRows: (row) => row.children || [],
    getRowId: (row) => row.uuid,
  });

  useEffect(() => {
    console.log('Discounts State:', discounts);
  }, [discounts]);

  // Effect to ensure pageIndex is within valid range
  useEffect(() => {
    const totalPages = table.getPageCount();
    if (pagination.pageIndex >= totalPages && totalPages > 0) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: totalPages - 1,
      }));
    }
  }, [table.getPageCount(), pagination.pageIndex, setPagination]);

  const filteredLoyaltyLevels = useMemo(() => {
    if (loyaltyProgramUUID) {
      return loyaltyLevels.filter(
        (level) => level.loyalty_program_uuid === loyaltyProgramUUID,
      );
    }
    return loyaltyLevels;
  }, [loyaltyLevels, loyaltyProgramUUID]);

  useEffect(() => {
    if (
      loyaltyLevelUUID &&
      !discounts.some((d) => d.loyalty_level_uuid === loyaltyLevelUUID)
    ) {
      // Find loyalty level name
      const levelName =
        loyaltyLevels.find((level) => level.uuid === loyaltyLevelUUID)?.name ||
        'Unknown';

      toast({
        title: 'Loyalty Level Not Found',
        description: `Loyalty Level "${levelName}" not found in discounts.`,
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      setLoyaltyLevelUUID('');
    }
  }, [discounts, loyaltyLevelUUID, loyaltyLevels, toast]);

  // Handler to reset all filters
  const resetFilters = () => {
    setProductUUID('');
    setLoyaltyProgramUUID('');
    setLoyaltyLevelUUID('');
    setIsActive(true);
    setPagination({ pageIndex: 0, pageSize: 30 }); // Reset to first page
  };

  const filteredProducts = useMemo(() => {
    return products.filter(
      (product) =>
        product.product_type === 'regular' &&
        !product.loyalty_program_uuid &&
        !product.loyalty_level_uuid,
    );
  }, [products]);

  return (
    <Box>
      <Flex
        direction="column"
        pt={{ base: '120px', md: '75px', xl: '75px' }}
        minH="100vh"
      >
        {/* Use the imported DiscountTemplates component */}
        <DiscountTemplates />

        <Flex direction="column" pt={{ sm: '60px', lg: '30px' }} flex="1">
          <Card
            w="100%"
            px={{ base: '16px', md: '24px' }}
            flex="1"
            overflowX="hidden"
            overflowY="visible"
            display="flex"
            flexDirection="column"
          >
            <Flex
              direction="row"
              my="24px"
              px="20px"
              align="center"
              justify="space-between"
            >
              <DiscountFilters
                productUUID={productUUID}
                setProductUUID={setProductUUID}
                loyaltyProgramUUID={loyaltyProgramUUID}
                setLoyaltyProgramUUID={setLoyaltyProgramUUID}
                loyaltyLevelUUID={loyaltyLevelUUID}
                setLoyaltyLevelUUID={setLoyaltyLevelUUID}
                isActive={isActive}
                setIsActive={setIsActive}
                resetFilters={resetFilters}
                filteredProducts={filteredProducts}
                filteredLoyaltyPrograms={loyaltyPrograms}
                filteredLoyaltyLevels={filteredLoyaltyLevels}
                setPagination={setPagination}
              />

              <Button
                leftIcon={<AddIcon />}
                colorScheme="blue"
                variant="solid"
                onClick={() => navigate('/admin/discounts/new-discount')}
              >
                Add Discount
              </Button>
            </Flex>

            {/* Display Error Messages */}
            {(productsError ||
              loyaltyProgramsError ||
              loyaltyLevelsError ||
              discountsError) && (
              <Alert status="error" mb="20px" mx="20px">
                <AlertIcon />
                {productsError ||
                  loyaltyProgramsError ||
                  loyaltyLevelsError ||
                  discountsError}
              </Alert>
            )}

            {discountsLoading ||
            productsLoading ||
            loyaltyProgramsLoading ||
            loyaltyLevelsLoading ? (
              <Flex justify="center" align="center" flex="1">
                <Spinner size="lg" />
              </Flex>
            ) : discounts.length > 0 ? (
              <>
                {/* Table */}
                <DiscountTable
                  table={table}
                  borderColor={borderColor}
                  textColor={textColor}
                />

                {/* Pagination */}
                {total > pagination.pageSize && (
                  <Flex
                    w="100%"
                    justify="space-between"
                    px="20px"
                    pt="10px"
                    pb="5px"
                    alignItems="center"
                    mt="auto"
                  >
                    <Text fontSize="sm" color="gray.500" fontWeight="normal">
                      Showing{' '}
                      {total === 0
                        ? 0
                        : pagination.pageIndex * pagination.pageSize + 1}{' '}
                      to{' '}
                      {Math.min(
                        (pagination.pageIndex + 1) * pagination.pageSize,
                        total,
                      )}{' '}
                      of {total} entries
                    </Text>

                    <Pagination
                      currentPage={pagination.pageIndex}
                      pageCount={table.getPageCount()}
                      onChange={(page) => table.setPageIndex(page)}
                      textColor={textColor}
                      borderColor={borderColor}
                      brandColor={brandColor}
                    />
                  </Flex>
                )}
              </>
            ) : (
              <Text textAlign="center" mt="20px">
                No discounts found.
              </Text>
            )}
            <Outlet />
          </Card>
        </Flex>
      </Flex>
    </Box>
  );
}
