import React, { useState } from 'react';
import {
    Box,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Input,
    Textarea,
} from '@chakra-ui/react';
import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';

export const BenefitsElement = React.forwardRef(({ attributes, children, element, editor }, ref) => {
    const [currentSection, setCurrentSection] = useState('section1');
    const path = ReactEditor.findPath(editor, element);

    const updateSection = (section, value) => {
        const newAttrs = {
            ...element.attrs,
            [section]: value
        };

        Transforms.setNodes(
            editor,
            { attrs: newAttrs },
            { at: path }
        );
    };

    return (
        <Box {...attributes} ref={ref} contentEditable={false} p={4} bg="gray.50" borderRadius="md">
            <Tabs onChange={index => setCurrentSection(`section${index + 1}`)}>
                <TabList>
                    <Tab>Section 1</Tab>
                    <Tab>Section 2</Tab>
                    <Tab>Section 3</Tab>
                </TabList>
                <TabPanels>
                    {['section1', 'section2', 'section3'].map((section, i) => (
                        <TabPanel key={section}>
                            <Textarea
                                value={element.attrs[section] || ''}
                                onChange={e => updateSection(section, e.target.value)}
                                placeholder={`Enter content for section ${i + 1}`}
                                rows={4}
                            />
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
            {children}
        </Box>
    );
}); 