import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  FormControl,
  Select,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { createCategory, getCategories } from '../../api/category';

const initialCategory = {
  translations: {
    en: { category_name: '', category_description: '' },
    et: { category_name: '', category_description: '' },
  },
  child_categories: [],
};

export default function AddCategory() {
  const navigate = useNavigate();
  const toast = useToast();

  const [parentId, setParentId] = useState(null);
  const [parentCategories, setParentCategories] = useState([]);
  const [loadingParents, setLoadingParents] = useState(true);

  const [categoryTree, setCategoryTree] = useState([initialCategory]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchParentCategories = async () => {
      setLoadingParents(true);
      try {
        const categories = await getCategories({ limit: 100, offset: 0 });
        setParentCategories(categories);
      } catch (error) {
        console.error('Error fetching parent categories:', error);
        toast({
          title: 'Error',
          description: 'Failed to load parent categories.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoadingParents(false);
      }
    };

    fetchParentCategories();
  }, [toast]);

  const addChildCategory = (path) => {
    setCategoryTree((prevTree) => {
      const newTree = [...prevTree];
      let current = newTree;
      path.forEach((index) => {
        current = current[index].child_categories;
      });
      current.push(JSON.parse(JSON.stringify(initialCategory)));
      return newTree;
    });
  };

  const removeCategory = (path) => {
    setCategoryTree((prevTree) => {
      const newTree = [...prevTree];
      const lastIndex = path.pop();
      let current = newTree;
      path.forEach((index) => {
        current = current[index].child_categories;
      });
      current.splice(lastIndex, 1);
      return newTree;
    });
  };

  const handleTranslationChange = (path, langCode, field, value) => {
    setCategoryTree((prevTree) => {
      const newTree = [...prevTree];
      let current = newTree;

      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]].child_categories;
      }

      const targetCategory = current[path[path.length - 1]];

      targetCategory.translations[langCode][field] = value;

      return newTree;
    });
  };

  const validateAllTranslations = (categories, path = []) => {
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      for (const lang of languages) {
        if (!category.translations[lang.code].category_name.trim()) {
          toast({
            title: 'Validation Error',
            description: `Category name is required for ${lang.name} at ${path.concat(i).join(' > ') || 'root'}.`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return false;
        }
      }
      if (category.child_categories.length > 0) {
        const valid = validateAllTranslations(
          category.child_categories,
          path.concat(i),
        );
        if (!valid) return false;
      }
    }
    return true;
  };

  const preparePayload = () => {
    return categoryTree.map((category) => ({
      parent_id: parentId || null,
      translations: category.translations,
      child_categories: category.child_categories.map(transformCategory),
    }));
  };

  const transformCategory = (category) => ({
    parent_id: null,
    translations: category.translations,
    child_categories: category.child_categories.map(transformCategory),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateAllTranslations(categoryTree);
    if (!isValid) return;

    setIsSubmitting(true);

    const payload = categoryTree.map((category) => ({
      parent_id: parentId || null,
      translations: category.translations,
      child_categories: category.child_categories.map(transformCategory),
    }));

    try {
      const newCategory = await createCategory(payload[0]);
      console.log('Category created successfully:', newCategory);
      toast({
        title: 'Success',
        description: 'Category created successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/admin/categories');
    } catch (error) {
      console.error('Error creating category:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to create category.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
  ];

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      px={{ base: '16px', md: '24px' }}
    >
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
          {}
          <FormControl>
            <Select
              placeholder="Select parent category"
              value={parentId || ''}
              onChange={(e) => setParentId(e.target.value || null)}
              isDisabled={loadingParents}
            >
              <option value="">None</option>
              {parentCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {}
        </SimpleGrid>

        {}

        {}
        <Flex mt="20px" justify="flex-end">
          <Button
            colorScheme="blue"
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Add Category
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
